import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import { Id } from '@gmini/sm-api-sdk'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

// eslint-disable-next-line import/no-cycle
import { createPredicate } from '../createPredicate'

// eslint-disable-next-line import/no-cycle
import { Inspection } from './Inspection'
// eslint-disable-next-line import/no-cycle
import { InspectionRepoFolder } from './InspectionRepoFolder'

export interface InspectionRepo {
  readonly type: 'FieldInspectionRepository'
  readonly id: Id
  readonly name: string
  readonly total: number
}

export namespace InspectionRepo {
  const domain = ApiDomain.domain('InspectionRepo')

  export const is = createPredicate<InspectionRepo>('FieldInspectionRepository')

  export const io: t.Type<InspectionRepo> = t.type({
    type: t.literal('FieldInspectionRepository'),
    id: Id,
    name: t.string,
    total: t.number,
  })

  export interface Populated {
    readonly type: 'FieldInspectionRepositoryPopulated'
    readonly id: Id
    readonly name: string
    readonly total: number
    readonly offset: number
    readonly children: ReadonlyArray<InspectionRepoFolder | Inspection>
  }

  export namespace Populated {
    export const is = createPredicate<Populated>(
      'FieldInspectionRepositoryPopulated',
    )

    export const io: t.Type<Populated> = t.type({
      type: t.literal('FieldInspectionRepositoryPopulated'),
      id: Id,
      name: t.string,
      total: t.number,
      offset: t.number,
      children: t.readonlyArray(
        t.union([InspectionRepoFolder.io, Inspection.io]),
      ),
    })

    export const fetch = ApiCallService.create<
      { readonly projectUrn: string },
      Populated
    >({
      name: 'fetch',
      parentDomain: domain,
      result: InspectionRepo.Populated.io,
      getKey: () => 'root',
    })
  }
}
