import { createEvent, createStore, guard, sample } from 'effector'

import { isNotEmpty } from '@gmini/utils'

import { Color } from '@gmini/common/lib/forge-viewer/model/themeModel'

import {
  includedAndLoadedModels$,
  themeModelsLoaded,
  reFetchColorModels,
  modelLoaded,
  colorViewerElements,
} from './model'
import { fetchBulk } from './requests'

type ColoredElements = Record<
  string,
  {
    GREEN?: string[]
    RED?: string[]
    YELLOW?: string[]
    GRAY?: string[]
    WHITE?: string[]
  }
>

/**
 * Окрашиваем модель цветами
 */

const coloredModels$ = createStore<ColoredElements>({}).on(
  themeModelsLoaded,
  (state, { urns, coloredModels }) => {
    let theme: ColoredElements = {}

    if (!coloredModels) {
      return state
    }

    urns.forEach(urn => {
      const modelItems = coloredModels && coloredModels[urn]
      if (!modelItems) {
        theme = {}
        return
      }

      theme = {
        ...theme,
        [urn]: {
          [Color.GREEN]: modelItems
            .map(({ status, id }) => (status === 'COMPLETED' && id) || null)
            .filter(isNotEmpty),
          [Color.GRAY]: modelItems
            .map(({ status, id }) => (status === 'NOT_STARTED' && id) || null)
            .filter(isNotEmpty),
          [Color.YELLOW]: modelItems
            .map(({ status, id }) => (status === 'IN_PROGRESS' && id) || null)
            .filter(isNotEmpty),
        },
      }
    })

    return Object.keys(theme).length ? { ...state, ...theme } : state
  },
)
coloredModels$.watch(colorViewerElements)

const fetchOnce = createEvent<{
  modelId: number
  modelVersion: number
  urn: string
  baseClassifierId: number
  inspectionId: number
  inspectionVersion: number
}>()
sample({
  source: includedAndLoadedModels$,
  clock: modelLoaded,
  fn: (includedAndLoadedModels, addedModel) =>
    includedAndLoadedModels?.find(model => model.urn === addedModel.urn) ||
    null,
}).watch(data => {
  if (data) {
    fetchOnce(data)
  }
})

sample({
  source: includedAndLoadedModels$,
  clock: reFetchColorModels,
  fn: includedAndLoadedModels =>
    includedAndLoadedModels ? includedAndLoadedModels : null,
})
  .filter({ fn: isNotEmpty })
  .watch(fetchBulk)

guard({
  clock: fetchOnce.map(data => [data]),
  filter: Boolean,
  target: fetchBulk,
})
