import * as t from 'io-ts'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { Id } from '@gmini/sm-api-sdk'

import { ApiCallService } from '@gmini/api-call-service'

import { InspectionItem } from './common'

export namespace ChecklistStatus {
  const domain = ApiDomain.domain('ChecklistStatus')

  export interface Item {
    itemType: InspectionItem.Type
    itemId: number
    status: Status
    groupId: Id
  }

  export const Status = t.union([
    t.literal('IN_PROGRESS'),
    t.literal('NOT_STARTED'),
    t.literal('COMPLETED'),
  ])

  export const io: t.Type<Item> = t.type({
    itemId: Id,
    itemType: InspectionItem.Type,
    status: Status,
    groupId: Id,
  })

  export type Status = t.TypeOf<typeof Status>

  export interface ResponseItem {
    itemType: InspectionItem.Type
    itemId: number
    status: Status
    groupId: Id
  }

  export const ResponseItem: t.Type<ResponseItem> = t.type({
    itemId: Id,
    itemType: InspectionItem.Type,
    status: Status,
    groupId: Id,
  })

  export type DependencyResponseItem = Omit<ResponseItem, 'groupId'>

  export const DependencyResponseItem: t.Type<DependencyResponseItem> = t.type({
    itemId: Id,
    itemType: InspectionItem.Type,
    status: Status,
  })

  export interface Response {
    items: ResponseItem[]
  }

  export const Response: t.Type<Response> = t.type({
    items: t.array(ResponseItem),
  })
  export interface DependencyResponse {
    items: DependencyResponseItem[]
  }

  export const DependencyResponse: t.Type<DependencyResponse> = t.type({
    items: t.array(DependencyResponseItem),
  })

  export const fetchTreeStatuses = ApiCallService.create<
    InspectionItem.FetchInspectionItemsParams,
    Response
  >({
    name: 'fetchTreeStatuses',
    parentDomain: domain,
    getKey: ['fieldInspectionId', 'fieldInspectionVersion'],
    result: Response,
  })

  export const fetchDependencyTreeStatuses = ApiCallService.create<
    InspectionItem.FetchDependencyItemsParams,
    DependencyResponse
  >({
    name: 'fetchDependencyTreeStatuses',
    parentDomain: domain,
    getKey: [
      'fieldInspectionId',
      'fieldInspectionVersion',
      'sourceClassifierId',
      'sourceClassifierVersion',
    ],
    result: DependencyResponse,
  })
}
