import * as Sentry from '@sentry/browser'

import { ServerUnavailableException } from '@gmini/utils/lib/fetchService/exceptions'

import { BadResult } from '@gmini/api-call-service'

const excludedCodes = [
  'GROUPING_CONDITION_ELEMENT_PROPERTIES_IS_GREATER_MAX_ALLOWED',
]

export function logApiError(err: BadResult | ServerUnavailableException) {
  if (err instanceof ServerUnavailableException) {
    // Не отправляем в сентри ошибки 503
    return
  }

  const { res, ...error } = err
  const errInfo = { req: res.req, error }
  let message = errorNameMap[err.status]
  console.error(`Api call error:\n ${JSON.stringify(errInfo, null, 2)}`)

  if (err.res.data) {
    message = `${message}: ${err.res.data.message}`
  }

  if (err.status === 'fail' && excludedCodes.includes(err.data.errorCode)) {
    return
  }

  Sentry.captureEvent({
    message,
    extra: errInfo,
  })
}

export const errorNameMap = {
  error: 'ошибка сервера error',
  fail: 'ошибка сервера fail',
  'io-ts error': 'ошибка валидации API',
  'unknown error': 'неизвесная ошибка',
}
