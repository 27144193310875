import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import {
  Id,
  Version,
  VersionData,
  VersionDates,
  Versions,
} from '@gmini/sm-api-sdk'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

// eslint-disable-next-line import/no-cycle
import { createPredicate } from '../createPredicate'

import { InspectionIssue } from './InspectionIssue'
import { InspectionChecklist } from './InspectionChecklist'
import { InspectionForgeIssue } from './InspectionForgeIssue'
import { InspectionForgeChecklist } from './InspectionForgeChecklist'

export interface Inspection {
  readonly type: 'FieldInspection'
  readonly id: Id
  readonly version: Version
  readonly parentFolderId: null | number
  readonly name: string
  readonly classifierId: Id
  readonly classifierVersion: Version
  readonly readOnly: boolean
  readonly owner: string | null
  readonly versionNameOwner: string | null
  readonly versionCreatedDate: string
  readonly versionName: string | null
  readonly checklists: (InspectionForgeChecklist | InspectionChecklist)[] | null
  readonly issues: (InspectionForgeIssue | InspectionIssue)[] | null | undefined
  readonly projectUrn: string
}

export namespace Inspection {
  const domain = ApiDomain.domain('Inspection')

  export const is = createPredicate<Inspection>('FieldInspection')

  export const io: t.Type<Inspection> = t.type({
    type: t.literal('FieldInspection'),
    id: Id,
    version: Version,
    classifierId: Id,
    classifierVersion: Version,
    parentFolderId: t.union([t.null, Id]),
    name: t.string,
    readOnly: t.boolean,
    owner: t.union([t.null, t.string]),
    versionNameOwner: t.union([t.null, t.string]),
    versionCreatedDate: t.string,
    versionName: t.union([t.null, t.string]),
    checklists: t.union([
      t.null,
      t.array(t.union([InspectionForgeChecklist.io, InspectionChecklist.io])),
    ]),
    issues: t.union([
      t.null,
      t.array(t.union([InspectionIssue.io, InspectionForgeIssue.io])),
      t.undefined,
    ]),
    projectUrn: t.string,
  })

  export const create = ApiCallService.create<
    {
      readonly parentFolderId?: null | Id
      readonly name: string
      readonly projectUrn: string
    },
    Inspection
  >({
    name: 'create',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['name', 'parentFolderId'],
  })

  export const copy = ApiCallService.create<
    {
      readonly id: Id
      readonly version: Version
      readonly name: string
    },
    Inspection
  >({
    name: 'copy',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['name', 'id'],
  })

  export const rename = ApiCallService.create<
    {
      readonly id: Id
      readonly version: Version
      readonly parentFolderId: null | Id
      readonly name: string
    },
    Inspection
  >({
    name: 'rename',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['id', 'parentFolderId', 'name'],
  })

  export const move = ApiCallService.create<
    {
      readonly id: Id
      readonly version: Version
      readonly parentFolderId: null | Id
    },
    Inspection
  >({
    name: 'move',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['id', 'parentFolderId'],
  })

  export const remove = ApiCallService.create<{ readonly id: Id }, Inspection>({
    name: 'remove',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['id'],
  })

  export const getMostRecent = ApiCallService.create<
    { readonly id: Id },
    Inspection
  >({
    name: 'getMostRecent',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['id'],
  })

  export const getMostRecentSilent = ApiCallService.create<
    { readonly id: Id },
    Inspection
  >({
    name: 'getMostRecentSilent',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['id'],
  })

  export const fetch = ApiCallService.create<
    {
      readonly id: number
      readonly version: Version
    },
    Inspection
  >({
    name: 'fetch',
    parentDomain: domain,
    result: Inspection.io,
    getKey: ['id', 'version'],
  })

  // export const start = ApiCallService.create<
  //   {
  //     readonly estimationId: number
  //     readonly estimationVersion: Version
  //   },
  //   unknown
  // >({
  //   name: 'start',
  //   parentDomain: domain,
  //   result: t.unknown,
  //   getKey: ['estimationId', 'estimationVersion'],
  // })

  // export const modelElementEstimationStatus = ApiCallService.create<
  //   {
  //     readonly estimationId: Id
  //     readonly estimationVersion: Version
  //     readonly modelId: Id
  //   },
  //   ModelElementStatus
  // >({
  //   name: 'modelElementEstimationStatus',
  //   parentDomain: domain,
  //   result: ModelElementStatus.io,
  //   getKey: ['estimationId', 'estimationVersion', 'modelId'],
  // })

  export const fetchVersionDates = ApiCallService.create<
    {
      readonly inspectionId: Id
    },
    VersionDates
  >({
    name: 'modelElementEstimationStatus',
    parentDomain: domain,
    result: VersionDates.io,
    getKey: ['inspectionId'],
  })

  export const fetchVersionByDate = ApiCallService.create<
    {
      readonly inspectionId: Id
      readonly versionDate: string
    },
    Versions
  >({
    name: 'modelElementEstimationStatus',
    parentDomain: domain,
    result: Versions.io,
    getKey: ['inspectionId'],
  })

  export const renameVersion = ApiCallService.create<
    {
      readonly inspectionId: Id
      readonly inspectionVersion: Version
      readonly versionName: string
    },
    VersionData
  >({
    name: 'renameVersion',
    parentDomain: domain,
    result: VersionData.io,
    getKey: ['inspectionId', 'inspectionVersion', 'versionName'],
  })

  export const removeVersionName = ApiCallService.create<
    {
      readonly inspectionId: Id
      readonly inspectionVersion: Version
    },
    VersionData
  >({
    name: 'removeVersionName',
    parentDomain: domain,
    result: VersionData.io,
    getKey: ['inspectionId', 'inspectionVersion'],
  })

  export const fetchNamedVersions = ApiCallService.create<
    {
      readonly inspectionId: Id
    },
    Versions
  >({
    name: 'fetchNamedVersions',
    parentDomain: domain,
    result: Versions.io,
    getKey: ['inspectionId'],
  })
}
