import { createStore } from 'effector'

import * as smApi from '@gmini/sm-api-sdk'
import { useStore, useStoreMap } from 'effector-react'

export const fetchUserList = smApi.Auth.User.fetchList.createContext()
export const fetchUserListPending$ = fetchUserList.pending$
export const fetchAllUserList = smApi.Auth.User.fetchList.createContext()
export const fetchAllUserListPending$ = fetchAllUserList.pending$

export const userList$ = createStore<smApi.Auth.UserData[]>([]).on(
  fetchUserList.doneData,
  (state, result) => result || state,
)

export function useUserList() {
  return useStore(userList$)
}

export function useFilteredUserList(filterNameValue: string) {
  return useStoreMap({
    store: userList$,
    keys: [filterNameValue],
    fn: (users, [filterName]) =>
      filterName
        ? users.filter(user =>
            user.name.toLowerCase().includes(filterName.toLowerCase()),
          )
        : users,
  })
}

export const allUserList$ = createStore<smApi.Auth.UserData[]>([]).on(
  fetchAllUserList.doneData,
  (state, result) => result || state,
)
