import { createStore, merge, restore, sample } from 'effector'
import { clone } from 'ramda'

import * as chmApi from '@gmini/chm-api-sdk'

import { resetChecklistTreeStatuses } from '../../model/checklist-status'
import { templateTypes$ } from '../templateTypes'
import { createAssigneeGroupListService } from '../../../../services/createAssigneeGroupListService'
import { userList$ } from '../../model/user.store'
import { roleList$ } from '../../model/role.store'

export type InstancePopulated = chmApi.Instance.InstancePopulated

export const fetchChecklistItem = chmApi.Instance.fetchMostRecent.createContext()
export const updateChecklist = chmApi.Instance.update.createContext()
export const updateChecklistPending$ = updateChecklist.pending$

export const fetchTemplateItem = chmApi.Template.fetchTemplateMostRecent.createContext()

export type ChecklistData = InstancePopulated & {
  templateTypeDescription?: string
  completedItemsCount: number
  totalQuestions?: number
  totalItems?: number
  completedSectionCount?: number
  sectionsCount?: number
}

type ChecklistById = {
  [id: string]: ChecklistData | undefined
}

fetchChecklistItem.doneData.watch(({ templateId }) => {
  if (templateId) {
    fetchTemplateItem({ id: templateId })
  }
})

updateChecklist.doneData.watch(instance => {
  if (instance.templateId) {
    fetchTemplateItem({ id: instance.templateId })
  }
  resetChecklistTreeStatuses()
})

const fetchTemplateItemWithTemplateTypes = sample({
  clock: fetchTemplateItem.doneData,
  source: templateTypes$,
  fn: (templateTypes, templateItem) => ({
    ...templateItem,
    templateTypeDescription: templateTypes.find(
      templateType => templateType.id === templateItem.templateTypeId,
    )?.name,
  }),
})

export const templateItemWithTemplateTypes$ = restore(
  fetchTemplateItemWithTemplateTypes,
  null,
)

export const checklistById$ = createStore<ChecklistById>({})
  .on(
    merge([updateChecklist.doneData, fetchChecklistItem.doneData]),
    (state, result) => {
      const next = clone(state)

      next[result.id] = {
        ...next[result.id],
        ...result,
        completedItemsCount: result.itemProperties.length,
      }

      return next
    },
  )
  .on(fetchTemplateItemWithTemplateTypes, (state, result) => {
    const next = Object.fromEntries(
      Object.entries(state).map(([checklistId, checklist]) => {
        if (checklist?.templateId === result.id) {
          const totalQuestions =
            result?.sections?.reduce(
              (acc, item) => (item.items ? acc + item.items.length : acc),
              0,
            ) || 0
          const answersIds = checklist.itemProperties.map(
            answer => answer.templateSectionItemId,
          )

          const completedSectionCount =
            result.sections.filter(section =>
              section.items?.every(question =>
                answersIds.includes(question.id),
              ),
            ).length || 0

          return [
            checklistId,
            {
              ...checklist,
              templateTypeDescription: result.templateTypeDescription,
              totalQuestions,
              completedSectionCount,
              countSection: result.sections.length,
              totalItems: totalQuestions,
              sectionsCount: result?.sections?.length || 0,
            },
          ]
        }

        return [checklistId, checklist]
      }),
    )

    return next
  })

//TODO объединить с такими же сторами из renderIssue.store после добавления компаний в чек-листы
export const {
  assigneeGroupList$: assigneeGroupListChecklist$,
  assigneeUserList$: assigneeUserListChecklist$,
  assigneeRoleList$: assigneeRoleListChecklist$,
  assigneeCompanyList$: assigneeCompanyListChecklist$,
  mapRoleToAssigneeOption,
  mapUserToAssigneeOption,
} = createAssigneeGroupListService({ roleList$, userList$ })
