import { combine } from 'effector'

import * as api from '../../../api'

import {
  fetchAllUserListPending$,
  fetchUserListPending$,
} from '../model/user.store'
import { fetchCompanyListPending$ } from '../model/company.store'

import { fetchRoleListPending$ } from '../model/role.store'

import {
  createChecklistLinkPending$,
  removeChecklistLinkPending$,
} from './Checklists/checklist.store'
import { fetchChecklistItem } from './Checklists/renderChecklist.store'

import { fetchStatusListPending$ } from './Checklists/instanceStatus.store'

export const editorPending$ = combine(
  [
    fetchChecklistItem.pending$,
    createChecklistLinkPending$,
    removeChecklistLinkPending$,
    api.InspectionIssue.createLinkIssue.defaultContext.pending$,
    api.InspectionIssue.removeLinkIssue.defaultContext.pending$,
    fetchStatusListPending$,
    fetchUserListPending$,
    fetchRoleListPending$,
    fetchCompanyListPending$,
    fetchAllUserListPending$,
  ],
  pendings => pendings.some(Boolean),
)
