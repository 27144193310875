import { useStore } from 'effector-react'

import React, { useEffect } from 'react'

import { Button } from '@gmini/ui-kit'

import { useSnackbar } from 'notistack'

import { inspectionService } from '../../../../services/inspectionService'

import {
  Popover,
  WarningActions,
  WarningContainer,
  WarningDeleteText,
} from '../Issues/RenderIssue.styled'

import { InspectionChecklist } from '../../../../api'

import { fetchAllUserList, fetchUserList } from '../../model/user.store'
import { fetchRoleList } from '../../model/role.store'

import { RenderChecklistContainer } from './RenderChecklistContainer'
import { CreateChecklist } from './CreateChecklist'
import { removeChecklistLink } from './checklist.store'
import { checklistById$, fetchChecklistItem } from './renderChecklist.store'

export type ChecklistsProps = {
  groupId: number
  widthFormulaResizableCol?: number
  projectUrn: string
}

export const Checklists = ({
  groupId,
  widthFormulaResizableCol,
  projectUrn,
}: ChecklistsProps) => {
  const inspection = useStore(inspectionService.inspection.currentInspection$)!
  const checklistsById = useStore(checklistById$)

  const [
    anchorElPopover,
    setAnchorElPopover,
  ] = React.useState<HTMLButtonElement | null>(null)

  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    fetchAllUserList.submit({})
    fetchUserList({
      projectUrn,
    })
    fetchRoleList.submit()
  }, [projectUrn])

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const handleClosePopover = () => {
    setAnchorElPopover(null)
  }

  const openPopover = Boolean(anchorElPopover)

  const currChecklistRef = inspection.checklists?.find(
    ch => ch.groupId === groupId && ch.type !== 'FieldInspectionForgeChecklist',
  ) as InspectionChecklist | undefined

  if (currChecklistRef) {
    return (
      <>
        <RenderChecklistContainer
          checklistId={currChecklistRef.checklistId}
          widthFormulaResizableCol={widthFormulaResizableCol}
          setAnchorElPopover={setAnchorElPopover}
          type={currChecklistRef.type}
          projectUrn={projectUrn}
        />
        <Popover
          anchorEl={anchorElPopover}
          open={openPopover}
          onClose={handleClosePopover}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          <WarningContainer>
            <WarningDeleteText>
              Вы действительно хотите отвязать чек-лист?
            </WarningDeleteText>
            <WarningActions>
              <Button
                color='warning'
                onClick={async () => {
                  handleClosePopover?.()
                  const checklist = checklistsById[currChecklistRef.checklistId]
                  if (
                    currChecklistRef.type === 'FieldInspectionChecklist' &&
                    typeof checklist?.id === 'number'
                  ) {
                    const currentInstance = await fetchChecklistItem({
                      id: checklist.id,
                    })

                    if (
                      currentInstance.status === 'FINISHED' &&
                      !currentInstance.deleted
                    ) {
                      notify(
                        'Невозможно отвязать чек-лист со статусом "Завершен"',
                      )
                      return
                    }

                    removeChecklistLink({
                      inspectionChecklistId: currChecklistRef.id,
                    })
                  }
                }}
                size='regular'
              >
                Отвязать
              </Button>
              <Button
                size='regular'
                color='secondary'
                onClick={handleClosePopover}
              >
                Отмена
              </Button>
            </WarningActions>
          </WarningContainer>
        </Popover>
      </>
    )
  }

  return (
    <CreateChecklist
      groupId={groupId}
      widthFormulaResizableCol={widthFormulaResizableCol}
      projectUrn={projectUrn}
    />
  )
}
