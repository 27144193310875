import { combine, restore } from 'effector'
import * as smApi from '@gmini/sm-api-sdk'

import { inspectionService } from '../../../services/inspectionService'

const projectList$ = restore(
  smApi.Project.fetchList.defaultContext.doneData.map(({ list }) => list),
  null,
)

export const currentProject$ = combine({
  projects: projectList$,
  inspection: inspectionService.inspection.currentInspection$,
}).map(
  ({ inspection, projects }) =>
    projects?.find(project => project.urn === inspection?.projectUrn) || null,
)
