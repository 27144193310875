import * as t from 'io-ts'

import { ApiCallService } from '@gmini/api-call-service'

import { Id } from '@gmini/sm-api-sdk'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

// eslint-disable-next-line import/no-cycle
import { createPredicate } from '../createPredicate'

// eslint-disable-next-line import/no-cycle
import { Inspection } from './Inspection'

export interface InspectionRepoFolder {
  readonly type: 'FieldInspectionRepositoryFolder'
  readonly id: Id
  readonly parentFolderId: null | Id
  readonly name: string
  readonly total: number
  readonly projectUrn: string
}

export namespace InspectionRepoFolder {
  const domain = ApiDomain.domain('InspectionRepoFolder')

  export const is = createPredicate<InspectionRepoFolder>(
    'FieldInspectionRepositoryFolder',
  )

  export const io: t.Type<InspectionRepoFolder> = t.type({
    type: t.literal('FieldInspectionRepositoryFolder'),
    id: Id,
    parentFolderId: t.union([t.null, Id]),
    name: t.string,
    total: t.number,
    projectUrn: t.string,
  })

  export interface Populated {
    readonly type: 'FieldInspectionRepositoryFolderPopulated'
    readonly id: Id
    readonly parentFolderId: null | Id
    readonly name: string
    readonly total: number
    readonly offset: number
    readonly children: ReadonlyArray<InspectionRepoFolder | Inspection>
    readonly projectUrn: string
  }

  export namespace Populated {
    export const is = createPredicate<Populated>(
      'FieldInspectionRepositoryFolderPopulated',
    )

    export const io: t.Type<Populated> = t.recursion(
      'FieldInspectionRepositoryFolderPopulated',
      () =>
        t.type({
          type: t.literal('FieldInspectionRepositoryFolderPopulated'),
          id: Id,
          parentFolderId: t.union([t.null, Id]),
          name: t.string,
          total: t.number,
          offset: t.number,
          children: t.readonlyArray(
            t.union([InspectionRepoFolder.io, Inspection.io]),
          ),
          projectUrn: t.string,
        }),
    )

    export const fetch = ApiCallService.create<{ readonly id: Id }, Populated>({
      name: 'fetch',
      parentDomain: domain,
      result: InspectionRepoFolder.Populated.io,
      getKey: ['id'],
    })

    export const fetchSilent = ApiCallService.create<
      { readonly id: Id },
      Populated
    >({
      name: 'fetchSilent',
      parentDomain: domain,
      result: InspectionRepoFolder.Populated.io,
      getKey: ['id'],
    })
  }

  export const create = ApiCallService.create<
    {
      readonly parentFolderId?: null | Id
      readonly name: string
      readonly projectUrn: string
    },
    InspectionRepoFolder
  >({
    name: 'create',
    parentDomain: domain,
    result: InspectionRepoFolder.io,
    getKey: ['name', 'parentFolderId'],
  })

  export const rename = ApiCallService.create<
    {
      readonly id: Id
      readonly parentFolderId: null | Id
      readonly name: string
    },
    InspectionRepoFolder
  >({
    name: 'rename',
    parentDomain: domain,
    result: InspectionRepoFolder.io,
    getKey: ['id', 'parentFolderId', 'name'],
  })

  export const move = ApiCallService.create<
    {
      readonly id: Id
      readonly parentFolderId: null | Id
    },
    InspectionRepoFolder
  >({
    name: 'move',
    parentDomain: domain,
    result: InspectionRepoFolder.io,
    getKey: ['id', 'parentFolderId'],
  })

  export const remove = ApiCallService.create<
    { readonly id: Id },
    InspectionRepoFolder
  >({
    name: 'remove',
    parentDomain: domain,
    result: InspectionRepoFolder.io,
    getKey: ['id'],
  })
}
