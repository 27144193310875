import { createInclusionModel } from '@gmini/common/lib/classifier-editor/DependencyTree/Inclusion'
import { byInclusion$ } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import * as smApi from '@gmini/sm-api-sdk'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { classifierService } from '../../../services/classifierService'

import { notificationService } from '../../../services/notificationService'

import { dependencyTreeModel } from './dependencyTreeModel'
import { dependencyExpandModel } from './dependencyExpandModel'

const notification = notificationService.message.filter({
  fn: smApi.NotificationEvent.is,
})

export const {
  inclusionStore$,
  currentInclusionMap$,
  filteredFlatTree$,
  loadNodeInclusion,
  loadNodeInclusionByStandardSize,
} = createInclusionModel({
  flatTree$: dependencyTreeModel.flatTree$,
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  inclusionFilterStatus$: byInclusion$,
  notification,
  expanded$: dependencyExpandModel.expanded$,
})
