import * as smApi from '@gmini/sm-api-sdk'
import { createStore } from 'effector'
import { useStore, useStoreMap } from 'effector-react'

export type Company = Omit<
  smApi.HubManagement.Company.Data,
  'id' | 'companyName'
> & {
  id: string
  label: string
}

export const fetchCompanyList = smApi.HubManagement.Company.fetchList.createContext()
export const fetchCompanyListPending$ = fetchCompanyList.pending$

export const companyList$ = createStore<Company[]>([]).on(
  fetchCompanyList.doneData,
  (state, result) =>
    result.map(company => ({
      ...company,
      id: company.id.toString(),
      label: company.companyName,
    })),
)

export function useRoleList() {
  return useStore(companyList$)
}

export function useFilteredRoleList(filterTitleValue: string) {
  return useStoreMap({
    store: companyList$,
    keys: [filterTitleValue],
    fn: (companies, [filterTitle]) =>
      filterTitle
        ? companies.filter(role =>
            role.label.toLowerCase().includes(filterTitle.toLowerCase()),
          )
        : companies,
  })
}
