import { createDependencyTreeModel } from '@gmini/common/lib/classifier-editor/DependencyTree/model/treeModel'

import { sample } from 'effector'

import { isApiFlatNode } from '@gmini/common'

import { getNode } from '@gmini/common/lib/classifier-service'

import { isNotEmpty } from '@gmini/utils'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { classifierService } from '../../../services/classifierService'

export const dependencyTreeModel = createDependencyTreeModel({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
})

export const dependencyRootNodes$ = sample({
  source: classifierService.nodes$,
  clock: dependencyTreeModel.flatTree$,
  fn: (nodes, flatTree) =>
    flatTree
      .filter(isApiFlatNode)
      .filter(({ path }) => path.length === 1)
      .map(({ ref }) => getNode(nodes, ref))
      .filter(isNotEmpty),
})
