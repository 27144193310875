import { createEffect } from 'effector'

import * as api from '../../../../api'

type ModelStatusReq = {
  modelId: number
  modelVersion: number
  urn: string
  inspectionId: number
  inspectionVersion: number
}

export const fetchBulk = createEffect({
  handler: (models: ModelStatusReq[]) => {
    const promises = Promise.all(
      models.map(async req => {
        const res = await api.CalculateModelElements.fetchModelElements.defaultContext(
          req,
        )
        return res.urn
      }),
    )
    return promises
  },
})
