import * as smApi from '@gmini/sm-api-sdk'

import { Id } from './Id'
// eslint-disable-next-line import/no-cycle
import { Node } from './Node'

export interface InspectionRepoNode {
  readonly type: 'InspectionRepoNode'
  readonly id: Id | smApi.Urn
  readonly key: string
  readonly name: string
  readonly total: number
  readonly offset: number
}

export namespace InspectionRepoNode {
  export type Type = InspectionRepoNode['type']
  export const TYPE: Type = 'InspectionRepoNode'

  export const create = ({
    offset = 0,
    ...data
  }: Omit<InspectionRepoNode, 'type' | 'offset' | 'key'> & {
    readonly offset?: number
  }): InspectionRepoNode => ({
    ...data,
    offset,
    type: TYPE,
    key: getKey(data),
  })

  export type Ref = Pick<InspectionRepoNode, 'type' | 'id'>

  export const is = (node: Node): node is InspectionRepoNode =>
    node.type === 'InspectionRepoNode'

  export const getKey = ({ id }: Pick<InspectionRepoNode, 'id'>): string =>
    `${TYPE}:${id}`

  export const getByKey = (
    nodes: Node.Map,
    key: string,
  ): null | InspectionRepoNode => {
    const node = nodes[key]
    return node && is(node) ? node : null
  }
}
