import * as t from 'io-ts'
import * as smApi from '@gmini/sm-api-sdk'

import { ApiCallService } from '@gmini/api-call-service'

import { domain } from '../../components/InspectionEditor/domain'

import { ChecklistStatus } from './ChecklistStatus'

export namespace CalculateModelElements {
  export type Response = {
    urn: string
    items: ResponseItem[]
  }

  export type ResponseItem = {
    id: string
    status: ChecklistStatus.Status
  }

  export interface CalculateModelElements {
    inspectionId: smApi.Id
    inspectionVersion: smApi.Version
    modelId: smApi.Id
    modelVersion: smApi.Version
  }

  export const ResponseItem: t.Type<ResponseItem> = t.type({
    id: t.string,
    status: ChecklistStatus.Status,
  })

  export const Response: t.Type<Response> = t.type({
    urn: t.string,
    items: t.array(ResponseItem),
  })

  export const fetchModelElements = ApiCallService.create<
    {
      inspectionId: smApi.Id
      inspectionVersion: smApi.Version
      modelId: smApi.Id
      modelVersion: smApi.Version
    },
    Response
  >({
    name: 'fetchModelElements',
    parentDomain: domain,
    getKey: () => '0',
    result: Response,
  })
}
