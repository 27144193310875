import { createClassifiersTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/ClassifiersTree'

import { createUserClassifierRepoTreeModel } from '@gmini/common/lib/classifier-editor/SelectDependencies/ClassifiersTree/createTreeModel'

import { userClassifierRepoService } from '../../../services/userClassifierRepoServiceConnect'
import {
  currentUserClassifier$,
  projectUrn$,
} from '../../CurrentUserClassifier'
import { inclusionStore$ } from '../model/inclusionModel'

import { addedDependencyIds$ } from './selectDependencies'

const userClassifierTree$ = createUserClassifierRepoTreeModel(
  userClassifierRepoService,
  projectUrn$,
)

export const { ClassifiersTree } = createClassifiersTree({
  inclusionStatus$: inclusionStore$,
  addedDependencyIds$,
  tree$: userClassifierTree$,
  currentUserClassifier$,
})
