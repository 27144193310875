import { combine, createEvent, createStore, sample } from 'effector'

import * as chmApi from '@gmini/chm-api-sdk'

import * as api from '../../../../api'

import { inspectionService } from '../../../../services/inspectionService'

export type Instance = chmApi.Instance.Instance

// TODO Вынести повторяющиеся sample в этом файле в переиспользуемую конструкцию

export const submitFetchChecklistsEffect = createEvent<string>()
export const fetchInstanceList = chmApi.Instance.fetchList.createContext()
export const fetchInstanceListPending$ = fetchInstanceList.pending$

export const ZERO_SEARCH = 'áÝé¾±µ'

export const _removeChecklistLink = api.InspectionChecklist.removeLinkChecklist.createContext()
export const removeChecklistLinkPending$ = _removeChecklistLink.pending$
export const removeChecklistLink = createEvent<{
  inspectionChecklistId: number
}>()

sample({
  source: inspectionService.inspection.currentInspection$,
  clock: removeChecklistLink,
  fn: (inspection, params) => {
    if (!inspection) {
      return null
    }

    return {
      ...params,
      inspectionId: inspection.id,
      inspectionVersion: inspection.version,
    }
  },
}).watch(params => {
  if (params) {
    _removeChecklistLink(params)
  }
})

type Ids = (string | number)[]

type ById = {
  [id: string]: Instance
}

export const _createChecklistLink = api.InspectionChecklist.createLinkChecklist.createContext()
export const createChecklistLinkPending$ = _createChecklistLink.pending$
export const createChecklistLink = createEvent<{
  groupId: number
  checklistId: number
}>()

sample({
  source: inspectionService.inspection.currentInspection$,
  clock: createChecklistLink,
  fn: (inspection, params) => {
    if (!inspection) {
      return null
    }

    return {
      ...params,
      inspectionId: inspection.id,
      inspectionVersion: inspection.version,
    }
  },
}).watch(params => {
  if (params) {
    _createChecklistLink(params)
  }
})

const byId$ = createStore<ById>({}).on(
  fetchInstanceList.doneData,
  (state, result) => {
    const next = { ...state }

    result.list.forEach(ch => (next[ch.id] = ch))
    return next
  },
)

const ids$ = createStore<Ids>([]).on(
  fetchInstanceList.done,
  (state, { params, result }) => {
    const ids = [...new Set([...state, ...result.list.map(({ id }) => id)])]
    return ids
  },
)

export const totalChecklists$ = createStore<number | null>(null).on(
  fetchInstanceList.done,
  (state, { result }) => result.total,
)

export const checklists$ = combine({
  byId: byId$,
  ids: ids$,
  totalChecklists: totalChecklists$,
})
