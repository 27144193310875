import React, { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { Box } from '@gmini/ui-kit/lib/Box'

import { combine, guard, merge, sample } from 'effector'
import { useSnackbar } from 'notistack'

import { useStore, useStoreMap } from 'effector-react'
import {
  PageContentContainer,
  useNavbar,
  ProjectBadge,
} from '@gmini/common/lib/components'
import { isNotEmpty } from '@gmini/utils'

import { useHistory } from 'react-router-dom'
import { prop } from 'ramda'

import { getFilterIsActiveStore } from '@gmini/common/lib/components/FilterPanel/utils/getFilterIsActiveStore'

import {
  Flaky,
  Header,
  HeaderLogoWrap,
  Logo,
  Resizable,
  ResizablePanel,
  PlusCircle,
  SeparatorList,
  IconButton,
  Tooltip,
  Update,
  FolderChecklist,
  Folder,
  FolderWithPlus,
  ResizableColumnStateItem,
  Cover,
  Substrate,
  FolderWithoutSubstrate,
  WithCursorIcon,
} from '@gmini/ui-kit'

import { TreeLoader } from '@gmini/ui-kit/lib/TreeLoader/TreeLoader'

import { useKeycloak } from '@react-keycloak/web'

import {
  adapter,
  isGroupType,
} from '@gmini/common/lib/classifier-service/adapters'

import * as filter from '@gmini/common/lib/classifier-editor/DependencyTree/Filters/InclusionFilter/FilterModel'

import { createModelStoreService } from '@gmini/common/lib/classifier-editor/ModelSelect/modelStore'

import { useInclusionFilter } from '@gmini/common/lib/classifier-editor/DependencyTree/Filters'

import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'

import { setCurrentGroup } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import { createBimFileSubscriptions } from '@gmini/common/lib/classifier-editor/bimFileSubscriptions'

import {
  createLockModel,
  DragLayer,
  ModelSelect,
  openExplorer,
  SelectDependencies,
  selectedModels as selectedModelsService,
  createViewerDisplayModel,
} from '@gmini/common/lib/classifier-editor'

import * as forgeViewer from '@gmini/common/lib/forge-viewer'
import { createModelManageMenu } from '@gmini/common/lib/classifier-editor/ModelManageMenu'

import {
  useReadonlyMode,
  VersionButton,
  VersionHistory,
  getModulesLinkFromEnv,
  getNode,
} from '@gmini/common'

import { userInfo$ } from '@gmini/common/lib/auth/auth'

import {
  useNamedVersions,
  useVersionName,
} from '@gmini/common/lib/components/VersionSwitch/NamedVersions'

import { VersionList } from '@gmini/common/lib/components/VersionSwitch/VersionList'
import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'
import { createVersionHistoryStore } from '@gmini/common/lib/components/VersionSwitch/versionHistoryStore'
import {
  compareDates,
  fromDateTimeToDate,
  sortByDate,
} from '@gmini/common/lib/helpers/versionDateCommon'

import { goals } from '@gmini/common/lib/metrika'

import { createStatusPanel } from '@gmini/common/lib/classifier-editor/StatusPanel'
import { getBimNode } from '@gmini/common/lib/classifier-editor/Search/utils'
import {
  getViewerId,
  validateModelTypes,
  getViewerModelUrl,
  fetchViewerToken,
} from '@gmini/common/lib/classifier-editor/Common'

import * as smApi from '@gmini/sm-api-sdk'

import { InspectionEntity } from '../../inspection-service'

import * as api from '../../api'

import { classifierService } from '../../services/classifierService'
import { notificationService } from '../../services/notificationService'

import { inspectionService } from '../../services/inspectionService'
import { CurrentInspection } from '../CurrentInspection'

import { currentUserClassifier$ } from '../CurrentUserClassifier'

import './core/inspection.init'
import './model/current/inspection.init'
import './core/viewerTheme/init'

import { envLinks, seoEventManager } from '../../config/config'

import { checklistStatus, EditorTreeWrap } from './EditorTreeWrap'
import { treeModel } from './EditorTreeWrap/treeModel'

import {
  dependencyCheckedModel,
  editorCheckedModel,
} from './model/checkedModel'
import {
  dependencyRootNodes$,
  dependencyTreeModel,
} from './model/dependencyTreeModel'
import {
  filteredFlatTree$,
  inclusionStore$,
  loadNodeInclusion,
  loadNodeInclusionByStandardSize,
} from './model/inclusionModel'

import { dependencyExpandModel } from './model/dependencyExpandModel'
import { useSelectedGroupNode } from './CurrentGroup'
import { DependencyTreeWrap } from './DependencyTreeWrap/DependencyTreeWrap'
import {
  EditorPanelHeader,
  EditorPanelHeaderIcon,
  EditorPanelHeaderTitle,
  BrandContainer,
  BrandTitle,
  BrandSecondaryWrapper,
} from './inspectionEditorPage.styled'
import { GroupSettings } from './GroupSettings'
import { getChecklistStatusColor } from './EditorTreeWrap/getChecklistStatusColor'
import { colorViewerElements } from './core/viewerTheme'
import { sourceClassifiersLoaded$ } from './core/fetchSourceCls'
import { resetChecklistTreeStatuses } from './model/checklist-status'
import { searchSourceModel } from './searchSourceModel'
import { bimFile$, ModelsTree } from './Dependencies/modelRepoTree'
import { ClassifiersTree } from './Dependencies/classifierRepoTree'
import { EstimationRepoTree } from './Dependencies/estimationRepoTree'

import { currentProject$ } from './core/project'
import { viewerDerivatives$ } from './core/fetchViwerModelDerivatives'
import { filterPanelService } from './model/filterPanelService'
import {
  SelectSourcePanel,
  selectedSourceNodes$,
} from './model/selectSourcePanel.model'
import { searchModel, Tags } from './model/searchModel'

const versionHistoryPending$ = combine(
  [
    api.Inspection.fetchNamedVersions.defaultContext.pending$,
    api.Inspection.fetchVersionDates.defaultContext.pending$,
    // api.Inspection.fetchVersionByDate.defaultContext.pending$,
  ],
  pendings => pendings.some(Boolean),
)

const { resetBimFileIds } = createBimFileSubscriptions({
  subscribe: notificationService.subscriptions.subscribeBimFile,
  unsubscribe: notificationService.subscriptions.unsubscribeBimFile,
})

//FSK-655/29

merge([
  smApi.BimReference.remove.doneData,
  smApi.BimReference.create.doneData,
  smApi.BimReference.move.doneData,
  smApi.UserClassifierGroup.create.doneData,
  smApi.UserClassifierGroup.move.doneData,
  smApi.UserClassifierGroup.rename.doneData,
  smApi.UserClassifierGroup.remove.doneData,
  smApi.UserClassifier.removeAll.doneData.map(({ id }) => ({
    parentClassifierId: id,
  })),
]).watch(({ parentClassifierId: id }) => {
  smApi.UserClassifier.getMostRecent.defaultContext.submit({ id })
})

enum TreeSections {
  viewer,
  dependencies,
  tree,
  groupSettings,
}

const selectedModels$ = selectedModelsService.models$.map(
  models => models?.map(({ viewerId }) => viewerId) || [],
)

const modelStoreService = createModelStoreService((node, viewerRef) =>
  getViewerId({
    node,
    viewerRef,
    nodes: classifierService.nodes$.getState(),
    getNodeFunc: getNode,
  }),
)

sample({
  source: inspectionService.inspection.currentInspection$,
  clock: merge([
    api.Inspection.renameVersion.doneData,
    api.Inspection.removeVersionName.doneData,
  ]),
  fn: (inspection, versionData) => ({ inspection, versionData }),
}).watch(({ inspection, versionData }) => {
  if (inspection?.version === versionData.version) {
    api.Inspection.fetch.defaultContext(inspection)
  }
})

const { subscriptions, message } = notificationService
const { versionDates$, versions$, removeVersion } = createVersionHistoryStore({
  fetchDates: api.Inspection.fetchVersionDates.doneData,
  fetchVersions: api.Inspection.fetchVersionByDate.done.map(
    ({ params, result: { versions } }) => ({
      versionDate: params.versionDate,
      versions,
    }),
  ),
  fetchNamedVersionDates: api.Inspection.fetchNamedVersions.doneData.map(
    ({ versions }) => ({
      versionDates: [
        ...new Set(
          versions.map(version => fromDateTimeToDate(version.createdDate)),
        ),
      ],
    }),
  ),
  fetchNamedVersions: api.Inspection.fetchNamedVersions.doneData.map(
    ({ versions }) =>
      versions.map(version => ({
        versionDate: fromDateTimeToDate(version.createdDate),
        versions: versions
          .filter(vrs => compareDates(vrs.createdDate, version.createdDate))
          .sort((a, b) => sortByDate(a.createdDate, b.createdDate)),
      })),
  ),
  versionNameChanged: merge([
    api.Inspection.renameVersion.doneData,
    api.Inspection.removeVersionName.doneData,
  ]).map(data => ({
    versionDate: fromDateTimeToDate(data.createdDate),
    version: data,
  })),
})

export const expandVersionModel = createExpandModel()

const { ModelManageMenu } = createModelManageMenu({
  classifierService,
  currentEntity$: currentUserClassifier$,
  bimFile$,
})

export const { StatusPanel: DependenciesStatusPanel } = createStatusPanel({
  checkedModel: dependencyCheckedModel,
  flatTree$: dependencyTreeModel.flatTree$,
  searchNodesInfo$: searchModel.searchNodes$,
  openedFilterPanel$: filterPanelService.openedFilterPanel$,
  hideIsolateButton$: getFilterIsActiveStore(searchModel.searchNodes$),
  SelectSourcePanel,
  selectedSourceNodes$,
})

export const { StatusPanel: EditorTreeStatusPanel } = createStatusPanel({
  checkedModel: editorCheckedModel,
  flatTree$: treeModel.flatTree$,
  searchNodesInfo$: searchModel.searchNodes$,
  openedFilterPanel$: filterPanelService.openedFilterPanel$,
})

const searchedBimNodes$ = sample({
  source: classifierService.nodes$,
  clock: searchModel.searchNodes$,
  fn: (nodes, search) => {
    if (!search?.nodes?.length) {
      return null
    }
    return search.nodes
      .map(({ node }) => getBimNode(node, nodes))
      .filter(isNotEmpty)
  },
})

createViewerDisplayModel({
  currentEntity$: currentUserClassifier$,
  nodes$: classifierService.nodes$,
  searchModel,
  getViewerId: (node, viewerRef) =>
    getViewerId({
      node,
      viewerRef,
      nodes: classifierService.nodes$.getState(),
      getNodeFunc: getNode,
    }),
  getClsModels: (clsId, clsVersion) => {
    const dependenciesWithModels = modelStoreService.dependenciesWithModels$.getState()
    const models =
      dependenciesWithModels
        .find(
          ({ classifier }) =>
            classifier.id === clsId && classifier.version === clsVersion,
        )
        ?.models.map(m => adapter(m)) || null

    if (!models) {
      throw new Error(
        `Models not found in classifier id: ${clsId}, version: ${clsVersion}`,
      )
    }

    // Now we are sure that model arr contain  only bim360 or Gstation models
    validateModelTypes(models)

    return models
  },
})

// Инкапсулировать логику в common после GT-984 - Front: Исправить проблему: "circular dependencies" в репозиториях
const modelUpdated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))
  .filter({ fn: smApi.BimModel.is })
  .filter({
    fn: model =>
      model.modelType === 'ForgeModel' && model.status === 'ImportCompleted',
  })

guard({
  clock: modelUpdated,
  source: currentUserClassifier$.map(cls =>
    cls ? { classifierId: cls.id, classifierVersion: cls.version } : null,
  ),
  filter: Boolean,
  target: smApi.UserClassifier.fetchFlatListDependencies.defaultContext.submit,
})

const lockStatusMessage = message.filter({
  fn: smApi.NotificationEvent.LockStatusChange.is,
})

const { lockMap$ } = createLockModel({ message: lockStatusMessage })

export const filteredDependencyTree$ = searchModel.filterTree(filteredFlatTree$)

export const InspectionEditorPage = memo(
  ({
    currentInspection,
    isCurrentVersion,
  }: {
    currentInspection: InspectionEntity
    isCurrentVersion: boolean
  }) => {
    const userInfo = useStore(userInfo$)
    const flatTree = useStore(treeModel.flatTree$)
    const history = useHistory()
    const versionHistoryPending = useStore(versionHistoryPending$)
    const selectedGroup = useSelectedGroupNode()
    const { keycloak } = useKeycloak()
    const selectedModels = useStore(selectedModels$)
    const inCreateNode = useStore(treeModel.inCreateNode$)
    const currentUserClassifier = useStore(currentUserClassifier$)
    const [selectViewerRefs, setSelectViewerRefs] = React.useState<
      Record<string, string[]>
    >({})
    const fetchFlatListDependenciesPending = useStore(
      smApi.UserClassifier.fetchFlatListDependencies.defaultContext.pending$,
    )
    const dynamicGroupsPending = useStore(
      smApi.UserClassifier.fetchDynamicGroupFlatItems.defaultContext.pending$,
    )
    const fetchFlatListItemsPending = useStore(
      smApi.UserClassifier.fetchFlatListItems.defaultContext.pending$,
    )
    const renameInspectionPending = useStore(
      api.Inspection.rename.defaultContext.pending$,
    )
    const fetchListProjectPending = useStore(
      smApi.Project.fetchList.defaultContext.pending$,
    )
    const currentProject = useStore(currentProject$)
    const viewerDerivatives = useStore(viewerDerivatives$)
    const migratePending = useStore(smApi.migrate.pending$)
    const isLock = useStoreMap({
      store: lockMap$,
      keys: [currentInspection?.classifierId],
      fn: (state, [classifierId]) => !!state[classifierId],
    })

    React.useEffect(() => {
      const subscription = searchModel.resetSearchNodes.watch(() => {
        setSelectViewerRefs({})
      })

      return () => {
        subscription.unsubscribe()
      }
    }, [])

    const versionDates = useStore(versionDates$)
    const versions = useStore(versions$)

    const [checkListTheme, setCheckListSwitch] = useState(false)

    const {
      InclusionFilterButton,
      InclusionFilterContent,
    } = useInclusionFilter()

    const fieldInspectionId = currentInspection.id
    const { classifierId } = currentInspection

    useEffect(() => () => modelStoreService.resetDependenciesWithModels(), [])

    useEffect(() => {
      if (fieldInspectionId && classifierId) {
        subscriptions.subscribeInspection({ fieldInspectionId, classifierId })
      }

      return () => {
        if (fieldInspectionId && classifierId) {
          subscriptions.unsubscribeInspection({
            fieldInspectionId,
            classifierId,
          })
        }
      }
    }, [fieldInspectionId, classifierId])

    const createRootGroup = useCallback(() => {
      treeModel.setInCreateRootNode()
    }, [])

    useEffect(
      () => () => {
        inspectionService.inspection.resetCurrent()
        setCurrentGroup(null)
        classifierService.reset()
        resetBimFileIds()
        resetChecklistTreeStatuses()
      },
      [],
    )

    useEffect(() => {
      filter.setStorageKey(
        currentInspection
          ? `inspectionDependencyFilter:${currentInspection.id}`
          : null,
      )

      return filter.reset
    }, [currentInspection])

    useEffect(() => {
      if (currentUserClassifier?.id && currentUserClassifier?.version) {
        smApi.DependencyWithModels.getClassifierDependencyModels.defaultContext(
          {
            id: currentUserClassifier.id,
            version: currentUserClassifier.version,
          },
        )
      }
    }, [currentUserClassifier?.id, currentUserClassifier?.version])

    const { readonlyMode } = useReadonlyMode()

    const [openVersionHistory, setOpenVersionHistory] = React.useState(false)

    const {
      NamedVersionsSwitch,
      namedVersions,
      closeNamedVersions,
    } = useNamedVersions({
      getNamedVersions: async () =>
        !!(await api.Inspection.fetchNamedVersions.defaultContext({
          inspectionId: currentInspection!.id,
        })),
      onDisable: () => {
        expandVersionModel.resetExpanded()
        api.Inspection.fetchVersionDates.defaultContext({
          inspectionId: currentInspection.id,
        })
      },
      onActive: () =>
        versionDates && expandVersionModel.expandAll(versionDates.versionDates),
      disabled: versionHistoryPending,
    })

    const { enqueueSnackbar } = useSnackbar()

    const onErrorGetViewerModelUrl = (errorText: string) => {
      enqueueSnackbar(errorText, {
        variant: 'error',
      })
      selectedModelsService.reset()
    }

    const onOpenVersionHistory = React.useCallback(async () => {
      await api.Inspection.fetchVersionDates.defaultContext({
        inspectionId: currentInspection.id,
      })

      setOpenVersionHistory(true)
    }, [currentInspection])

    const {
      ChangeVersionNameDialog,
      setChangeNameDialog,
      changeNameDialog,
    } = useVersionName<smApi.VersionData>({
      onSubmitChangeName: async ({ name, version }) =>
        !!(await api.Inspection.renameVersion.defaultContext({
          inspectionId: currentInspection.id,
          inspectionVersion: version,
          versionName: name,
        })),
    })

    const { ContextMenu, setCtxMenu } = useContextMenu<smApi.VersionData>([
      {
        title: 'Перейти к версии',
        onClick: ({ version }) => {
          window.open(
            `${window.location.origin}/inspection/${
              currentInspection!.id
            }/version/${version}`,
            '_blank',
          )
        },
        show: item => item.version !== currentInspection.version,
      },
      {
        title: 'Переименовать',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !!item.name,
      },
      {
        title: 'Присвоить имя',
        onClick: item => {
          setChangeNameDialog(item)
        },
        show: item => !item.name,
      },
      {
        title: 'Удалить название',
        onClick: item => {
          api.Inspection.removeVersionName
            .defaultContext({
              inspectionId: currentInspection.id,
              inspectionVersion: item.version,
            })
            .then(version => {
              if (namedVersions) {
                removeVersion({
                  versionDate: fromDateTimeToDate(version.createdDate),
                  version,
                })
              }
            })
        },
        show: item => !!item.name,
      },
    ])

    const editorChecked = useStore(editorCheckedModel.checked$)
    const dependencyChecked = useStore(dependencyCheckedModel.checked$)

    const onCloseVersionHistory = React.useCallback(() => {
      if (namedVersions) {
        closeNamedVersions()
      }
      setOpenVersionHistory(false)
    }, [closeNamedVersions, namedVersions])

    const [panelsDebouncedState, setPanelsDebouncedState] = React.useState<
      ResizableColumnStateItem[]
    >([])

    const onColumnsWidthsChanged = React.useCallback(
      (state: ResizableColumnStateItem[]) => {
        setPanelsDebouncedState(state)
      },
      [],
    )

    const onRenameEntity = (value: string) => {
      api.Inspection.rename.defaultContext.submit({
        name: value,
        parentFolderId: currentInspection.parentFolderId,
        id: currentInspection.id,
        version: currentInspection.version,
      })
    }

    const widthFormulaResizableCol =
      panelsDebouncedState[TreeSections.groupSettings]?.widthPx || 0
    const widthColMoreMin = widthFormulaResizableCol > 310

    // TODO Объединить расцветку иконки группы в дереве и тут, чтобы исключить дублирование кода
    const statusMap = useStore(checklistStatus.statusMap$)
    const checklist = currentInspection?.checklists?.find(
      ({ groupId }) => selectedGroup?.id === groupId,
    )
    let selectedGroupFolderIcon = <Folder />
    const selectedGroupNode = useMemo(
      () =>
        flatTree.find(
          ({ ref }) =>
            ref.type !== 'LoadMore' &&
            ref.type !== 'CreateNode' &&
            isGroupType(ref.type) &&
            ref.id === selectedGroup?.id,
        ),
      [flatTree, selectedGroup?.id],
    )

    const statusNodeKey =
      selectedGroupNode?.path?.[Number(selectedGroupNode?.path?.length) - 1]

    const statusInfo = statusMap[`${statusNodeKey}_${selectedGroup?.id || ''}`]
    if (checklist) {
      const color: string = getChecklistStatusColor(statusInfo?.status)
      selectedGroupFolderIcon = <FolderChecklist color={color} />
    }

    const { NavbarDropDown, opened } = useNavbar({
      navModules: getModulesLinkFromEnv(
        envLinks,
        currentProject?.urn,
        currentProject?.sourceType,
      ),
      seoEventHandler: (selectedExplorer: string) => {
        seoEventManager.push({
          event: 'ExplorersNavigation_SelectExplorer',
          payload: {
            originalExplorer: 'Стройконтроль',
            selectedExplorer,
          },
        })
      },
    })

    const projectBadge = (
      <ProjectBadge
        projectName={currentProject?.name || ''}
        loading={fetchListProjectPending}
      />
    )

    if (!currentProject) {
      return null
    }

    return (
      <>
        <DragLayer
          editorCheckedCount={Object.keys(editorChecked).length}
          depsCheckedCount={Object.keys(dependencyChecked).length}
        />
        <Box height='100%' display='flex' flexDirection='column'>
          <CurrentInspection />
          <Header
            title={currentInspection?.name}
            brandSecondary={
              <BrandSecondaryWrapper>{projectBadge}</BrandSecondaryWrapper>
            }
            titleSecondary={
              <>
                <VersionButton
                  onClick={onOpenVersionHistory}
                  isActualVersion={isCurrentVersion}
                  currentVersion={currentInspection.version}
                  versionCreated={currentInspection.versionCreatedDate}
                  versionName={currentInspection.versionName}
                  title={currentInspection?.name}
                  pending={versionHistoryPending}
                  onRename={onRenameEntity}
                  renamePending={renameInspectionPending}
                />

                <Box ml={1} flexGrow={1}>
                  <Tags
                    dataTestIdFor={{
                      root: 'findAnywhereBadge',
                      deleteIcon: 'resetFilter',
                    }}
                  />
                </Box>

                <filterPanelService.FilterButton
                  currentUserClassifier={currentUserClassifier}
                  searchModel={searchModel}
                />
              </>
            }
            onLogout={() => keycloak.logout()}
            brand={
              <Tooltip
                enterDelay={400}
                title={opened ? '' : 'Выйти к списку инспекций'}
              >
                <BrandContainer
                  data-test-id='backToExplorer'
                  to={
                    currentProject ? `/?projectUrn=${currentProject?.urn}` : '/'
                  }
                  active={opened}
                >
                  <HeaderLogoWrap>
                    <Logo />
                  </HeaderLogoWrap>
                  <BrandTitle>Стройконтроль</BrandTitle>
                  {NavbarDropDown}
                </BrandContainer>
              </Tooltip>
            }
            userInfo={userInfo}
            onProfileClick={() => goals.showUserMenu()}
          />
          <PageContentContainer data-test-id='EstimateEditor'>
            <Resizable
              foldedPanelWidthPx={50}
              onWidthChangedDelay={50}
              storageKey='InspectionEditorPanels'
              onWidthsChanged={onColumnsWidthsChanged}
            >
              <ResizablePanel
                header={
                  <Box
                    ml='-16px'
                    maxWidth='calc(100% - 32px)'
                    style={{ overflow: 'hidden' }}
                  >
                    <ModelSelect
                      dependencyRootNodes$={dependencyRootNodes$}
                      currentEntity$={currentUserClassifier$}
                      searchedBimNodes$={searchedBimNodes$}
                      modelStoreService={modelStoreService}
                      getViewerId={(node, viewerRef) =>
                        getViewerId({
                          node,
                          viewerRef,
                          nodes: classifierService.nodes$.getState(),
                          getNodeFunc: getNode,
                        })
                      }
                    />
                  </Box>
                }
                foldedTitle='Просмотр модели'
                onOpened={() => {
                  goals.expandViewer()
                }}
                onClosed={() => {
                  goals.foldViewer()
                }}
                data-test-id='ModelViewerSection'
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyVH
              >
                <forgeViewer.ViewerComponent
                  selectedModels={selectedModels}
                  resetSearchNodes={searchModel.resetSearchNodes}
                  selectViewerElements={selectViewerRefs}
                  colorViewerElements={colorViewerElements}
                  resetSelectedModels={selectedModelsService.reset}
                  loadModelByUrl
                  getModelUrl={modelUrn =>
                    getViewerModelUrl(
                      modelUrn,
                      viewerDerivatives,
                      onErrorGetViewerModelUrl,
                    )
                  }
                  fetchToken={() => fetchViewerToken(keycloak)}
                />
              </ResizablePanel>

              <ResizablePanel
                title='Зависимости'
                header={
                  <SeparatorList>
                    <Tooltip placement='top' title='Добавить модель(и)'>
                      <IconButton
                        onClick={openExplorer}
                        data-test-id='openDependencyList'
                        data-test-ui-type='CreateButton'
                        disabled={readonlyMode.enabled}
                      >
                        <PlusCircle color='rgba(53, 59, 96, 0.5)' />
                      </IconButton>
                    </Tooltip>

                    {InclusionFilterButton}
                  </SeparatorList>
                }
                headerSecondary={
                  <SeparatorList>
                    <Tooltip
                      placement='top'
                      title='Вкл/выкл окраску дерева по статусам выполнения'
                    >
                      <IconButton
                        onClick={() => setCheckListSwitch(old => !old)}
                        selected={checkListTheme}
                      >
                        <Flaky color='rgba(53, 59, 96, 0.5)' />
                      </IconButton>
                    </Tooltip>

                    <ModelManageMenu disabled={migratePending || isLock} />
                  </SeparatorList>
                }
                footer={<DependenciesStatusPanel />}
                onOpened={() => {
                  goals.expandDependencies()
                }}
                onClosed={() => {
                  goals.foldDependencies()
                }}
                data-test-id='DependenciesSection'
                body={InclusionFilterContent}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <Box
                  width='100%'
                  height='100%'
                  display='flex'
                  flexDirection='column'
                >
                  <TreeLoader
                    loading={
                      fetchFlatListDependenciesPending ||
                      dynamicGroupsPending ||
                      migratePending ||
                      isLock
                    }
                    text='Идет загрузка...'
                  />
                  <Cover
                    open={
                      currentUserClassifier?.sourceClassifiers?.length === 0 &&
                      !fetchFlatListDependenciesPending
                    }
                    icon={
                      <Substrate>
                        <WithCursorIcon>
                          <PlusCircle color='#DADADA' width={45} height={45} />
                        </WithCursorIcon>
                      </Substrate>
                    }
                    title={
                      <>
                        Добавьте Модели в “Зависимости”
                        <br /> для начала работы
                      </>
                    }
                  />
                  <DependencyTreeWrap
                    currentUserClassifier$={currentUserClassifier$}
                    dependencyCheckedModel={dependencyCheckedModel}
                    dependencyExpandModel={dependencyExpandModel}
                    dependencyTreeModel={dependencyTreeModel}
                    loadNodeInclusion={loadNodeInclusion}
                    loadNodeInclusionByStandardSize={
                      loadNodeInclusionByStandardSize
                    }
                    filteredFlatTree$={filteredDependencyTree$}
                    inclusionStore$={inclusionStore$}
                    nodes$={classifierService.nodes$}
                    searchModel={searchModel}
                    currentInspection={currentInspection}
                    checkListTheme={checkListTheme}
                    sourceClassifiersLoaded$={sourceClassifiersLoaded$}
                    searchSourceData$={searchSourceModel.searchSourceData$}
                    selectForgeRefs={setSelectViewerRefs}
                    openedFilterPanel$={filterPanelService.openedFilterPanel$}
                    selectedSourceNodes$={selectedSourceNodes$}
                    sources={filterPanelService.sources}
                  />
                  <SelectDependencies
                    ClassifiersTree={ClassifiersTree}
                    ModelsTree={ModelsTree}
                    EstimationRepoTree={EstimationRepoTree}
                    brand={projectBadge}
                  />
                </Box>
              </ResizablePanel>

              <ResizablePanel
                title='Мои задачи'
                header={
                  <Tooltip placement='top' title='Добавить папку в Мои задачи'>
                    <IconButton
                      onClick={() => createRootGroup()}
                      data-test-id='addRootGroup'
                      data-test-ui-type='CreateButton'
                      disabled={readonlyMode.enabled}
                    >
                      {' '}
                      <FolderWithPlus color='rgba(53, 59, 96, 0.5)' />
                    </IconButton>
                  </Tooltip>
                }
                headerSecondary={
                  <Tooltip
                    placement='top'
                    title='Обновить статусы чек-листов и замечаний'
                  >
                    <IconButton onClick={() => resetChecklistTreeStatuses()}>
                      <Update color='rgba(53, 59, 96, 0.5)' />
                    </IconButton>
                  </Tooltip>
                }
                onOpened={() => {
                  goals.expandClassifier()
                }}
                onClosed={() => {
                  goals.foldClassifier()
                }}
                data-test-id='ElementsSection'
                footer={<EditorTreeStatusPanel />}
                bodyStyles={{ borderRight: '' }}
                hideCssOnlyDN
              >
                <TreeLoader
                  loading={
                    fetchFlatListItemsPending || migratePending || isLock
                  }
                  text='Идет загрузка...'
                />
                <Cover
                  open={
                    currentUserClassifier?.children.length === 0 &&
                    !fetchFlatListItemsPending &&
                    !inCreateNode
                  }
                  icon={
                    <Substrate>
                      <WithCursorIcon>
                        <FolderWithPlus
                          color='#DADADA'
                          width={45}
                          height={45}
                        />
                      </WithCursorIcon>
                    </Substrate>
                  }
                  title={
                    <>
                      Создайте желаемую структуру <br /> папок и перенесите в
                      них необходимые <br />
                      элементы из зависимостей
                    </>
                  }
                />
                <EditorTreeWrap
                  selectViewerRefs={setSelectViewerRefs}
                  dependenciesWithModels$={
                    modelStoreService.dependenciesWithModels$
                  }
                  searchModel={searchModel}
                />
              </ResizablePanel>

              <ResizablePanel
                foldedTitle='Статус'
                data-test-id='FormulaSection'
                onOpened={() => {
                  goals.expandGroupSettings()
                }}
                onClosed={() => {
                  goals.foldGroupSettings()
                }}
                noHeaderSeparator
                bodyStyles={{ borderRight: '' }}
                header={
                  //TODO - Сделать переиспользуемый компонент с кодом ниже
                  <EditorPanelHeader>
                    <EditorPanelHeaderIcon>
                      {selectedGroupFolderIcon}
                    </EditorPanelHeaderIcon>
                    <EditorPanelHeaderTitle data-test-ui-type='Text'>
                      {(widthColMoreMin || !selectedGroup?.name) && 'Статус'}
                      {widthColMoreMin && selectedGroup?.name && `: `}
                      {selectedGroup?.name}
                    </EditorPanelHeaderTitle>
                  </EditorPanelHeader>
                }
              >
                <Cover
                  open={
                    !selectedGroup &&
                    currentUserClassifier?.children.length !== 0
                  }
                  icon={
                    <Substrate p='19px 16px 20px 16px'>
                      <FolderWithoutSubstrate width={40} height={33} />
                    </Substrate>
                  }
                  title={<>Ни одна папка в третьей колонке не выбрана</>}
                  pb='46px'
                />
                <GroupSettings
                  widthFormulaResizableCol={widthFormulaResizableCol}
                />
              </ResizablePanel>
            </Resizable>
          </PageContentContainer>
          <VersionHistory
            open={openVersionHistory}
            onClose={onCloseVersionHistory}
            onRedirectToLast={() => {
              history.push(`/inspection/${currentInspection.id}`)
            }}
            isCurrentVersion={isCurrentVersion}
            beforeList={NamedVersionsSwitch}
            versionList={
              <VersionList
                onMenuClick={setCtxMenu}
                currentVersion={currentInspection.version}
                dates={versionDates}
                versions={versions}
                fetchVersions={async versionDate => {
                  await api.Inspection.fetchVersionByDate.defaultContext({
                    inspectionId: currentInspection.id,
                    versionDate,
                  })
                }}
                anotherListOpened={namedVersions}
                contextMenu={!changeNameDialog && ContextMenu()}
                openVersionHistory={openVersionHistory}
                expandModel={expandVersionModel}
              />
            }
          />

          <filterPanelService.FilterPanel
            classifierId={currentInspection.classifierId}
            classifierVersion={currentInspection.classifierVersion}
            searchModel={searchModel}
            nodes$={classifierService.nodes$}
            selectViewerRefs={setSelectViewerRefs}
            currentEntity={currentUserClassifier}
            dependenciesWithModels$={modelStoreService.dependenciesWithModels$}
          />

          {ChangeVersionNameDialog()}
        </Box>
      </>
    )
  },
)

InspectionEditorPage.displayName = 'EstimationEditorPage'
