import * as t from 'io-ts'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { Id } from '@gmini/sm-api-sdk'

import { ApiCallService } from '@gmini/api-call-service'

import { InspectionItem } from './common'

export namespace IssueStatus {
  const domain = ApiDomain.domain('IssueStatus')

  export type IssueItem = InspectionItem.InspectionRequestItem & {
    hasIssues: boolean
    childrenHasIssues: boolean
  }

  export const IssueItem: t.Type<IssueItem> = t.type({
    itemId: Id,
    itemType: InspectionItem.Type,
    groupId: Id,
    hasIssues: t.boolean,
    childrenHasIssues: t.boolean,
  })

  export type DependencyIssueItem = Omit<IssueItem, 'groupId'>
  export type FetchIssueItemsParams = Omit<
    InspectionItem.FetchInspectionItemsParams,
    'items'
  >

  export const DependencyIssueItem: t.Type<DependencyIssueItem> = t.type({
    itemId: Id,
    itemType: InspectionItem.Type,
    hasIssues: t.boolean,
    childrenHasIssues: t.boolean,
  })
  export interface ItemsStatus {
    items: IssueItem[]
  }

  export const ItemsStatus: t.Type<ItemsStatus> = t.type({
    items: t.array(IssueItem),
  })
  export interface DependencyItemsStatus {
    items: DependencyIssueItem[]
  }

  export const DependencyItemsStatus: t.Type<DependencyItemsStatus> = t.type({
    items: t.array(DependencyIssueItem),
  })

  export const fetchItemsStatus = ApiCallService.create<
    FetchIssueItemsParams,
    ItemsStatus
  >({
    name: 'fetchItemsStatus',
    parentDomain: domain,
    getKey: ['fieldInspectionId', 'fieldInspectionVersion'],
    result: ItemsStatus,
  })

  export const fetchDependencyItemsStatus = ApiCallService.create<
    InspectionItem.FetchDependencyItemsParams,
    DependencyItemsStatus
  >({
    name: 'fetchDependencyItemsStatus',
    parentDomain: domain,
    getKey: [
      'fieldInspectionId',
      'fieldInspectionVersion',
      'sourceClassifierId',
      'sourceClassifierVersion',
    ],
    result: DependencyItemsStatus,
  })
}
