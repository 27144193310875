import { combine, merge, sample } from 'effector'
import { useStore } from 'effector-react'

import { getNode } from '@gmini/common/lib/classifier-service'
import * as smApi from '@gmini/sm-api-sdk'

import {
  DynamicBaseGroupNode,
  UserClassifierGroupNode,
} from '@gmini/common/lib/classifier-service/Node'

import { isApiFlatNode } from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'
import {
  currentGroup$,
  setCurrentGroup,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'
import { findInFlatTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import {
  apiToNodeTypeMap,
  isGroupType,
} from '@gmini/common/lib/classifier-service/adapters'

import { classifierService } from '../../services/classifierService'

import { treeModel } from './EditorTreeWrap/treeModel'

const selectedGroupNode$ = sample({
  clock: currentGroup$,
  source: combine({
    flatTree: treeModel.flatTree$,
    nodes: classifierService.nodes$,
  }),
  fn: ({ nodes, flatTree }, currentGroup) => {
    if (currentGroup) {
      const flatNode = findInFlatTree(flatTree, currentGroup.path)
      if (flatNode && isApiFlatNode(flatNode)) {
        return getNode(nodes, flatNode.ref) as
          | UserClassifierGroupNode
          | DynamicBaseGroupNode
      }
    }
    return null
  },
})

merge([
  smApi.UserClassifier.removeAll.done.map(({ params: { items } }) =>
    items.filter(({ type }) => isGroupType(apiToNodeTypeMap[type])),
  ),
  smApi.UserClassifierGroup.remove.doneData.map(group => [group]),
]).watch(removedGroups => {
  if (
    removedGroups.some(({ id }) => id === selectedGroupNode$.getState()?.id)
  ) {
    setCurrentGroup(null)
  }
})

export function useSelectedGroupNode():
  | UserClassifierGroupNode
  | DynamicBaseGroupNode
  | null {
  return useStore(selectedGroupNode$)
}
