import { FetchService } from '@gmini/utils'

// eslint-disable-next-line import/no-cycle
import * as api from '../../api'

export function inspectionConnect(inspectionFetch: FetchService) {
  api.InspectionRepo.Populated.fetch.use(({ projectUrn }) =>
    inspectionFetch
      .get(`/v1/field-inspection-repo/project/${projectUrn}`)
      .then(api.InspectionRepo.Populated.fetch.toPromise),
  )

  api.InspectionRepoFolder.create.use(({ parentFolderId, name, projectUrn }) =>
    inspectionFetch
      .post(`/v1/field-inspection-repo/project/${projectUrn}/folder`, {
        parentFolderId,
        name,
      })
      .then(api.InspectionRepoFolder.create.toPromise),
  )

  api.InspectionRepoFolder.Populated.fetch.use(({ id }) =>
    inspectionFetch
      .get(`/v1/field-inspection-repo/folder/${id}`)
      .then(api.InspectionRepoFolder.Populated.fetch.toPromise),
  )

  api.InspectionRepoFolder.Populated.fetchSilent.use(({ id }) =>
    inspectionFetch
      .get(`/v1/field-inspection-repo/folder/${id}`, { skip403: true })
      .then(api.InspectionRepoFolder.Populated.fetchSilent.toPromise),
  )

  api.InspectionRepoFolder.rename.use(({ id, name, parentFolderId }) =>
    inspectionFetch
      .patch(`/v1/field-inspection-repo/folder/${id}`, { name, parentFolderId })
      .then(api.InspectionRepoFolder.rename.toPromise),
  )

  api.InspectionRepoFolder.move.use(({ id, parentFolderId }) =>
    inspectionFetch
      .patch(`/v1/field-inspection-repo/folder/${id}`, { parentFolderId })
      .then(api.InspectionRepoFolder.move.toPromise),
  )

  api.InspectionRepoFolder.remove.use(({ id }) =>
    inspectionFetch
      .delete(`/v1/field-inspection-repo/folder/${id}`)
      .then(api.InspectionRepoFolder.remove.toPromise),
  )

  api.Inspection.create.use(({ parentFolderId, name, projectUrn }) =>
    inspectionFetch
      .post(
        `/v1/field-inspection-repo/project/${projectUrn}/field-inspection`,
        {
          parentFolderId,
          name,
        },
      )
      .then(api.Inspection.create.toPromise),
  )

  api.Inspection.copy.use(({ id, name, version }) =>
    inspectionFetch
      .post(
        `/v1/field-inspection-repo/field-inspection/${id}/version/${version}/copy`,
        {
          name,
        },
      )
      .then(api.Inspection.create.toPromise),
  )

  api.Inspection.rename.use(({ id, name, parentFolderId, version }) =>
    inspectionFetch
      .patch(
        `/v1/field-inspection-repo/field-inspection/${id}/version/${version}`,
        {
          name,
          parentFolderId,
        },
      )
      .then(api.Inspection.rename.toPromise),
  )

  api.Inspection.move.use(({ id, parentFolderId, version }) =>
    inspectionFetch
      .patch(
        `/v1/field-inspection-repo/field-inspection/${id}/version/${version}`,
        {
          parentFolderId,
        },
      )
      .then(api.Inspection.move.toPromise),
  )

  api.Inspection.remove.use(({ id }) =>
    inspectionFetch
      .delete(`/v1/field-inspection-repo/field-inspection/${id}`)
      .then(api.Inspection.remove.toPromise),
  )

  api.Inspection.getMostRecent.use(({ id }) =>
    inspectionFetch
      .get(`/v1/field-inspection-repo/field-inspection/${id}/most-recent`)
      .then(api.Inspection.getMostRecent.toPromise),
  )

  api.Inspection.getMostRecentSilent.use(({ id }) =>
    inspectionFetch
      .get(`/v1/field-inspection-repo/field-inspection/${id}/most-recent`)
      .then(api.Inspection.getMostRecentSilent.toPromise),
  )

  api.Inspection.fetch.use(({ id, version }) =>
    inspectionFetch
      .get(
        `/v1/field-inspection-repo/field-inspection/${id}/version/${version}`,
      )
      .then(api.Inspection.fetch.toPromise),
  )

  api.Inspection.fetchVersionDates.use(({ inspectionId }) =>
    inspectionFetch
      .get(
        `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/date`,
      )
      .then(api.Inspection.fetchVersionDates.toPromise),
  )

  api.Inspection.fetchVersionByDate.use(({ inspectionId, versionDate }) =>
    inspectionFetch
      .get(
        `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/date/${versionDate}`,
      )
      .then(api.Inspection.fetchVersionByDate.toPromise),
  )

  api.Inspection.renameVersion.use(
    ({ inspectionId, inspectionVersion, versionName }) =>
      inspectionFetch
        .post(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/name`,
          { versionName },
        )
        .then(api.Inspection.renameVersion.toPromise),
  )
  api.Inspection.removeVersionName.use(({ inspectionId, inspectionVersion }) =>
    inspectionFetch
      .delete(
        `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/name`,
      )
      .then(api.Inspection.removeVersionName.toPromise),
  )
  api.Inspection.fetchNamedVersions.use(({ inspectionId: estimationId }) =>
    inspectionFetch
      .get(
        `/v1/field-inspection-repo/field-inspection/${estimationId}/version/name`,
      )
      .then(api.Inspection.fetchNamedVersions.toPromise),
  )

  api.InspectionChecklist.createLinkChecklist.use(
    ({ checklistId, groupId, inspectionId, inspectionVersion }) =>
      inspectionFetch
        .post(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/checklist`,
          { checklistId, groupId },
        )
        .then(api.InspectionChecklist.createLinkChecklist.toPromise),
  )

  api.InspectionChecklist.removeLinkChecklist.use(
    ({ inspectionId, inspectionChecklistId, inspectionVersion }) =>
      inspectionFetch
        .delete(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/checklist/${inspectionChecklistId}`,
        )
        .then(api.InspectionChecklist.removeLinkChecklist.toPromise),
  )

  api.InspectionChecklist.fetch.use(
    ({ inspectionId, inspectionChecklistId, inspectionVersion }) =>
      inspectionFetch
        .get(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/checklist/${inspectionChecklistId}`,
        )
        .then(api.InspectionChecklist.fetch.toPromise),
  )

  api.ChecklistStatus.fetchTreeStatuses.use(params =>
    inspectionFetch
      .post(
        `/v1/field-inspection-repo/field-inspection/checklist/calculate-items-inspections`,
        params,
      )
      .then(api.ChecklistStatus.fetchTreeStatuses.toPromise),
  )

  api.ChecklistStatus.fetchDependencyTreeStatuses.use(params =>
    inspectionFetch
      .post(
        `/v1/field-inspection-repo/field-inspection/checklist/calculate-items-dependencies`,
        params,
      )
      .then(api.ChecklistStatus.fetchDependencyTreeStatuses.toPromise),
  )

  api.IssueStatus.fetchDependencyItemsStatus.use(params =>
    inspectionFetch
      .post(
        `/v1/field-inspection-repo/field-inspection/issue/calculate-items-dependencies`,
        params,
      )
      .then(api.IssueStatus.fetchDependencyItemsStatus.toPromise),
  )

  api.IssueStatus.fetchItemsStatus.use(
    ({ fieldInspectionId, fieldInspectionVersion }) =>
      inspectionFetch
        .get(
          `/v1/field-inspection-repo/field-inspection/${fieldInspectionId}/version/${fieldInspectionVersion}/issue/calculate-items-inspections`,
        )
        .then(api.IssueStatus.fetchItemsStatus.toPromise),
  )

  api.CalculateModelElements.fetchModelElements.use(
    ({ inspectionId, inspectionVersion, modelId, modelVersion }) =>
      inspectionFetch
        .get(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/model/${modelId}/version/${modelVersion}/calculate-model-elements`,
        )
        .then(api.CalculateModelElements.fetchModelElements.toPromise),
  )

  api.InspectionIssue.createIssue.use(
    ({ inspectionId, inspectionVersion, groupId, ...other }) =>
      inspectionFetch
        .post(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/group/${groupId}/issue`,
          { ...other },
        )
        .then(api.InspectionIssue.createIssue.toPromise),
  )

  api.InspectionIssue.createLinkIssue.use(
    ({ issueId, groupId, inspectionId, inspectionVersion }) =>
      inspectionFetch
        .post(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/issue`,
          { issueId, groupId },
        )
        .then(api.InspectionIssue.createLinkIssue.toPromise),
  )

  api.InspectionIssue.removeLinkIssue.use(
    ({ inspectionId, inspectionIssueId, inspectionVersion }) =>
      inspectionFetch
        .delete(
          `/v1/field-inspection-repo/field-inspection/${inspectionId}/version/${inspectionVersion}/issue/${inspectionIssueId}`,
        )
        .then(api.InspectionIssue.removeLinkIssue.toPromise),
  )
}
