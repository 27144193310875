import { setStoreName } from 'effector-root'
import { createGate } from 'effector-react'

import type { node } from '@gmini/common/lib/classifier-service'

import type * as api from '../../../../api'

import { domain } from './domain'

export const Inspection = createGate<{ readonly id: null | number }>({
  domain,
  defaultState: { id: null },
  name: 'Inspection',
})

export const inspectionId$ = Inspection.state.map(state => state.id || null)

setStoreName(inspectionId$, 'inspectionId$')

export const inspection$ = domain.store<null | api.Inspection>(null, {
  name: 'inspection$',
})

export const classifier$ = domain.store<null | node.UserClassifierNode>(null, {
  name: 'classifier$',
})
