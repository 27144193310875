import { createSelectDependenciesParams } from '@gmini/common'

import { classifierService } from '../../../services/classifierService'
import { currentUserClassifier$ } from '../../CurrentUserClassifier'

export const {
  addedDependencyIds$,
  currentModelsByClassifierMap$,
} = createSelectDependenciesParams({
  nodes$: classifierService.nodes$,
  currentEntity$: currentUserClassifier$,
})
