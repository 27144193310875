import { createStore } from 'effector'

import * as chmApi from '@gmini/chm-api-sdk'

export const fetchTypes = chmApi.Template.fetchTypes.createContext()

export const templateTypes$ = createStore<chmApi.Template.TemplateType[]>(
  [],
).on(fetchTypes.doneData, (state, result) => {
  if (result) {
    return result
  }

  return state
})
