import { forward, merge, sample } from 'effector'

import { prop } from 'ramda'

import { isNotEmpty } from '@gmini/utils'

import { adapter } from '@gmini/common/lib/classifier-service/adapters'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '../../../../api'

import { notificationService } from '../../../../services/notificationService'
import { inspectionService } from '../../../../services/inspectionService'

import { classifier$, inspection$, inspectionId$ } from './store'

forward({
  from: inspectionId$.updates.filterMap(id => (id ? { id } : undefined)),
  to: api.Inspection.getMostRecent.defaultContext.submit,
})

forward({
  from: inspection$.updates.filter({ fn: isNotEmpty }).map(inspection => ({
    id: inspection.classifierId,
    version: inspection.classifierVersion,
  })),
  to: smApi.UserClassifier.getMostRecent.defaultContext.submit,
})
const smUpdated = notificationService.message
  .filter({ fn: smApi.NotificationEvent.Update.is })
  .map(prop('payload'))

const updated = notificationService.message
  .filter({ fn: api.NotificationEvent.Update.is })
  .map(prop('payload'))

export const updateInspection = sample(
  inspectionId$,
  merge([
    api.Inspection.getMostRecent.doneData,
    updated.filter({ fn: api.Inspection.is }),
  ]),
  (currentInspectionId, inspection) =>
    inspection.id === currentInspectionId ? inspection : null,
).filter({ fn: isNotEmpty })

inspection$
  .on(updateInspection, (_, inspection) => inspection)
  .reset(inspectionService.Gate.close)

export const updateClassifier = sample(
  inspection$,
  merge([
    smApi.UserClassifier.getMostRecent.doneData,
    smUpdated.filter({ fn: smApi.UserClassifier.is }),
  ]),
  (currentInspection, classifier) =>
    currentInspection &&
    currentInspection.classifierId === classifier.id &&
    currentInspection.classifierVersion === classifier.version
      ? classifier
      : null,
)
  .filter({ fn: isNotEmpty })
  .map(adapter)

classifier$
  .on(updateClassifier, (_, classifier) => classifier)
  .reset(inspectionService.Gate.close)
