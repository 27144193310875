import React, { memo, useEffect } from 'react'
import { useStore } from 'effector-react'

import { Redirect, useParams } from 'react-router-dom'

import { ReadonlyModeProvider } from '@gmini/common'

import * as smApi from '@gmini/sm-api-sdk'

import * as api from '../../api'

import { inspectionService } from '../../services/inspectionService'

import {
  inspectionVersion,
  oldVersionMode$,
} from '../../services/classifierService'

import { InspectionEditorPage } from './InspectionEditorPage'

api.Inspection.fetch.doneData.watch(({ classifierId, classifierVersion }) => {
  smApi.UserClassifier.fetch.defaultContext.submit({
    id: classifierId,
    version: classifierVersion,
  })
})

export const EditorByVersionWrap = memo(() => {
  const { id, version } = useParams<{ id: string; version: string }>()

  const currentInspection = useStore(
    inspectionService.inspection.currentInspection$,
  )
  const oldVersionMode = useStore(oldVersionMode$)
  const versionReqPending = useStore(inspectionVersion.pending)

  useEffect(() => {
    smApi.Project.fetchList.defaultContext.submit()
  }, [])

  useEffect(() => {
    if (!oldVersionMode && !versionReqPending) {
      inspectionVersion({
        id: +id,
        version: +version,
      })
    }

    if (oldVersionMode?.active) {
      api.Inspection.fetch.defaultContext.submit({
        id: +id,
        version: +version,
      })
    }
  }, [versionReqPending, oldVersionMode, id, version])

  if (versionReqPending) {
    return null
  }

  if (oldVersionMode && !oldVersionMode?.active) {
    return <Redirect to={`/inspection/${id}`} />
  }

  if (!currentInspection) {
    return null
  }

  const readonlyMode = oldVersionMode?.active || !!currentInspection?.readOnly

  return (
    <ReadonlyModeProvider
      value={{
        enabled: readonlyMode,
        description: readonlyMode ? 'Расчет находится в режиме просмотра' : '',
      }}
    >
      <InspectionEditorPage
        isCurrentVersion={!oldVersionMode?.active}
        currentInspection={currentInspection}
      />
    </ReadonlyModeProvider>
  )
})
EditorByVersionWrap.displayName = 'EditorByVersionWrap'
