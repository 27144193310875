import { createEvent, createStore, Event } from 'effector'

import { ApiCallService } from '@gmini/api-call-service'
import { DynamicBimElementReferenceNode } from '@gmini/common/lib/classifier-service/Node'

import { FromApiTreeNode } from '@gmini/common'

import * as api from '../../../../api'

export type ChecklistStatusMap<I> = {
  [key: string]: I | undefined
}

export const resetChecklistTreeStatuses = createEvent()

export function createCheckListStatusModel<
  P extends
    | api.InspectionItem.FetchDependencyItemsParams
    | api.InspectionItem.FetchInspectionItemsParams
    | api.IssueStatus.FetchIssueItemsParams,
  R extends
    | api.ChecklistStatus.Response
    | api.ChecklistStatus.DependencyResponse
    | api.IssueStatus.ItemsStatus
    | api.IssueStatus.DependencyItemsStatus
>({
  onReset,
  apiCall,
  onBuildKey,
}: {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReset: Event<any>
  apiCall: ApiCallService<P, R>
  onBuildKey: (params: { item: R['items'][0]; params: P }) => string
}) {
  const statusMap$ = createStore<ChecklistStatusMap<R['items'][0]>>({})
    .on(apiCall.done, (state, { result: { items }, params }) => {
      const next: ChecklistStatusMap<R['items'][0]> = { ...state }

      items.forEach((item: R['items'][0]) => {
        const key = onBuildKey({ item, params })
        next[key] = item
      })

      return next
    })
    .reset(onReset)

  return { statusMap$ }
}

export function buildChecklistStatusKey({
  parentId,
  id,
  type,
}: {
  type:
    | api.InspectionItem.Type
    | FromApiTreeNode['type']
    | DynamicBimElementReferenceNode['type']
  id: number
  parentId: number
}): string {
  return `${type}_${id}_${parentId}`
}
