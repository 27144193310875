// eslint-disable-next-line import/no-cycle
import { InspectionRepoNode } from './InspectionRepoNode'
// eslint-disable-next-line import/no-cycle
import { InspectionRepoFolderNode } from './InspectionRepoFolderNode'
// eslint-disable-next-line import/no-cycle
import { InspectionNode } from './InspectionNode'

export type Node =
  | InspectionRepoNode
  | InspectionRepoFolderNode
  | InspectionNode

export type NodeType = Node['type']

export namespace Node {
  export type Ref =
    | InspectionRepoNode.Ref
    | InspectionRepoFolderNode.Ref
    | InspectionNode.Ref

  export type Map = Readonly<Record<string, undefined | Node>>

  export function getKey(ref: Ref): string {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return map[ref.type].getKey(ref as any)
  }

  export function getParentKey(node: Node): null | string {
    const ns = map[node.type]
    const fn = 'getParentKey' in ns ? ns.getParentKey : null
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return fn && fn(node as any)
  }

  export const getByRef = <R extends Ref>(
    nodes: Map,
    ref: Ref,
  ): null | TypeMap[R['type']] => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const ns = map[ref.type] as any
    return ns.getByKey(nodes, getKey(ref))
  }
}

const map = {
  InspectionRepoNode,
  InspectionRepoFolderNode,
  InspectionNode,
}

interface TypeMap {
  readonly InspectionRepoNode: InspectionRepoNode
  readonly InspectionRepoFolderNode: InspectionRepoFolderNode
  readonly InspectionNode: InspectionNode
}
