import * as React from 'react'
import { LinearProgress } from '@gmini/ui-kit/lib/LinearProgress'

import { useStore } from 'effector-react'

import { combine } from 'effector'

import { Folder, EstimationFilled, FolderEmpty } from '@gmini/ui-kit/lib/icons'

import { Root, Explorer, Item } from '@gmini/common/lib/Explorer'
import { ActiveItemRefs } from '@gmini/ui-kit/lib/MacosExplorer'

import {
  useCopy,
  useCreate,
  useMove,
  useRemove,
  useRename,
} from '@gmini/common/lib/Explorer/hooks'

import { createTreeModel } from '@gmini/common/lib/Explorer/Model/createTreeModel'

import { fromEntityTreeData } from '@gmini/common/lib/Explorer/Model'

import { useActivePanels } from '@gmini/common/lib/Explorer/hooks/useActivePanels'

import { usePersist } from '@gmini/common/lib/Explorer/hooks/usePersist'

import { baseExplorerOperations } from '@gmini/common/lib/Explorer/ctx/baseOperations'

import {
  preloadPending$,
  resetPreloadPending,
} from '@gmini/common/lib/Explorer/Model/tree.persist'

import { useRemoveDialog } from '@gmini/common/lib/Explorer/hooks/useRemoveDialog'

import { isNotEmpty } from '@gmini/utils'

import { useContextMenu } from '@gmini/common/lib/components/VersionSwitch/ContextMenu'

import * as smApi from '@gmini/sm-api-sdk'

import { InspectionNode } from '../../inspection-service/Node'

import { node } from '../../inspection-service'

import * as api from '../../api'

import { inspectionService } from '../../services/inspectionService'
import { notificationService } from '../../services/notificationService'

import { tree$, reset } from './Model'
import { fetchChildren } from './fetchChildren'
import { explorerTitle, EXPLORER_FOLDER_OPENED_KEY } from './constants'
import { folderCreated, inspectionCreated } from './store'

const { subscriptions } = notificationService

const { root$ } = createTreeModel({
  tree$,
  explorerTitle,
  categoriesOnTop: true,
})

const pending$ = combine(
  [api.InspectionRepo.Populated.fetch.defaultContext.pending$, preloadPending$],
  pendingList => pendingList.some(Boolean),
)

const operationsPending$ = combine(
  [
    api.Inspection.move.defaultContext.pending$,
    api.Inspection.remove.defaultContext.pending$,
    api.Inspection.create.defaultContext.pending$,
    api.Inspection.copy.defaultContext.pending$,
    api.Inspection.fetch.defaultContext.pending$,
    api.InspectionRepoFolder.rename.defaultContext.pending$,
    api.InspectionRepoFolder.move.defaultContext.pending$,
    api.InspectionRepoFolder.create.defaultContext.pending$,
    api.InspectionRepoFolder.remove.defaultContext.pending$,
    api.InspectionRepoFolder.Populated.fetch.defaultContext.pending$,
    smApi.Project.fetchList.defaultContext.pending$,
  ],
  pendingList => pendingList.some(Boolean),
)

export const EstimationExplorer = React.memo<{
  onEstimationOpen(options: { readonly id: node.Id }): void
  panelsCount: (count: number) => void
  panelWidth: number
  selectedProject: smApi.Project | null
}>(({ onEstimationOpen, panelWidth, panelsCount, selectedProject }) => {
  const pending = useStore(pending$)
  const operationsPending = useStore(operationsPending$)
  const selectedProjectUrn = selectedProject?.urn
  const persistKey = `${EXPLORER_FOLDER_OPENED_KEY}__${
    selectedProjectUrn || ''
  }`

  const root = useStore(root$)

  const tree = React.useMemo(
    () =>
      fromEntityTreeData({
        parentNode: root.reduce(
          (acc, next) => (next ? { ...acc, ...next } : acc),
          {} as Root,
        ),
        source: root.flatMap(root => root.children),
      }),
    [root],
  )

  const { activePanels, setActive, setActivePanels } = useActivePanels()

  const { toPersist, initTree } = usePersist({
    key: persistKey,
    tree,
    fetchChildren:
      api.InspectionRepoFolder.Populated.fetchSilent.defaultContext,
  })

  React.useEffect(() => {
    if (activePanels.length) {
      panelsCount(activePanels.length)
      toPersist({ activeItemRefs: activePanels })
    }
  }, [activePanels, panelsCount, toPersist])

  React.useEffect(() => {
    if (selectedProjectUrn) {
      setTimeout(() => {
        api.InspectionRepo.Populated.fetch.defaultContext.submit({
          projectUrn: selectedProjectUrn,
        })
      }, 0) //TODO Костыль для того чтобы токен успел засетиться в стор перед запросом
    }

    // Дожидаемся загрузки данных, для работы с деревом в initTree
    const subscription = api.InspectionRepo.Populated.fetch.defaultContext.done.watch(
      () => {
        initTree(tree$).then(active => {
          setActivePanels(active)
        })
      },
    )
    return () => {
      subscription.unsubscribe()
      resetPreloadPending()
    }
  }, [initTree, selectedProjectUrn, setActivePanels])

  React.useEffect(() => {
    subscriptions.subscribeExplorer({ type: 'FieldInspectionRepository' })

    return () => {
      subscriptions.unsubscribeExplorer({ type: 'FieldInspectionRepository' })
    }
  }, [])

  React.useEffect(() => () => reset(), [])

  const inspections = useStore(inspectionService.nodes$)

  const { onCreate } = useCreate({
    entityCreated: inspectionCreated,
    folderCreated,
    onCreateEntity: api.Inspection.create.defaultContext,
    onCreateFolder: api.InspectionRepoFolder.create.defaultContext,
    onOpen: onEstimationOpen,
  })

  const { onCopy } = useCopy({
    getEntity: id =>
      Object.values(inspections)
        .filter(isNotEmpty)
        .filter(InspectionNode.is)
        .find(inspection => inspection.id === id),
    onCopyEntity: api.Inspection.copy.defaultContext,
  })

  const { onRename } = useRename({
    onRenameFolder: api.InspectionRepoFolder.rename.defaultContext,
    onRenameEntity: api.Inspection.rename.defaultContext,
  })
  const { onMove } = useMove({
    onMoveFolder: api.InspectionRepoFolder.move.defaultContext,
    onMoveEntity: api.Inspection.move.defaultContext,
    fetch: api.InspectionRepoFolder.Populated.fetch.defaultContext,
  })
  const { onRemove } = useRemove({
    onRemoveFolder: api.InspectionRepoFolder.remove.defaultContext,
    onRemoveEntity: api.Inspection.remove.defaultContext,
  })

  const onEntityOpen = React.useCallback(
    (id: number) => {
      onEstimationOpen({ id })
    },
    [onEstimationOpen],
  )
  const setActiveItem = React.useCallback(
    (activeItems: ActiveItemRefs) => {
      if (tree.length) {
        setActive(activeItems, tree)
      }
    },
    [setActive, tree],
  )

  const { RemoveDialog, setToRemoveNode } = useRemoveDialog(onRemove)

  const { ContextMenu, setCtxMenu, ctxMenu } = useContextMenu<{
    node: Item | null
    onOpen?: () => void
  }>([
    ...baseExplorerOperations({
      onRemoveItem: (item: Item) => {
        if (item.type === 'Assembly' || item.type === 'Entity') {
          setToRemoveNode(item)
          return
        }
        onRemove(item)
      },
      createEntityTitle: 'Создать инспекцию',
      dataTestId: 'createInspection',
    }),
  ])

  return (
    <>
      <inspectionService.Gate />

      {pending ? (
        <LinearProgress />
      ) : (
        <>
          {RemoveDialog}
          <Explorer
            entityIcon={<EstimationFilled />}
            categoryIcon={<Folder />}
            categoryEmptyIcon={<FolderEmpty />}
            data={tree}
            onCreate={onCreate}
            onRename={onRename}
            onCopy={onCopy}
            onMove={onMove}
            onRemove={onRemove}
            onEntityOpen={onEntityOpen}
            setActiveItems={setActiveItem}
            activeItems={activePanels}
            fetchChildren={fetchChildren}
            pending={pending}
            panelWidth={panelWidth}
            ContextMenu={ContextMenu}
            setCtxMenu={setCtxMenu}
            ctxMenu={ctxMenu}
            hideCtxMenu={operationsPending}
            selectedProject={selectedProject}
          />
        </>
      )}
    </>
  )
})

EstimationExplorer.displayName = 'EstimationExplorer'
