import * as t from 'io-ts'
import { Store, Event, Domain, merge, createStore } from 'effector'
import { fold } from 'fp-ts/lib/Either'

import * as smApi from '@gmini/sm-api-sdk'
import { decode } from '@gmini/api-call-service'

import { prop } from 'ramda'

import { createChildDomain } from '@gmini/utils'

// eslint-disable-next-line import/no-cycle
import { NotificationEvent } from './NotificationEvent'
import {
  ExplorerSubscriptionType,
  SubscribeRequest,
  SubscriptionEvent,
  SubscriptionsApi,
} from './subscriptions'

export type WsRequestInfo = {
  id: number
  onResponse: (m: SubscriptionMessage) => void
  onError: () => void
}

export type SubscriptionMessage =
  | SubscriptionEvent.SubscribeCheckup
  | SubscriptionEvent.SubscribeClassifier
  | SubscriptionEvent.SubscribeExplorer
  | SubscriptionEvent.SubscribeEstimation
  | SubscriptionEvent.SubscribeBimFile
  | SubscriptionEvent.SubscribeAssembly
  | SubscriptionEvent.SubscribeFieldInspection

export interface NotificationService {
  readonly connected$: Store<boolean>
  readonly error: Event<NotificationService.Error>
  readonly websocketError: Event<NotificationService.Error.Websocket>
  readonly validationError: Event<NotificationService.Error.Validation>
  readonly subscriptionError: Event<NotificationService.Error.Subscription>
  readonly deserializationError: Event<NotificationService.Error.Deserialization>
  readonly message: Event<NotificationEvent>
  readonly created: Event<NotificationEvent.Create.Payload>
  readonly updated: Event<NotificationEvent.Update.Payload>
  readonly removed: Event<NotificationEvent.Remove.Payload>
  readonly inclusionStatusChanged: Event<NotificationEvent.InclusionStatusChange.Payload>
  readonly connect: Event<{ readonly url: string }>
  readonly reconnect: Event<void>
  readonly disconnect: Event<void>
  readonly subscriptions: SubscriptionsApi
}

let lastId = 0
function generateId() {
  return ++lastId
}

export namespace NotificationService {
  export function create(
    options: {
      readonly parentDomain?: Domain
    } = {},
  ): NotificationService {
    const domain = createChildDomain(
      options.parentDomain,
      'NotificationService',
    )

    const open = domain.event<globalThis.Event>()
    const close = domain.event<CloseEvent>()
    const rawMessage = domain.event<MessageEvent>()
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const deserializedMessage = rawMessage.map((messageEvent): any =>
      JSON.parse(messageEvent.data),
    )

    const validatedMessage = deserializedMessage.map(
      (message): Result<NotificationEvent, Error> => {
        try {
          const result = NotificationEvent.io.decode(message)

          return fold<
            t.Errors,
            NotificationEvent,
            Result<NotificationEvent, Error>
          >(
            errors =>
              ResultErr({
                type: 'ValidationError',
                errors: [decode(errors)],
              }),
            ResultOk,
          )(result)
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error: any) {
          return { ok: false, error: { type: 'DeserializationError', error } }
        }
      },
    )

    const error = merge<Error>([
      validatedMessage.filterMap(result => {
        if (!result.ok) {
          return result.error
        }
      }),
      close.filterMap(closeEvent => {
        if (closeEvent.code !== 1000) {
          return { type: 'WebsocketError', closeEvent }
        }
      }),
      validatedMessage
        .filter({
          fn: (result): result is ResultOk<SubscriptionEvent> =>
            result.ok && isSubscriptionMsg(result.payload),
        })
        .filterMap(({ payload: { payload } }) => {
          if (payload.status === 'Error' || payload.status === 'Fail') {
            return {
              type: 'SubscriptionError',
              error: payload.message || 'Ошибка подписки',
            }
          }
        }),
    ])

    const websocketError = error.filter({ fn: Error.Websocket.is })
    const validationError = error.filter({ fn: Error.Validation.is })
    const deserializationError = error.filter({ fn: Error.Deserialization.is })
    const subscriptionError = error.filter({ fn: Error.Subscription.is })

    const connect = domain.event<{ readonly url: string }>()
    const reconnect = domain.event<void>()
    const disconnect = domain.event<void>()

    const message = validatedMessage
      .filter({
        fn: (result): result is ResultOk<NotificationEvent> => result.ok,
      })
      .map(result => result.payload)

    const responseMessage = deserializedMessage.filter({
      fn: (result): result is ResultOk<NotificationEvent> => result,
    })

    const isSubscriptionMsg = (
      msg: NotificationEvent,
    ): msg is SubscriptionEvent =>
      msg.type === 'SubscribeEvent' || msg.type === 'UnsubscribeEvent'

    responseMessage.watch(msg => {
      if (isSubscriptionMsg(msg.payload)) {
        handleResponse(msg.payload)
      }
    })

    const requests: WsRequestInfo[] = []

    // При ответе забираем удаляем запрос из массива и вызываем
    function handleResponse(m: SubscriptionMessage) {
      const reqIdx = requests.findIndex(r => r.id === m.payload.id)
      const req = requests[reqIdx]
      requests.splice(reqIdx, 1)
      req.onResponse(m)
    }

    const url$ = domain
      .store<null | string>(null)
      .on(connect, (_, { url }) => url)
      .reset(disconnect)

    const client$ = createStore<WebSocket | null>(null)
      .on(url$.updates, (client, url) => (url ? new WebSocket(url) : client))
      .on(reconnect, client => client && new WebSocket(client.url))
      .map((client, oldClient?: null | WebSocket) => {
        if (oldClient) {
          oldClient.removeEventListener('open', open)
          oldClient.removeEventListener('close', close)
          oldClient.removeEventListener('message', rawMessage)
          oldClient.close()
        }

        if (client) {
          client.addEventListener('open', open)
          client.addEventListener('close', close)
          client.addEventListener('message', rawMessage)
        }

        return client
      })

    // Послать запрос
    function request(m: SubscribeRequest): Promise<SubscriptionMessage> {
      return new Promise((res, rej) => {
        if (connected$.getState()) {
          client$.getState()?.send(JSON.stringify(m))
        } else {
          // Делаем подписку на соединение
          const subscription = connected$.watch(connected => {
            if (connected) {
              client$.getState()?.send(JSON.stringify(m))
              setTimeout(() => {
                subscription.unsubscribe()
              }, 0)
            }
          })

          // Эта строка кода для правильной сборки webpack'ом
          // eslint-disable-next-line no-console
          console.log('connecting')
        }

        requests.push({ id: m.id, onResponse: res, onError: rej })
      })
    }

    const created = message
      .filter({ fn: NotificationEvent.Create.is })
      .map(prop('payload'))

    const updated = message
      .filter({ fn: NotificationEvent.Update.is })
      .map(prop('payload'))

    const removed = message
      .filter({ fn: NotificationEvent.Remove.is })
      .map(prop('payload'))

    const inclusionStatusChanged = message
      .filter({ fn: NotificationEvent.InclusionStatusChange.is })
      .map(prop('payload'))

    const connected$ = domain
      .store<boolean>(false)
      .on(open, () => true)
      .reset(close)

    async function subscribeExplorer({
      type,
    }: {
      type: ExplorerSubscriptionType
    }) {
      // TODO обработка ошибок
      await request({
        id: generateId(),
        method: 'Subscribe',
        subscriptionType: type,
      })
    }

    async function unsubscribeExplorer({
      type,
    }: {
      type: ExplorerSubscriptionType
    }) {
      // TODO обработка ошибок
      await request({
        id: generateId(),
        method: 'Unsubscribe',
        subscriptionType: type,
      })
    }

    async function subscribeUserClassifier({ id }: { id: number }) {
      await request({
        id: generateId(),
        method: 'Subscribe',
        subscriptionType: 'UserClassifier',
        params: { classifierId: id },
      })
    }

    async function unsubscribeUserClassifier({ id }: { id: number }) {
      await request({
        id: generateId(),
        method: 'Unsubscribe',
        subscriptionType: 'UserClassifier',
        params: { classifierId: id },
      })
    }
    async function subscribeAssemblyClassifier({ id }: { id: number }) {
      await request({
        id: generateId(),
        method: 'Subscribe',
        subscriptionType: 'AssemblyClassifier',
        params: { classifierId: id },
      })
    }

    async function unsubscribeAssemblyClassifier({ id }: { id: number }) {
      await request({
        id: generateId(),
        method: 'Unsubscribe',
        subscriptionType: 'AssemblyClassifier',
        params: { classifierId: id },
      })
    }

    async function subscribeCheckup({
      checkupId,
      classifierId,
    }: {
      checkupId: number
      classifierId: number
    }) {
      await request({
        id: generateId(),
        method: 'Subscribe',
        subscriptionType: 'Checkup',
        params: { checkupId, classifierId },
      })
    }

    async function unsubscribeCheckup({
      checkupId,
      classifierId,
    }: {
      checkupId: number
      classifierId: number
    }) {
      await request({
        id: generateId(),
        method: 'Unsubscribe',
        subscriptionType: 'Checkup',
        params: { checkupId, classifierId },
      })
    }

    async function subscribeEstimation({
      classifierId,
      estimationId,
    }: {
      classifierId: number
      estimationId: number
    }) {
      await request({
        id: generateId(),
        method: 'Subscribe',
        subscriptionType: 'Estimation',
        params: { classifierId, estimationId },
      })
    }

    async function unsubscribeEstimation({
      classifierId,
      estimationId,
    }: {
      classifierId: number
      estimationId: number
    }) {
      await request({
        id: generateId(),
        method: 'Unsubscribe',
        subscriptionType: 'Estimation',
        params: { classifierId, estimationId },
      })
    }

    async function subscribeInspection({
      classifierId,
      fieldInspectionId,
    }: {
      classifierId: number
      fieldInspectionId: number
    }) {
      await request({
        id: generateId(),
        method: 'Subscribe',
        subscriptionType: 'FieldInspection',
        params: { classifierId, fieldInspectionId },
      })
    }

    async function unsubscribeInspection({
      classifierId,
      fieldInspectionId,
    }: {
      classifierId: number
      fieldInspectionId: number
    }) {
      await request({
        id: generateId(),
        method: 'Unsubscribe',
        subscriptionType: 'FieldInspection',
        params: { classifierId, fieldInspectionId },
      })
    }

    async function subscribeBimFile({ urn }: { urn: smApi.Urn }) {
      await request({
        id: generateId(),
        method: 'Subscribe',
        subscriptionType: 'BimFile',
        params: { bimFileUrn: urn },
      })
    }

    async function unsubscribeBimFile({ urn }: { urn: smApi.Urn }) {
      await request({
        id: generateId(),
        method: 'Unsubscribe',
        subscriptionType: 'BimFile',
        params: { bimFileUrn: urn },
      })
    }

    const subscriptions = {
      subscribeExplorer,
      unsubscribeExplorer,
      subscribeUserClassifier,
      unsubscribeUserClassifier,
      subscribeAssemblyClassifier,
      unsubscribeAssemblyClassifier,
      subscribeCheckup,
      unsubscribeCheckup,
      subscribeEstimation,
      unsubscribeEstimation,
      subscribeInspection,
      unsubscribeInspection,
      subscribeBimFile,
      unsubscribeBimFile,
    }

    return {
      connected$,
      error,
      message,
      websocketError,
      subscriptionError,
      validationError,
      deserializationError,
      created,
      updated,
      removed,
      inclusionStatusChanged,
      connect,
      reconnect,
      disconnect,
      subscriptions,
    }
  }

  export type Error =
    | Error.Websocket
    | Error.Deserialization
    | Error.Validation
    | Error.Subscription

  export namespace Error {
    export interface Websocket {
      readonly type: 'WebsocketError'
      readonly closeEvent: CloseEvent
    }

    export namespace Websocket {
      export const is = (error: Error): error is Websocket =>
        error.type === 'WebsocketError'
    }

    export interface Deserialization {
      readonly type: 'DeserializationError'
      readonly error: globalThis.Error
    }

    export namespace Deserialization {
      export const is = (error: Error): error is Deserialization =>
        error.type === 'DeserializationError'
    }

    export interface Validation {
      readonly type: 'ValidationError'
      readonly errors: readonly string[]
    }

    export namespace Validation {
      export const is = (error: Error): error is Validation =>
        error.type === 'ValidationError'
    }
    export interface Subscription {
      readonly type: 'SubscriptionError'
      readonly error: string
    }

    export namespace Subscription {
      export const is = (error: Error): error is Subscription =>
        error.type === 'SubscriptionError'
    }
  }

  const ResultOk = <T>(payload: T): ResultOk<T> => ({ ok: true, payload })
  const ResultErr = <E>(error: E): ResultErr<E> => ({ ok: false, error })

  type Result<T, E> = ResultOk<T> | ResultErr<E>

  interface ResultOk<T> {
    readonly ok: true
    readonly payload: T
  }

  interface ResultErr<E> {
    readonly ok: false
    readonly error: E
  }
}
