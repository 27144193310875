import * as t from 'io-ts'

import { Id, Version } from '@gmini/sm-api-sdk'
import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { ApiCallService } from '@gmini/api-call-service'

export interface InspectionChecklist {
  id: Id
  type: 'FieldInspectionChecklist'
  parentFieldInspectionId: Id
  parentFieldInspectionVersion: Version
  groupId: Id
  checklistId: Id
}

export namespace InspectionChecklist {
  const domain = ApiDomain.domain('InspectionChecklist')

  export const io: t.Type<InspectionChecklist> = t.type({
    id: Id,
    type: t.literal('FieldInspectionChecklist'),
    parentFieldInspectionId: Id,
    parentFieldInspectionVersion: Version,
    groupId: Id,
    checklistId: Id,
  })

  export const createLinkChecklist = ApiCallService.create<
    {
      inspectionId: number
      inspectionVersion: number
      checklistId: Id
      groupId: number
    },
    InspectionChecklist
  >({
    name: 'createLinkChecklist',
    parentDomain: domain,
    result: io,
    getKey: ['inspectionVersion', 'inspectionId', 'groupId', 'checklistId'],
  })

  export const removeLinkChecklist = ApiCallService.create<
    {
      inspectionId: number
      inspectionVersion: number
      inspectionChecklistId: number
    },
    unknown
  >({
    name: 'removeLinkChecklist',
    parentDomain: domain,
    result: t.unknown,
    getKey: ['inspectionChecklistId', 'inspectionId', 'inspectionVersion'],
  })

  export const fetch = ApiCallService.create<
    {
      inspectionId: number
      inspectionVersion: number
      inspectionChecklistId: number
    },
    InspectionChecklist
  >({
    name: 'removeLinkChecklist',
    parentDomain: domain,
    result: io,
    getKey: ['inspectionChecklistId', 'inspectionId', 'inspectionVersion'],
  })
}
