import * as t from 'io-ts'

import {
  Id,
  Version,
  BaseClassifier,
  BimReference,
  AssemblyClassifier,
  DynamicBaseGroup,
  UserClassifier,
  UserClassifierGroup,
  UserClassifierRepository,
  UserClassifierRepositoryFolder,
  BimElementReferenceItems,
  UserClassifierPriorityOrder,
} from '@gmini/sm-api-sdk'

import { BimFile } from '@gmini/sm-api-sdk/lib/ModelRepo'
import { BimModel } from '@gmini/sm-api-sdk/lib//BimEntity'

// eslint-disable-next-line import/no-cycle
import {
  Inspection,
  InspectionChecklist,
  InspectionIssue,
  InspectionRepoFolder,
} from '../InspectionRepo'

import { SubscriptionEvent } from './subscriptions'

export type NotificationEvent =
  | NotificationEvent.Create
  | NotificationEvent.Update
  | NotificationEvent.Remove
  | NotificationEvent.InclusionStatusChange
  | SubscriptionEvent.Subscribe
  | SubscriptionEvent.Unsubscribe
  | NotificationEvent.AssemblyStatusChange
  | NotificationEvent.LockStatusChange

export namespace NotificationEvent {
  export interface Create {
    readonly type: 'CreateEvent'
    readonly payload: Create.Payload
  }

  export namespace Create {
    export type Payload =
      | UserClassifierRepositoryFolder
      | UserClassifier
      | UserClassifierGroup
      | BimReference
      | Inspection
      | InspectionRepoFolder
      | AssemblyClassifier
      | BimElementReferenceItems
      | InspectionIssue
      | InspectionChecklist

    export namespace Payload {
      export const io: t.Type<Payload> = t.union([
        UserClassifierRepositoryFolder.io,
        UserClassifier.io,
        UserClassifierGroup.io,
        BimReference.io,
        Inspection.io,
        InspectionRepoFolder.io,
        AssemblyClassifier.io,
        BimElementReferenceItems.io,
        InspectionIssue.io,
        InspectionChecklist.io,
      ])
    }

    export const io: t.Type<Create> = t.type(
      {
        type: t.literal('CreateEvent'),
        payload: Payload.io,
      },
      'NotificationCreateEvent',
    )

    export const is = (e: NotificationEvent): e is Create =>
      e.type === 'CreateEvent'
  }

  export interface Update {
    readonly type: 'UpdateEvent'
    readonly payload: Update.Payload
  }

  export namespace Update {
    export type Payload =
      | BaseClassifier
      | UserClassifierRepositoryFolder
      | UserClassifier
      | UserClassifierGroup
      | BimReference
      | UserClassifierRepository
      | BimFile
      | BimModel.IoBuilderResult
      | DynamicBaseGroup
      | Inspection
      | InspectionRepoFolder
      | AssemblyClassifier
      | InspectionIssue
      | InspectionChecklist
      | UserClassifierPriorityOrder.Response

    export namespace Payload {
      export const io = t.union([
        BaseClassifier.io,
        UserClassifierRepositoryFolder.io,
        UserClassifier.io,
        UserClassifierGroup.io,
        BimReference.io,
        UserClassifierRepository.io,
        BimFile.ioBuilder(t.literal(BimFile.ModelRepositoryBimFileType)),
        BimFile.ioBuilder(t.literal(BimFile.BimFileType)),
        BimModel.ioBuilder(BimFile.Status.io),
        BimModel.NotLoaded.io,
        DynamicBaseGroup.io,
        Inspection.io,
        InspectionRepoFolder.io,
        AssemblyClassifier.io,
        InspectionIssue.io,
        InspectionChecklist.io,
        UserClassifierPriorityOrder.Response,
      ])
    }

    export const io: t.Type<Update> = t.type(
      {
        type: t.literal('UpdateEvent'),
        payload: Payload.io,
      },
      'NotificationUpdateEvent',
    )

    export const is = (e: NotificationEvent): e is Update =>
      e.type === 'UpdateEvent'
  }

  export interface Remove {
    readonly type: 'RemoveEvent'
    readonly payload: Remove.Payload
  }

  export namespace Remove {
    export type Payload =
      | UserClassifierRepository
      | UserClassifierRepositoryFolder
      | UserClassifier
      | UserClassifierGroup
      | BimReference
      | Inspection
      | InspectionRepoFolder
      | AssemblyClassifier
      | InspectionIssue
      | InspectionChecklist

    export namespace Payload {
      export const io: t.Type<Payload> = t.union([
        UserClassifierRepository.io,
        UserClassifierRepositoryFolder.io,
        UserClassifier.io,
        UserClassifierGroup.io,
        BimReference.io,
        Inspection.io,
        InspectionRepoFolder.io,
        AssemblyClassifier.io,
        InspectionIssue.io,
        InspectionChecklist.io,
      ])
    }

    export const io: t.Type<Remove> = t.type(
      {
        type: t.literal('RemoveEvent'),
        payload: Payload.io,
      },
      'NotificationRemoveEvent',
    )

    export const is = (e: NotificationEvent): e is Remove =>
      e.type === 'RemoveEvent'
  }

  export interface InclusionStatusChange {
    readonly type: 'InclusionStatusChangeEvent'
    readonly payload: InclusionStatusChange.Payload
  }

  export namespace InclusionStatusChange {
    export interface Payload {
      readonly classifierId: Id
      readonly classifierVersion: Version
    }

    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        classifierId: Id,
        classifierVersion: Version,
      })
    }

    export const io: t.Type<InclusionStatusChange> = t.type(
      {
        type: t.literal('InclusionStatusChangeEvent'),
        payload: Payload.io,
      },
      'InclusionStatusChangeEvent',
    )

    export const is = (e: NotificationEvent): e is InclusionStatusChange =>
      e.type === 'InclusionStatusChangeEvent'
  }

  export interface LockStatusChange {
    readonly type: 'LockStatusEvent'
    readonly payload: LockStatusChange.Payload
  }

  export namespace LockStatusChange {
    export interface Payload {
      id: Id
      isLocked: boolean
      type: string
    }

    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        id: Id,
        isLocked: t.boolean,
        type: t.string,
      })
    }

    export const io: t.Type<LockStatusChange> = t.type(
      {
        type: t.literal('LockStatusEvent'),
        payload: Payload.io,
      },
      'LockStatusEvent',
    )

    export const is = (e: NotificationEvent): e is LockStatusChange =>
      e.type === 'LockStatusEvent'
  }

  export interface AssemblyStatusChange {
    readonly type: 'AssemblyStatusChangeEvent'
    readonly payload: AssemblyStatusChange.Payload
  }

  export namespace AssemblyStatusChange {
    export type Payload = AssemblyClassifier.AssemblyStatusChangeEvent
    export namespace Payload {
      // eslint-disable-next-line prefer-destructuring
      export const io = AssemblyClassifier.AssemblyStatusChangeEvent
    }

    export const io: t.Type<AssemblyStatusChange> = t.type(
      {
        type: t.literal('AssemblyStatusChangeEvent'),
        payload: Payload.io,
      },
      'AssemblyStatusChangeEvent',
    )

    export const is = (e: NotificationEvent): e is AssemblyStatusChange =>
      e.type === 'AssemblyStatusChangeEvent'
  }

  export const io: t.Type<NotificationEvent> = t.union(
    [
      Create.io,
      Update.io,
      Remove.io,
      InclusionStatusChange.io,
      SubscriptionEvent.Unsubscribe.io,
      SubscriptionEvent.Subscribe.io,
      AssemblyStatusChange.io,
      LockStatusChange.io,
    ],
    'NotificationEvent',
  )
}
