import React, { UIEventHandler, useRef } from 'react'

import { Select, Tooltip } from '@gmini/ui-kit'

import { useReadonlyMode } from '@gmini/common'

import * as ismApi from '@gmini/ism-api-sdk'

import { LinkButton, Container } from './IssueSelect.styled'
import { SelectInner, SelectLabel, SelectWrapper } from './CreateIssue.styled'

export type IssueSelectProps = {
  adaptiveMode: boolean
  options: ismApi.GTechIssue.Issue[] | null
  setInputValue: (newValue: string) => void
  label?: string
  onLinkIssue: (value: ismApi.GTechIssue.Issue | null) => void
  onScrollList?: UIEventHandler<HTMLDivElement>
}

export const IssueSelect = ({
  adaptiveMode,
  options,
  setInputValue,
  label,
  onLinkIssue,
  onScrollList,
}: IssueSelectProps) => {
  const [value, setValue] = React.useState<ismApi.GTechIssue.Issue | null>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const { readonlyMode } = useReadonlyMode()

  return (
    <Container ref={containerRef} adaptiveMode={adaptiveMode}>
      <SelectWrapper>
        {label && <SelectLabel>{label}</SelectLabel>}
        <SelectInner>
          <Select
            getOptionLabel={option => (option as ismApi.GTechIssue.Issue).name}
            options={options}
            value={value}
            onChange={(
              newValue:
                | React.FormEvent<HTMLInputElement>
                | null
                | ismApi.GTechIssue.Issue,
            ) => {
              setValue(newValue as ismApi.GTechIssue.Issue)
            }}
            emptyOptionListMessage='Нет доступных совпадений'
            onInputChange={(newValue: string) => setInputValue(newValue)}
            placeholder='Выберите замечание'
            optionListStyles={{
              width: containerRef.current?.getBoundingClientRect().width,
            }}
            onScrollList={onScrollList}
            disabled={readonlyMode.enabled}
            data-test-id='selectNote'
          />
          <Tooltip title={value ? '' : 'Выберите замечание'}>
            <LinkButton
              disabled={!value || readonlyMode.enabled}
              adaptiveMode={adaptiveMode}
              onClick={() => onLinkIssue(value)}
              data-test-id='tieTheNote'
            >
              Привязать
            </LinkButton>
          </Tooltip>
        </SelectInner>
      </SelectWrapper>
    </Container>
  )
}
