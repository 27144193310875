import { ApiEntity, BimElementReferenceItems } from '@gmini/sm-api-sdk'

// eslint-disable-next-line import/no-cycle
import { InspectionChecklist, InspectionIssue } from './InspectionRepo'

// eslint-disable-next-line import/no-cycle
import { Inspection, InspectionRepo, InspectionRepoFolder } from './'

//TODO при разделении репозиториев разделить
type ApiSdk =
  | Inspection
  | InspectionRepo
  | InspectionRepo.Populated
  | InspectionRepoFolder
  | InspectionRepoFolder.Populated
  | ApiEntity
  | BimElementReferenceItems
  | InspectionIssue
  | InspectionChecklist
export const createPredicate = <T extends ApiSdk>(type: T['type']) => (
  value: ApiSdk,
): value is T => value.type === type
