import * as t from 'io-ts'

import { Id, Version } from '@gmini/sm-api-sdk'

export interface InspectionForgeChecklist {
  id: Id
  type: 'FieldInspectionForgeChecklist'
  parentFieldInspectionId: Id
  parentFieldInspectionVersion: Version
  groupId: Id
  containerId: string
  checklistId: string
}

export namespace InspectionForgeChecklist {
  export const io: t.Type<InspectionForgeChecklist> = t.type({
    id: Id,
    type: t.literal('FieldInspectionForgeChecklist'),
    parentFieldInspectionId: Id,
    parentFieldInspectionVersion: Version,
    groupId: Id,
    containerId: t.string,
    checklistId: t.string,
  })
}
