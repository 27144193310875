import * as t from 'io-ts'

import { PaginatedList } from '@gmini/sm-api-sdk'

export interface HistoryChanges<T> extends HistoryChanges.Data<T> {}

export namespace HistoryChanges {
  export const Operations = t.union(
    [t.literal('UPDATED'), t.literal('ADDED'), t.literal('REMOVED')],
    'HistoryOperations',
  )

  export type Operations = t.TypeOf<typeof Operations>

  export type CommonItemFields = {
    operation: Operations
  }

  export const CommonItemFields = t.interface(
    {
      operation: Operations,
    },
    'HistoryCommonItemFields',
  )

  export type Item<Field, Value> = CommonItemFields & {
    field: Field
    oldValue: Value | null | undefined
    newValue: Value | null
  }

  export const Item = <Field extends t.Mixed, Value extends t.Mixed>(
    field: Field,
    value: Value,
  ) =>
    t.intersection(
      [
        CommonItemFields,
        t.interface({
          field,
          newValue: t.union([value, t.null]),
        }),
        t.partial({ oldValue: t.union([value, t.null]) }),
      ],
      'HistoryItem',
    )

  export type Change<T> = {
    version: number
    operation: Operations
    authorId: string
    date: string
    items: T[]
  }

  export const Change = <Item extends t.Mixed>(item: Item) =>
    t.interface(
      {
        version: t.number,
        operation: Operations,
        authorId: t.string,
        date: t.string,
        items: t.array(item),
      },
      'HistoryChange',
    )

  export type Data<T> = PaginatedList<Change<T>>

  export const io = <Item extends t.Mixed>(item: Item) =>
    PaginatedList(Change(item))
}
