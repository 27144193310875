import { useReadonlyMode } from '@gmini/common'
import { useStore, useStoreMap } from 'effector-react'

import { isNotEmpty, useWindowEventListener } from '@gmini/utils'
import { useCallback, useEffect, useMemo, useState } from 'react'

import * as ismApi from '@gmini/ism-api-sdk'

import { CreateIssueIFrame } from '@gmini/ui-common'

import * as api from '../../../../api'

import { inspectionService } from '../../../../services/inspectionService'

import { getIssuesManagerLink } from '../../../../helpers'

import { envLinks } from '../../../../config'

import { DEVELOPMENT_MODE } from '../../../constants'

import { IssueSelect } from './IssueSelect'

import {
  Container,
  CreateButton,
  DescriptionText,
  FooterText,
  IconWrapper,
  Inner,
  Separator,
  WarningOutlined,
} from './CreateIssue.styled'

import issueNotFound from './issueNotFound.svg'

import { issuesList$, ZERO_SEARCH } from './issue-list.store'

export type CreateIssueProps = {
  groupId: number
  widthFormulaResizableCol?: number
  projectUrn: string
}

const limit = 20

export const CreateIssue = ({
  groupId,
  widthFormulaResizableCol,
  projectUrn,
}: CreateIssueProps) => {
  const [creatingIssue, setCreateIssue] = useState(false)
  const [inputValueGTechSelect, setInputValueGTechSelect] = useState('')
  const [offsetList, setOffsetList] = useState(0)

  const { readonlyMode } = useReadonlyMode()

  const adaptiveMode = Number(widthFormulaResizableCol) < 386
  const inspection = useStore(inspectionService.inspection.currentInspection$)!
  const fetchListIssuePending = useStore(
    ismApi.GTechIssue.fetchList.defaultContext.pending$,
  )
  const issuesManagerLink = useMemo(() => getIssuesManagerLink(envLinks), [])

  const { availableGTechIssues, total } = useStoreMap({
    store: issuesList$,
    keys: [inputValueGTechSelect],
    fn: ({ byId$, ids$, totalIssues$ }, [val]) => {
      const search = val || ZERO_SEARCH
      const idsList = ids$[search]
      if (idsList) {
        return {
          availableGTechIssues: idsList.map(id => byId$[id]).filter(isNotEmpty),
          total: totalIssues$,
        }
      }
      return { availableGTechIssues: [], total: null }
    },
  })

  useEffect(() => {
    ismApi.GTechIssue.fetchList.defaultContext.submit({
      limit,
      offset: offsetList,
      filter: inputValueGTechSelect,
      projectUrn,
    })
  }, [inputValueGTechSelect, offsetList, projectUrn])

  useEffect(() => {
    if (offsetList === 0) {
      ismApi.GTechIssue.fetchList.defaultContext.submit({
        limit,
        offset: offsetList,
        filter: inputValueGTechSelect,
        projectUrn,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offsetList])

  const onScrollList = useCallback(
    event => {
      if (fetchListIssuePending) {
        return
      }
      const isScrolledToEnd =
        Number(event.target?.scrollTop) + Number(event.target?.clientHeight) >=
        Number(event.target?.scrollHeight) - 20
      if (isScrolledToEnd && availableGTechIssues?.length < Number(total)) {
        setOffsetList(prevValue => prevValue + limit)
      }
    },
    [availableGTechIssues?.length, total, fetchListIssuePending],
  )

  const onMessage = useCallback(
    event => {
      if (!DEVELOPMENT_MODE && event.origin !== issuesManagerLink) {
        return
      }

      if (event.data && event.data.type === 'IssuePopulated') {
        const issue = event.data as ismApi.GTechIssue

        api.InspectionIssue.createLinkIssue.defaultContext.submit({
          issueId: issue.id,
          groupId,
          inspectionId: inspection.id,
          inspectionVersion: inspection.version,
        })
      }
    },
    [groupId, inspection.id, inspection.version, issuesManagerLink],
  )

  useWindowEventListener('message', onMessage)
  const fieldManagerLink = useMemo(
    () => getIssuesManagerLink(envLinks) || '',
    [],
  )

  if (creatingIssue) {
    return (
      <CreateIssueIFrame
        baseUrl={fieldManagerLink}
        onCreateIssue={issue =>
          api.InspectionIssue.createLinkIssue.defaultContext.submit({
            groupId,
            inspectionId: inspection.id,
            inspectionVersion: inspection.version,
            issueId: issue.id,
          })
        }
        onCloseIFrame={() => setCreateIssue(false)}
        projectUrn={projectUrn}
      />
    )
  }

  return (
    <Container>
      <div style={{ flex: '3' }} />
      <Inner>
        <IconWrapper>
          <img src={issueNotFound} alt='issues' />
        </IconWrapper>
        <DescriptionText>Замечание не прикреплено.</DescriptionText>
        <DescriptionText>Выберите существующее замечание.</DescriptionText>
        <IssueSelect
          options={availableGTechIssues}
          adaptiveMode={adaptiveMode}
          setInputValue={setInputValueGTechSelect}
          onLinkIssue={value => {
            if (value && typeof value.id === 'number') {
              api.InspectionIssue.createLinkIssue.defaultContext.submit({
                groupId,
                inspectionId: inspection.id,
                inspectionVersion: inspection.version,
                issueId: value.id,
              })
            }
          }}
          onScrollList={onScrollList}
        />

        <Separator>или</Separator>

        <CreateButton
          disabled={readonlyMode.enabled}
          adaptiveMode={adaptiveMode}
          onClick={() => setCreateIssue(true)}
          data-test-id='createIssue'
        >
          Создайте новое Замечание
        </CreateButton>
        <FooterText>
          <WarningOutlined />
          Данное замечание будет сохранено в G-mini tec
        </FooterText>
      </Inner>
      <div style={{ flex: '5' }} />
    </Container>
  )
}
