import styled from 'styled-components'
import { LinearProgress as LinearProgressInit } from '@gmini/ui-kit/lib/LinearProgress'
import { Button } from '@gmini/ui-kit'

export const Container = styled.div`
  margin: 12px 0 0 20px;
  position: relative;
  height: 100%;
`

type LinearProgressProps = {
  show?: boolean
}

export const LinearProgress = styled(LinearProgressInit)`
  width: calc(100% + 80px);
  top: -13px;
  margin-left: -20px;
  position: absolute;
  ${(props: LinearProgressProps) => !props.show && 'opacity: 0;'}
`

export const ManagerButton = styled(Button)``

export const Link = styled.a`
  text-decoration: none;
`
