import { FetchService } from '@gmini/utils'

// eslint-disable-next-line import/no-cycle
import * as api from '../InspectionRepo'

export function checklistConnect(f: FetchService) {
  api.Checklist.fetchList.use(({ search, ctrl }) =>
    f
      .get(`/v1/forge-field-management/checklists?filter=${search}`, {
        signal: ctrl.signal,
      })
      .then(api.Checklist.fetchList.toPromise),
  )
}
