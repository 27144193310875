import * as t from 'io-ts'

import { Id, Version } from '@gmini/sm-api-sdk'

export interface InspectionForgeIssue {
  id: Id
  type: 'FieldInspectionForgeIssue'
  parentFieldInspectionId: Id
  parentFieldInspectionVersion: Version
  groupId: Id
  containerId: string
  issueId: string
}

export namespace InspectionForgeIssue {
  export const io: t.Type<InspectionForgeIssue> = t.type({
    id: Id,
    type: t.literal('FieldInspectionForgeIssue'),
    parentFieldInspectionId: Id,
    parentFieldInspectionVersion: Version,
    groupId: Id,
    containerId: t.string,
    issueId: t.string,
  })
}
