import { createEstimationRepoTreeModel } from '@gmini/common/lib/classifier-editor/SelectDependencies/EstimationTree/createTreeModel'
import { createEstimationRepoTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/EstimationTree/EstimationTree'

import { estimationService } from '../../../services/estimationServiceConnect'
import {
  currentUserClassifier$,
  projectUrn$,
} from '../../CurrentUserClassifier'
import { inclusionStore$ } from '../model/inclusionModel'

import { addedDependencyIds$ } from './selectDependencies'

const estimationTree$ = createEstimationRepoTreeModel(
  estimationService,
  projectUrn$,
)

export const { EstimationRepoTree } = createEstimationRepoTree({
  inclusionStatus$: inclusionStore$,
  addedDependencyIds$,
  tree$: estimationTree$,
  currentUserClassifier$,
})
