import * as smApi from '@gmini/sm-api-sdk'
import { createStore } from 'effector'
import { useStore, useStoreMap } from 'effector-react'

export type Role = Omit<smApi.Auth.Role.Item, 'id'> & { id: string }

export const fetchRoleList = smApi.Auth.Role.fetchList.createContext()
export const fetchRoleListPending$ = fetchRoleList.pending$

export const roleList$ = createStore<Role[]>([]).on(
  fetchRoleList.doneData,
  (state, result) => result.map(role => ({ ...role, id: role.id.toString() })),
)

export function useRoleList() {
  return useStore(roleList$)
}

export function useFilteredRoleList(filterTitleValue: string) {
  return useStoreMap({
    store: roleList$,
    keys: [filterTitleValue],
    fn: (roles, [filterTitle]) =>
      filterTitle
        ? roles.filter(role =>
            role.title.toLowerCase().includes(filterTitle.toLowerCase()),
          )
        : roles,
  })
}
