import { createModelsTree } from '@gmini/common/lib/classifier-editor/SelectDependencies/ModelsTree'

import * as smApi from '@gmini/sm-api-sdk'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'
import { inclusionStore$ } from '../model/inclusionModel'

import { notificationService } from '../../../services/notificationService'

import {
  addedDependencyIds$,
  currentModelsByClassifierMap$,
} from './selectDependencies'

const notification = notificationService.message.filter({
  fn: smApi.NotificationEvent.is,
})

export const { ModelsTree, bimFile$ } = createModelsTree({
  addedDependencyIds$,
  inclusionStatus$: inclusionStore$,
  currentModelsByClassifierMap$,
  currentEntity$: currentUserClassifier$,
  notification,
})
