import * as t from 'io-ts'

import { Id, Version } from '@gmini/sm-api-sdk'

export namespace InspectionItem {
  export const Type = t.union([
    t.literal('UserClassifierGroup'),
    t.literal('BimModel'),
    t.literal('BimCategory'),
    t.literal('BimFamily'),
    t.literal('BimStandardSize'),
    t.literal('BimElement'),
    t.literal('UserClassifier'),
  ])

  export type Type = t.TypeOf<typeof Type>

  export interface InspectionRequestItem {
    itemId: Id
    itemType: Type
    groupId: Id
  }

  export type DependencyRequestItem = Omit<InspectionRequestItem, 'groupId'> & {
    modelVersion?: number
  }
  export type FetchDependencyItemsParams = {
    fieldInspectionId: Id
    fieldInspectionVersion: Version
    sourceClassifierId: Id
    sourceClassifierVersion: Version
    items: DependencyRequestItem[]
  }

  export type FetchInspectionItemsParams = {
    fieldInspectionId: Id
    fieldInspectionVersion: Version
    items: InspectionRequestItem[]
  }
}
