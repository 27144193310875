import { map, pipe, prop } from 'ramda'
import { Domain, Event, forward, merge, Store } from 'effector'
import { createGate, Gate } from 'effector-react'

import {
  BadResult,
  IOTSErrorResult,
  isIOTSErrorResult,
  isJsendBadResultError,
  isJsendBadResultFail,
  JsendResultError,
  JsendResultFail,
} from '@gmini/api-call-service'

import { NormalizedTree, Index, Relations } from '@gmini/normalized-tree'
import { createChildDomain } from '@gmini/utils'

import * as ismApi from '@gmini/ism-api-sdk'
import * as chmApi from '@gmini/chm-api-sdk'

import * as api from '../../api'

import * as n from '../Node'

import {
  InspectionStoreService,
  createInspectionStoreService,
} from './InspectionStoreService'

export interface InspectionService {
  readonly Gate: Gate<{
    readonly [key: string]: never
  }>

  readonly nodes$: Store<Index<n.Node>>
  readonly relations$: Store<Relations>

  readonly badResult: Event<BadResult>
  readonly fail: Event<JsendResultFail>
  readonly error: Event<JsendResultError>

  readonly IOTSError: Event<IOTSErrorResult>

  readonly notification: Event<api.NotificationEvent>

  readonly reset: Event<void>

  readonly inspection: InspectionStoreService
}

export function createInspectionService(options: {
  name?: string
  parentDomain?: Domain
}): InspectionService {
  const domain = createChildDomain(options.parentDomain, 'InspectionService')
  const reset = domain.event<void>('reset')

  const Gate = createGate<{ readonly [key: string]: never }>({
    name: 'Mount',
    domain,
  })

  forward({ from: Gate.close, to: reset })

  const notification = domain.event<api.NotificationEvent>('notification')

  const created = notification
    .filter({ fn: api.NotificationEvent.Create.is })
    .map(prop('payload'))

  const updated = notification
    .filter({ fn: api.NotificationEvent.Update.is })
    .map(prop('payload'))

  const removed = notification
    .filter({ fn: api.NotificationEvent.Remove.is })
    .map(prop('payload'))

  const inspection = createInspectionStoreService({ updatedEvent: updated })

  const badResult = merge([
    api.InspectionRepoFolder.create.failData,
    api.InspectionRepoFolder.rename.failData,
    api.InspectionRepoFolder.move.failData,
    api.InspectionRepoFolder.remove.failData,

    api.Inspection.create.failData,
    api.Inspection.copy.failData,
    api.Inspection.rename.failData,
    api.Inspection.move.failData,
    api.Inspection.remove.failData,
    api.Inspection.getMostRecent.failData,
    api.Inspection.renameVersion.failData,
    api.Inspection.removeVersionName.failData,
    api.Inspection.fetchNamedVersions.failData,
    api.Inspection.fetchVersionByDate.failData,
    api.Inspection.fetchVersionDates.failData,

    chmApi.Template.fetchList.failData,
    chmApi.Template.fetchTemplateMostRecent.failData,
    chmApi.Template.fetchTemplate.failData,
    chmApi.Template.createTemplate.failData,
    chmApi.Template.updateTemplate.failData,
    chmApi.Template.deleteTemplate.failData,
    chmApi.Template.createSection.failData,
    chmApi.Template.updateSection.failData,
    chmApi.Template.deleteSection.failData,
    chmApi.Template.createQuestion.failData,
    chmApi.Template.updateQuestion.failData,
    chmApi.Template.deleteQuestion.failData,
    chmApi.Template.fetchTypes.failData,

    chmApi.Instance.closeStatus.failData,
    chmApi.Instance.updateAnswer.failData,

    ismApi.GTechIssue.fetch.failData,
    ismApi.GTechIssue.fetchMostRecent.failData,
    ismApi.GTechIssue.create.failData,
    ismApi.GTechIssue.update.failData,

    api.InspectionChecklist.createLinkChecklist.failData,
    api.InspectionChecklist.removeLinkChecklist.failData,

    api.InspectionIssue.createLinkIssue.failData,
    api.InspectionIssue.removeLinkIssue.failData,
    api.InspectionIssue.createIssue.failData,

    api.InspectionRepo.Populated.fetch.failData,
  ])

  const fail = badResult.filter({ fn: isJsendBadResultFail })
  const error = badResult.filter({ fn: isJsendBadResultError })

  const IOTSError: Event<IOTSErrorResult> = badResult.filter({
    fn: isIOTSErrorResult,
  })

  const getInspectionRepo = api.InspectionRepo.Populated.fetch.done.map(
    ({ params, result }) => ({ ...result, id: params.projectUrn }),
  )

  const getRepoOrFolder = merge([
    getInspectionRepo,
    api.InspectionRepoFolder.Populated.fetch.doneData,
    api.InspectionRepoFolder.Populated.fetchSilent.doneData,
  ])

  const normalizedTree = new NormalizedTree<n.Node>()

  normalizedTree.index$
    .on(
      getInspectionRepo.map(n.InspectionRepoNode.create),
      updateNode(['name', 'total', 'offset']),
    )
    // .on(
    //   updated
    //     .filter({ fn: api.InspectionRepo.is })
    //     .map(n.InspectionRepoNode.create),
    //   updateNode(['name', 'total']),
    // )
    .on(
      getRepoOrFolder.map(
        pipe(
          prop('children'),
          filter(api.Inspection.is),
          map(n.InspectionNode.create),
        ),
      ),
      updateNodes(['parentFolderId', 'parentKey', 'name']),
    )
    .on(
      getRepoOrFolder.map(
        pipe(
          prop('children'),
          filter(api.InspectionRepoFolder.is),
          map(n.InspectionRepoFolderNode.create),
        ),
      ),
      updateNodes(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      api.InspectionRepoFolder.create.doneData.map(
        n.InspectionRepoFolderNode.create,
      ),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      created
        .filter({ fn: api.InspectionRepoFolder.is })
        .map(n.InspectionRepoFolderNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      api.InspectionRepoFolder.Populated.fetch.doneData.map(
        n.InspectionRepoFolderNode.create,
      ),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total', 'offset']),
    )
    .on(
      api.InspectionRepoFolder.rename.doneData.map(
        n.InspectionRepoFolderNode.create,
      ),
      updateNode(['name']),
    )
    .on(
      api.InspectionRepoFolder.move.doneData.map(
        n.InspectionRepoFolderNode.create,
      ),
      updateNode(['parentFolderId', 'parentKey']),
    )
    .on(
      updated
        .filter({ fn: api.InspectionRepoFolder.is })
        .map(n.InspectionRepoFolderNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'total']),
    )
    .on(
      api.InspectionRepoFolder.remove.doneData.map(
        n.InspectionRepoFolderNode.create,
      ),
      removeNode,
    )
    .on(
      removed
        .filter({ fn: api.InspectionRepoFolder.is })
        .map(n.InspectionRepoFolderNode.create),
      removeNode,
    )
    .on(
      api.Inspection.create.doneData.map(n.InspectionNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name']),
    )
    .on(
      api.Inspection.copy.doneData.map(n.InspectionNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name']),
    )
    .on(
      created.filter({ fn: api.Inspection.is }).map(n.InspectionNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name']),
    )
    .on(
      api.Inspection.getMostRecent.doneData.map(n.InspectionNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'version']),
    )
    .on(
      api.Inspection.rename.doneData.map(n.InspectionNode.create),
      updateNode(['name', 'version']),
    )
    .on(
      api.Inspection.move.doneData.map(n.InspectionNode.create),
      updateNode(['parentFolderId', 'parentKey', 'version']),
    )
    .on(
      updated.filter({ fn: api.Inspection.is }).map(n.InspectionNode.create),
      updateNode(['parentFolderId', 'parentKey', 'name', 'version']),
    )
    .on(api.Inspection.remove.doneData.map(n.InspectionNode.create), removeNode)
    .on(
      removed.filter({ fn: api.Inspection.is }).map(n.InspectionNode.create),
      removeNode,
    )
    .reset(reset)

  return {
    Gate,

    nodes$: normalizedTree.index$,
    relations$: normalizedTree.relations$,

    inspection,

    notification,

    badResult,
    fail,
    error,
    IOTSError,

    reset,
  }
}

export type MutableRelations = Record<string, undefined | string[]>

// TODO возможно пригодится
// type Mutable<T extends Readonly<Record<string | number | symbol, unknown>>> = {
//   [K in keyof T]: T[K]
// }

function filter<T, R extends T>(
  predicate: (value: T) => value is R,
): (items: readonly T[]) => readonly R[] {
  return (items: readonly T[]): readonly R[] => items.filter(predicate)
}

function setNode(nodes: Index<n.Node>, newNode: n.Node): Index<n.Node> {
  const key = n.Node.getKey(newNode)
  const oldNode = nodes[key]
  if (newNode === oldNode) {
    return nodes
  }
  return { ...nodes, [key]: newNode }
}

const updateNode = <N extends n.Node>(
  properties: ReadonlyArray<Exclude<keyof N, 'type' | 'id'>> = [],
) => (nodes: Index<n.Node>, newNode: N): Index<n.Node> => {
  const oldNode = n.Node.getByRef(nodes, newNode) as null | N

  if (!oldNode) {
    return setNode(nodes, newNode)
  }

  const changedProperties = properties.filter(
    property => oldNode[property] !== newNode[property],
  )

  if (!changedProperties.length) {
    return nodes
  }

  return setNode(
    nodes,
    changedProperties.reduce<N>(
      (acc, property) => {
        acc[property] = newNode[property]
        return acc
      },
      { ...oldNode } as N,
    ),
  )
}

const updateNodes = <N extends n.Node>(
  properties?: ReadonlyArray<Exclude<keyof N, 'type' | 'id'>>,
) => (nodes: Index<n.Node>, newNodes: readonly N[]): Index<n.Node> =>
  newNodes.reduce(updateNode(properties), nodes)

function removeNode(nodes: Index<n.Node>, node: n.Node): Index<n.Node> {
  const key = n.Node.getKey(node)

  if (!nodes[key]) {
    return nodes
  }

  const newNodes = { ...nodes }
  delete newNodes[key]
  return newNodes
}

export function getChildren(
  nodes: Index<n.Node>,
  relations: Relations,
  ref: n.InspectionRepoNode.Ref | n.InspectionRepoFolderNode.Ref,
): ReadonlyArray<n.InspectionRepoFolderNode | n.InspectionNode> {
  const key = n.Node.getKey(ref)
  const childrenKeys = relations[key]

  if (!childrenKeys) {
    return []
  }

  return childrenKeys.reduce((acc, key) => {
    const node = nodes[key] as
      | undefined
      | n.InspectionRepoFolderNode
      | n.InspectionNode
    if (node) {
      acc.push(node)
    }
    return acc
  }, [] as Array<n.InspectionRepoFolderNode | n.InspectionNode>)
}

export const buildClsGroupKey = ({
  classifierId,
  groupId,
}: {
  classifierId: number
  groupId: number
}) => `${classifierId}:${groupId}`
