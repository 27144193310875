export const getChecklistsManagerLink = (envLinks: string) => {
  try {
    const checklistsManagerLink: string = JSON.parse(envLinks).chm

    if (!checklistsManagerLink) {
      throw new Error(
        'В переменных окружения не найдена ссылка на менеджер чек-листов',
      )
    }
    return `https://${checklistsManagerLink}`
  } catch (error) {
    console.error(error)
  }
}
