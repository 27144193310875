import { createEvent, createStore, Event, Store } from 'effector'

import * as api from '../../api'

export type InspectionStoreService = {
  resetCurrent: Event<void>
  currentInspection$: Store<InspectionEntity | null>
}

export type CurrentEstimation$ = Store<InspectionEntity | null>

export type InspectionEntity = api.Inspection

export function createInspectionStoreService({
  updatedEvent,
}: {
  updatedEvent: Event<api.NotificationEvent.Update.Payload>
}): InspectionStoreService {
  const resetCurrent = createEvent()

  const currentInspection$ = createStore<InspectionEntity | null>(null).reset(
    resetCurrent,
  )

  currentInspection$
    .on(api.Inspection.getMostRecent.doneData, (_, data) => data)
    .on(api.Inspection.fetch.doneData, (_, data) => data)
    .on(updatedEvent.filter({ fn: api.Inspection.is }), (prev, next) => {
      if (prev && prev.id === next.id) {
        return next
      }

      return prev
    })

  return {
    currentInspection$,
    resetCurrent,
  }
}
