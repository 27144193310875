import * as t from 'io-ts'

import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { ApiCallService } from '@gmini/api-call-service'

export namespace Checklist {
  const domain = ApiDomain.domain('Checklist')

  export type TemplateType =
    | 'QUALITY'
    | 'PUNCH_LIST'
    | 'SAFETY'
    | 'COMMISSIONING'

  export const TemplateType = t.union([
    t.literal('QUALITY'),
    t.literal('PUNCH_LIST'),
    t.literal('SAFETY'),
    t.literal('COMMISSIONING'),
  ])

  export type Status =
    | 'NOT_SCHEDULED'
    | 'SCHEDULED'
    | 'IN_PROGRESS'
    | 'COMPLETED'
    | 'SIGNED_OFF'
    | 'PENDING_SIGNATURE'
    | 'NOT_STARTED'

  export const Status = t.union([
    t.literal('NOT_SCHEDULED'),
    t.literal('SCHEDULED'),
    t.literal('IN_PROGRESS'),
    t.literal('COMPLETED'),
    t.literal('SIGNED_OFF'),
    t.literal('PENDING_SIGNATURE'),
    t.literal('NOT_STARTED'),
  ])

  export type SignatureStatus = 'PENDING' | 'REQUIRED' | 'NONE'

  export const SignatureStatus = t.union([
    t.literal('PENDING'),
    t.literal('REQUIRED'),
    t.literal('NONE'),
  ])

  export type Assignee = {
    id: string
    name: string | null
    type: 'USER' | 'COMPANY' | 'ROLE'
  }

  export const Assignee: t.Type<Assignee> = t.type({
    id: t.string,
    name: t.union([t.string, t.null]),
    type: t.union([t.literal('USER'), t.literal('COMPANY'), t.literal('ROLE')]),
  })

  export type ShortInfo = {
    id: string
    containerId: string
    title: string
    instructions: string | null
    createdBy: string | null
    modifiedBy: string | null
    createdAt: string // TODO date
    updatedAt: string | null // TODO date
    deletedAt: string | null // TODO date
    scheduledDate: string | null // TODO date
    templateType: TemplateType
    status: Status
    signatureStatus: SignatureStatus | null
    assignees: Assignee[]
    progress: number
    completedItemsCount: number
    totalItems: number
    totalSections: number
    totalRequiredSignatures: number
    totalSignedSignatures: number
    totalUnsignedSignatures: number
  }

  export const ShortInfo: t.Type<ShortInfo> = t.type({
    id: t.string,
    containerId: t.string,
    title: t.string,
    instructions: t.union([t.string, t.null]),
    createdBy: t.union([t.string, t.null]),
    modifiedBy: t.union([t.string, t.null]),
    createdAt: t.string, // TODO date
    updatedAt: t.union([t.string, t.null]), // TODO date
    deletedAt: t.union([t.string, t.null]), // TODO date
    scheduledDate: t.union([t.string, t.null]), // TODO date
    templateType: TemplateType,
    status: Status,
    signatureStatus: t.union([SignatureStatus, t.null]),
    assignees: t.array(Assignee),
    progress: t.number,
    completedItemsCount: t.number,
    totalItems: t.number,
    totalSections: t.number,
    totalRequiredSignatures: t.number,
    totalSignedSignatures: t.number,
    totalUnsignedSignatures: t.number,
  })

  export type SectionStatus = 'COMPLETED' | 'NOT_COMPLETED'

  export const SectionStatus = t.union([
    t.literal('COMPLETED'),
    t.literal('NOT_COMPLETED'),
  ])

  export type Section = {
    id: string
    title: string
    index: number
    modifiedBy: string | null
    updatedAt: string | null // TODO date
    instructions: string | null
    status: SectionStatus
    assignees: Assignee[]
  }

  export const Section = t.type({
    id: t.string,
    title: t.string,
    index: t.number,
    modifiedBy: t.union([t.string, t.null]),
    updatedAt: t.union([t.string, t.null]),
    instructions: t.union([t.string, t.null]),
    status: SectionStatus,
    assignees: t.array(Assignee),
  })

  export type ResponseType =
    | 'YES_NO'
    | 'PLUS_MINUS'
    | 'TRUE_FALSE'
    | 'PASS_FAIL'
    | 'TEXT'
    | 'LIST_SINGE'
    | 'LIST_MULTIPLE'
    | 'NUMERIC'

  export const ResponseType = t.union([
    t.literal('YES_NO'),
    t.literal('PLUS_MINUS'),
    t.literal('TRUE_FALSE'),
    t.literal('PASS_FAIL'),
    t.literal('TEXT'),
    t.literal('LIST_SINGE'),
    t.literal('LIST_MULTIPLE'),
    t.literal('NUMERIC'),
  ])

  export type PossibleAnswer = {
    id: string
    index: number
    value: string | null
    conformanceType: 'CONFORMING' | 'NON_CONFORMING'
  }

  export const PossibleAnswer = t.type({
    id: t.string,
    index: t.number,
    value: t.union([t.string, t.null]),
    conformanceType: t.union([
      t.literal('CONFORMING'),
      t.literal('NON_CONFORMING'),
    ]),
  })

  export type Instruction = {
    id: number | null
    data: string
  }

  export const Instruction = t.type({
    id: t.union([t.number, t.null]),
    data: t.string,
  })

  export type AttachmentRef = {
    id: string
  }

  export const AttachmentRef = t.type({
    id: t.string,
  })

  export type Urn = {
    type: 'WIP' | 'OSS' | 'CHECKLIST'
    urn: string
  }

  export const Urn = t.type({
    type: t.union([t.literal('WIP'), t.literal('OSS'), t.literal('CHECKLIST')]),
    urn: t.string,
  })

  export type Attachment = {
    id: string
    name: string
    mimeType: string
    uploadStatus: 'COMPLETED' | 'NOT_COMPLETED'
    urns: Urn[]
  }

  export const Attachment = t.type({
    id: t.string,
    name: t.string,
    mimeType: t.string,
    uploadStatus: t.union([t.literal('COMPLETED'), t.literal('NOT_COMPLETED')]),
    urns: t.array(Urn),
  })

  export type DataItem = {
    id: string
    sectionIndex: number
    title: string
    answer: Answer | null
    responseType: {
      id: string | null
      responseType: ResponseType
      possibleAnswers: PossibleAnswer[]
    } | null
    isRequired: boolean
    modifiedBy: string | null
    updatedAt: string | null // TODO date
    index: number
    note: string | null
    instructions: Instruction[]
    attachments: AttachmentRef[]
  }

  export type Answer = string | number | (string | number)[]

  export const Answer = t.union([
    t.string,
    t.number,
    t.null,
    t.array(t.union([t.string, t.number])),
  ])

  export const DataItem: t.Type<DataItem> = t.type({
    id: t.string,
    sectionIndex: t.number,
    title: t.string,
    answer: Answer,
    responseType: t.union([
      t.type({
        id: t.union([t.string, t.null]),
        responseType: ResponseType,
        possibleAnswers: t.array(PossibleAnswer),
      }),
      t.null,
    ]),
    isRequired: t.boolean,
    modifiedBy: t.union([t.string, t.null]),
    updatedAt: t.union([t.string, t.null]), // TODO date
    index: t.number,
    note: t.union([t.string, t.null]),
    instructions: t.array(Instruction),
    attachments: t.array(AttachmentRef),
  })

  export type Data = ShortInfo & {
    sections: Section[]
    items: DataItem[]
    attachments: Attachment[]
    url: string
  }

  export const Data: t.Type<Data> = t.intersection([
    ShortInfo,
    t.type({
      sections: t.array(Section),
      items: t.array(DataItem),
      attachments: t.array(Attachment),
      url: t.string,
    }),
  ])

  export const fetchList = ApiCallService.create<
    { search: string; ctrl: AbortController },
    ShortInfo[] | null
  >({
    name: 'fetchList',
    parentDomain: domain,
    result: t.union([t.null, t.array(ShortInfo)]),
    getKey: ['search'],
  })
}
