import { broadcast } from '@gmini/utils'
import { createEvent, createStore, forward, guard, merge } from 'effector'
import { pick } from 'ramda'

import * as api from '../../../api'
import { inspectionService } from '../../../services/inspectionService'

import { resetChecklistTreeStatuses } from './checklist-status'

export const resetRequestedStatuses = merge([
  resetChecklistTreeStatuses,
  inspectionService.inspection.currentInspection$.updates,
])

export const fetchTreeItemsStatus = createEvent<api.InspectionItem.FetchInspectionItemsParams>()
export const fetchDependencyItemsStatus = createEvent<api.InspectionItem.FetchDependencyItemsParams>()
export const resetIssueFetchStatus = createEvent<void>()

const issueItemsFetchStatus$ = createStore<boolean>(false)
  .on(api.IssueStatus.fetchItemsStatus.defaultContext.doneData, () => true)
  .reset(resetRequestedStatuses)

forward({
  from: resetChecklistTreeStatuses,
  to: resetIssueFetchStatus,
})

guard({
  clock: fetchTreeItemsStatus.filterMap(
    pick(['fieldInspectionId', 'fieldInspectionVersion']),
  ),
  filter: issueItemsFetchStatus$.map(value => !value),
  target: api.IssueStatus.fetchItemsStatus.defaultContext.submit,
})

forward({
  from: fetchTreeItemsStatus,
  to: api.ChecklistStatus.fetchTreeStatuses.defaultContext.submit,
})

broadcast(fetchDependencyItemsStatus, [
  api.ChecklistStatus.fetchDependencyTreeStatuses.defaultContext.submit,
  api.IssueStatus.fetchDependencyItemsStatus.defaultContext.submit,
])
