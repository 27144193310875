import * as t from 'io-ts'

import { Id, Version } from '@gmini/sm-api-sdk'
import { ApiDomain } from '@gmini/sm-api-sdk/lib/domain'

import { ApiCallService } from '@gmini/api-call-service'

export interface InspectionIssue {
  id: Id
  type: 'FieldInspectionIssue'
  parentFieldInspectionId: Id
  parentFieldInspectionVersion: Version
  groupId: Id
  issueId: number
}

export namespace InspectionIssue {
  const domain = ApiDomain.domain('InspectionIssue')

  export const io: t.Type<InspectionIssue> = t.type({
    id: Id,
    type: t.literal('FieldInspectionIssue'),
    parentFieldInspectionId: Id,
    parentFieldInspectionVersion: Version,
    groupId: Id,
    issueId: t.number,
  })

  export const createLinkIssue = ApiCallService.create<
    {
      inspectionId: number
      inspectionVersion: number
      issueId: number
      groupId: number
    },
    InspectionIssue
  >({
    name: 'createLinkIssue',
    parentDomain: domain,
    result: io,
    getKey: ['groupId', 'inspectionId', 'inspectionVersion', 'issueId'],
  })

  export const removeLinkIssue = ApiCallService.create<
    {
      inspectionId: number
      inspectionVersion: number
      inspectionIssueId: number
    },
    unknown
  >({
    name: 'removeLinkIssue',
    parentDomain: domain,
    result: t.unknown,
    getKey: ['inspectionId', 'inspectionIssueId', 'inspectionVersion'],
  })

  export type CreateIssue = {
    inspectionId: number
    inspectionVersion: number
    groupId: number
    title: string
    status: string | null
    issueTypeId: string | null
    issueSubtypeId: string | null
    assignedToId: string | null
    ownerId: string | null
  }

  export const createIssue = ApiCallService.create<CreateIssue, unknown>({
    name: 'createIssue',
    parentDomain: domain,
    result: t.unknown,
    getKey: [
      'assignedToId',
      'groupId',
      'inspectionId',
      'inspectionVersion',
      'issueSubtypeId',
      'issueTypeId',
      'ownerId',
      'status',
      'title',
    ],
  })
}
