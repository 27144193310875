import { equals } from 'ramda'

// eslint-disable-next-line @typescript-eslint/ban-types
export const checkChangesArray = <T extends {}>(
  prevValue: T[],
  newValue: T[],
  getId: (item: T) => string,
) => {
  const compareFunction = (a: T, b: T) => {
    const idA = getId(a).toUpperCase()
    const idB = getId(b).toUpperCase()
    if (idA < idB) {
      return -1
    }
    if (idA > idB) {
      return 1
    }

    return 0
  }

  const sortedPrevValue = [...prevValue].sort(compareFunction)
  const sortedNewValue = [...newValue].sort(compareFunction)

  return !equals(sortedPrevValue, sortedNewValue)
}
