import { createStore, sample, combine, merge, createEvent } from 'effector'

import { isNotEmpty } from '@gmini/utils'

import { selectedModels } from '@gmini/common/lib/classifier-editor'

import { createViewerColoredModel } from '@gmini/common/lib/forge-viewer/model/themeModel'

import * as api from '../../../../api'

import { inspectionService } from '../../../../services/inspectionService'

import { currentInclusionMap$ } from '../../model/inclusionModel'

import { resetChecklistTreeStatuses } from '../../model/checklist-status'

import { fetchBulk } from './requests'

export const {
  modelLoaded,
  modelRemoved,
  colorViewerElements,
} = createViewerColoredModel()

export const reFetchColorModels = createEvent<void>()

resetChecklistTreeStatuses.watch(() => {
  colorViewerElements({})
  reFetchColorModels()
})

/**
 * Загруженные модели во вьювере
 */
export const currentLoadedModels$ = combine(
  {
    currentInspection: inspectionService.inspection.currentInspection$,
    //TODO выпилить оставшийся selectedModels, продумать поиск модели и её рефа по urn
    selectedModels: selectedModels.models$,
  },
  ({ currentInspection, selectedModels }) =>
    selectedModels && currentInspection
      ? selectedModels
          .map(model => ({
            modelId: model.modelId,
            modelVersion: model.modelVersion,
            urn: model.viewerId,
            baseClassifierId: model.baseClassifierId,
            inspectionId: currentInspection.id,
            inspectionVersion: currentInspection.version,
          }))
          .filter(isNotEmpty)
      : null,
)

export const includedAndLoadedModels$ = combine(
  {
    currentLoadedModels: currentLoadedModels$,
    currentInclusionMap: currentInclusionMap$,
  },
  ({ currentInclusionMap, currentLoadedModels }) =>
    currentLoadedModels?.filter(model =>
      currentInclusionMap
        ? Object.keys(currentInclusionMap[model.baseClassifierId] || []).length
        : null,
    ) || null,
)

const coloredModelsStore$ = createStore<Record<
  string,
  api.CalculateModelElements.ResponseItem[]
> | null>(null)
  .on(
    api.CalculateModelElements.fetchModelElements.defaultContext.doneData,
    (state, { urn, items }) => ({
      ...state,
      [urn]: items,
    }),
  )
  .reset(reFetchColorModels || modelRemoved)

/**
 * ExternalIdMap модели загрузилась во вьювере или модели загружены и был bulk запрос
 */
const modelsLoadedEvent = merge([
  modelLoaded.map(({ urn }) => urn),
  fetchBulk.doneData,
])

export const themeModelsLoaded = sample({
  source: coloredModelsStore$,
  clock: modelsLoadedEvent,
  fn: (coloredModels, urn) => ({
    coloredModels,
    urns: Array.isArray(urn) ? urn : [urn],
  }),
})
