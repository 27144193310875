import * as api from '../../../api'

export const getChecklistStatusColor = (
  status?: api.ChecklistStatus.Status,
) => {
  let color: string | undefined
  switch (status || 'NOT_STARTED') {
    case 'COMPLETED':
      color = '#0D9966'
      break
    case 'IN_PROGRESS':
      color = '#DAC374'
      break
    case 'NOT_STARTED':
      color = '#A2A3B7'
  }

  return color
}
