import { combine, createEvent, createStore } from 'effector'

import * as ismApi from '@gmini/ism-api-sdk'

export const resetIssueList = createEvent()

type ById = {
  [id: string]: ismApi.GTechIssue.Issue
}

const byId$ = createStore<ById>({}).on(
  ismApi.GTechIssue.fetchList.defaultContext.doneData,
  (state, result) => {
    const next = { ...state }
    result.list.forEach(ch => (next[ch.id] = ch))
    return next
  },
)

type IdsBySearchValue = Record<string | symbol, number[] | undefined>

export const ZERO_SEARCH = 'áÝé¾±µ'

const ids$ = createStore<IdsBySearchValue>({})
  .on(
    ismApi.GTechIssue.fetchList.defaultContext.done,
    (state, { params, result }) => {
      const next = { ...state }
      const search = params.filter || ZERO_SEARCH
      next[search] = [
        ...new Set([
          ...(state[search] || []),
          ...result.list.map(({ id }) => id),
        ]),
      ]
      return next
    },
  )
  .on(resetIssueList, state => ({}))

export const totalIssues$ = createStore<number | null>(null).on(
  ismApi.GTechIssue.fetchList.defaultContext.doneData,
  (state, result) => result.total,
)

export const issuesList$ = combine({ byId$, ids$, totalIssues$ })
