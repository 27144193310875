import { useStore, useStoreMap } from 'effector-react'
import React, { MouseEvent } from 'react'

import { Button, Tooltip } from '@gmini/ui-kit'

import { useReadonlyMode } from '@gmini/common'

import { resetChecklistTreeStatuses } from '../../model/checklist-status'

import { InspectionChecklist } from '../../../../api/InspectionRepo/InspectionChecklist'

import { RenderChecklist } from './RenderChecklist'
import { checklistById$, fetchChecklistItem } from './renderChecklist.store'
import {
  Footer,
  CrossedChain,
  DeletedTitle,
  EmptyTitle,
} from './RenderChecklist.styled'

export type RenderChecklistContainerProps = {
  checklistId: number
  widthFormulaResizableCol?: number
  setAnchorElPopover?: (
    currentTarget: MouseEvent<HTMLButtonElement>['currentTarget'],
  ) => void
  type: InspectionChecklist['type']
  projectUrn: string
}

export const RenderChecklistContainer = ({
  checklistId,
  widthFormulaResizableCol,
  setAnchorElPopover,
  type,
  projectUrn,
}: RenderChecklistContainerProps) => {
  const fetchChecklistItemPending = useStore(fetchChecklistItem.pending$)

  const checklistData = useStoreMap({
    store: checklistById$,
    keys: [checklistId],
    fn: (checklistsById, [id]) => checklistsById?.[id] || null,
  })

  const { readonlyMode } = useReadonlyMode()

  React.useEffect(() => {
    if (checklistId) {
      fetchChecklistItem({
        id: checklistId,
      })
    }
  }, [checklistId, type])

  React.useEffect(() => {
    const refreshSubscription = resetChecklistTreeStatuses.watch(() => {
      if (checklistId) {
        fetchChecklistItem({
          id: checklistId,
        })
      }
    })

    return () => {
      refreshSubscription.unsubscribe()
    }
  }, [checklistId, type])

  const adaptiveMode = Number(widthFormulaResizableCol) > 385

  const removeButtonJsx = (
    <Tooltip title={adaptiveMode ? '' : 'Отвязать чек-лист'}>
      <Button
        onClick={(event: MouseEvent<HTMLButtonElement>) =>
          setAnchorElPopover?.(event.currentTarget)
        }
        color='secondary'
        disabled={readonlyMode.enabled || fetchChecklistItemPending}
        leftIcon={<CrossedChain />}
        size='regular'
        data-test-id='untieTheChecklist'
      >
        {adaptiveMode && 'Отвязать'}
      </Button>
    </Tooltip>
  )

  if (fetchChecklistItemPending && !checklistData) {
    return <EmptyTitle>Загрузка...</EmptyTitle>
  }

  if (!checklistData) {
    return (
      <>
        <EmptyTitle>Отсутствуют данные о чек-листе</EmptyTitle>
        <Footer>{removeButtonJsx}</Footer>
      </>
    )
  }

  if (checklistData?.deleted) {
    return (
      <>
        <DeletedTitle>Чек-лист удален</DeletedTitle>
        <Footer>{removeButtonJsx}</Footer>
      </>
    )
  }

  return (
    <RenderChecklist
      type={type}
      checklistId={checklistId}
      checklistData={checklistData}
      button={removeButtonJsx}
      projectUrn={projectUrn}
    />
  )
}
