export const fcWidget = (function () {
  let isInitialized: boolean = false

  function initialize(userFirstName: string, userEmail?: string | null): void {
    if (!isInitialized) {
      const head = document.querySelector('head')

      ;(function () {
        const scriptOnInit = document.createElement('script')
        const script = document.createElement('script')

        scriptOnInit.setAttribute('data-fc', 'true')
        script.setAttribute('data-fc', 'true')

        scriptOnInit.innerHTML = `
                window.fcSettings = {
                    onInit() {
                        if ('${userFirstName}') {
                            window.fcWidget.user.setFirstName('${userFirstName}')
                        }
                        if ('${userEmail}') {
                            window.fcWidget.user.setEmail('${userEmail}')
                        }
                    },
                }
            `

        script.setAttribute('src', '//fw-cdn.com/11030420/3776292.js')
        script.setAttribute('chat', 'true')

        head?.prepend(scriptOnInit)
        head?.prepend(script)
      })()

      isInitialized = true
    }
  }

  function deinitialize(): void {
    if (isInitialized) {
      document.querySelectorAll('[data-fc]').forEach(element => {
        element.remove()
      })
    }
  }

  return {
    initialize,
    deinitialize,
  }
})()
