import { Version } from '@gmini/common/lib/classifier-service/Node'

import * as smApi from '@gmini/sm-api-sdk'

import { Id } from './Id'
// eslint-disable-next-line import/no-cycle
import { Node } from './Node'
// eslint-disable-next-line import/no-cycle
import { InspectionRepoNode } from './InspectionRepoNode'
// eslint-disable-next-line import/no-cycle
import { InspectionRepoFolderNode } from './InspectionRepoFolderNode'

export interface InspectionNode {
  readonly type: 'InspectionNode'
  readonly id: Id
  readonly version: Version
  readonly parentFolderId: null | Id
  readonly key: string
  readonly parentKey: null | string
  readonly name: string
  readonly classifierId: number
  readonly readOnly: boolean
  readonly owner: string | null
  readonly projectUrn: smApi.Urn
}

export namespace InspectionNode {
  export type Type = InspectionNode['type']
  export const TYPE: Type = 'InspectionNode'

  export type Data = Omit<
    InspectionNode,
    'type' | 'calculations' | 'key' | 'parentKey'
  >

  export const create = (source: Data): InspectionNode => ({
    ...source,
    type: TYPE,
    key: getKey(source),
    parentKey: getParentKey(source),
  })

  export type Ref = Pick<InspectionNode, 'type' | 'id'>

  export const is = (node: Node): node is InspectionNode =>
    node.type === 'InspectionNode'

  export const getKey = ({ id }: Pick<InspectionNode, 'id'>): string =>
    `${TYPE}:${id}`

  export const getParentKey = ({
    parentFolderId,
    projectUrn,
  }: Pick<InspectionNode, 'parentFolderId' | 'projectUrn'>): string =>
    parentFolderId == null
      ? InspectionRepoNode.getKey({ id: projectUrn })
      : InspectionRepoFolderNode.getKey({ id: parentFolderId })

  export const getByKey = (
    nodes: Node.Map,
    key: string,
  ): null | InspectionNode => {
    const node = nodes[key]
    return node && is(node) ? node : null
  }
}
