//TODO заменить эту функцию на useSearchParams из react-router v6 или сделать собственную реализацию этого хука
export const buildUrlWithParams = (
  path: string,
  params: { [x: string]: string | number | null | undefined },
) =>
  `${path}${
    params
      ? `?${Object.keys(params)
          .filter(key => params[key])
          .map(key => `${key}=${params[key]}`)
          .join('&')}`
      : ''
  }`
