import React from 'react'
import { createGate } from 'effector-react'
import { Redirect, useParams } from 'react-router-dom'
export interface CurrentEstimationProps {
  id: number
}

const CurrentInspectionGate = createGate<CurrentEstimationProps | null>()

export const currentEstimationId$ = CurrentInspectionGate.state.map(
  props => props?.id || null,
)

export function useCurrentInspectionParams() {
  const { id } = useParams<{
    readonly id?: string
  }>()

  return React.useMemo(() => (id ? ({ id: +id } as const) : null), [id])
}

export const CurrentInspection = React.memo(() => {
  const params = useCurrentInspectionParams()

  if (!params) {
    return <Redirect to='/' />
  }
  return <CurrentInspectionGate id={params.id} />
})

CurrentInspection.displayName = 'CurrentInspection'
