/* eslint-disable react/display-name */
import { useStore } from 'effector-react'

import React, { useEffect, useMemo, useState } from 'react'

import { combine, createStore, sample } from 'effector'

import { useSnackbar } from 'notistack'

import { equals } from 'ramda'

import { Dot, FolderChecklist, WarningFilled } from '@gmini/ui-kit'

import { isNotEmpty } from '@gmini/utils'

import {
  BimRef,
  isReferenceNode,
  isUserClassifierGroupNode,
  NodeRef,
  ReferenceNode,
  UserClassifierGroupNodeRef,
} from '@gmini/common/lib/classifier-service/Node'

import { getNode, Nodes } from '@gmini/common/lib/classifier-service'

import {
  isGroupType,
  nodeToApiTypeMap,
  apiToNodeTypeMap,
} from '@gmini/common/lib/classifier-service/adapters'

import { EditorTree } from '@gmini/common/lib/classifier-editor/ClassifierTree/EditorTree'

import { createExpandModel } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/expandModel'

import { moveMultiplyFromOwn } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/moveMultiplyFromOwn'
import { insertMultiplyFromDeps } from '@gmini/common/lib/classifier-editor/ClassifierTree/multiply/insertMultiplyFromDeps'

import {
  ApiFlatNode,
  FlatNode,
  isApiFlatNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/createTree'

import { NodeLayout } from '@gmini/common/lib/classifier-editor/ClassifierTree/NodeLayout'

import { dynamicGroupMode$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/dynamicGroupMode'

import {
  currentGroup$,
  subscribeSelectNewGroup,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/groupModel'

import { pendingMapClassifier$ } from '@gmini/common/lib/classifier-editor/ClassifierTree/model/pendingModel'

import {
  removeAllNodes,
  removeNode,
} from '@gmini/common/lib/classifier-editor/ClassifierTree/model/removeModel'

import { getParentsByPath } from '@gmini/common/lib/classifier-editor/ClassifierTree/utils'

import { buildTreeItemKey } from '@gmini/common/lib/classifier-editor/ClassifierTree/buildTreeItemKey'

import { validateMoveFromDeps } from '@gmini/common/lib/classifier-editor/validate/validate-move'

import { resetShowMode } from '@gmini/common/lib/forge-viewer/model/selectModel'

import {
  TreeLoader,
  operationsPending$,
} from '@gmini/common/lib/classifier-editor/TreeLoader'
import {
  ModelStoreService,
  isGeneralGroupNode,
  resetChecked,
  SearchModel,
  updateChecked,
} from '@gmini/common/lib/classifier-editor'

import { getViewerRefs } from '@gmini/common/lib/classifier-editor/Common'

import * as smApi from '@gmini/sm-api-sdk'

import { useReadonlyMode } from '@gmini/common'

import { ViewerCheckedMap } from '@gmini/common/lib/classifier-editor/FromTreeToViewer/types'

import * as api from '../../../api'

import { classifierService } from '../../../services/classifierService'
import {
  dependencyCheckedItems$,
  editorCheckedModel,
} from '../model/checkedModel'
import { inspectionService } from '../../../services/inspectionService'
import {
  buildChecklistStatusKey,
  createCheckListStatusModel,
} from '../model/checklist-status'

import { currentUserClassifier$ } from '../../CurrentUserClassifier'

import { selectedForgeElements$ } from '../core/viewerSelection/viewerSelection'

import {
  fetchTreeItemsStatus,
  resetRequestedStatuses,
} from '../model/fetchItemsStatus'

import { StatusIconWrap } from '../common/statusIconWrap.styled'

import { searchModel } from '../model/searchModel'

import { searchSourceModel } from '../searchSourceModel'

import { dynamicGroupConditions$ } from '../model/dynamic-conditions.store'

import { getChecklistStatusColor } from './getChecklistStatusColor'

import { treeModel } from './treeModel'

import { setCurrentGroup } from './setCurrentGroup'

const filteredFlatTree$ = searchModel.filterTree(treeModel.flatTree$)

subscribeSelectNewGroup({
  flatTree$: filteredFlatTree$,
  onCreatedNode: smApi.UserClassifierGroup.create.doneData,
})

export const expandModel = createExpandModel()

const FILTER_LIMIT = 5000

export const checklistStatus = createCheckListStatusModel({
  apiCall: api.ChecklistStatus.fetchTreeStatuses,
  onBuildKey: ({ item }) =>
    buildChecklistStatusKey({
      id: item.itemId,
      type: apiToNodeTypeMap[item.itemType],
      parentId: item.groupId,
    }),
  onReset: resetRequestedStatuses,
})

export const issueStatus = createCheckListStatusModel({
  apiCall: api.IssueStatus.fetchItemsStatus,
  onBuildKey: ({ item }) =>
    buildChecklistStatusKey({
      id: item.itemId,
      type: apiToNodeTypeMap[item.itemType],
      parentId: item.groupId,
    }),
  onReset: resetRequestedStatuses,
})

const expandedTreeItems$ = combine(
  { tree: filteredFlatTree$, expanded: expandModel.expanded$ },
  ({ expanded, tree }) =>
    tree.filter(isApiFlatNode).filter(item => expanded[item.path.join(':')]),
)

const treeRootItems$ = filteredFlatTree$.map(tree =>
  tree.filter(({ path }) => path.length === 1).filter(isApiFlatNode),
)

function getRequestStatusItems({
  flatTree,
  nodes,
  requested,
}: {
  flatTree: ApiFlatNode[]
  nodes: Nodes
  requested: { [key: string]: true | undefined }
}): api.InspectionItem.InspectionRequestItem[] {
  return flatTree
    .map(item => {
      const node = getNode(nodes, item.ref)
      if (!node) {
        return null
      }
      const trueNode = isReferenceNode(node)
        ? getNode(nodes, node.element)
        : node
      if (!trueNode) {
        return null
      }

      const itemType = isGroupType(trueNode.type)
        ? 'UserClassifierGroup'
        : // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (nodeToApiTypeMap[trueNode.type] as any)

      const parents = getParentsByPath(nodes, item.path).reverse()
      const parentGroup = parents.find(isUserClassifierGroupNode)
      const groupId = isUserClassifierGroupNode(trueNode)
        ? trueNode.id
        : parentGroup?.id
      if (!groupId) {
        throw new Error(`${item.path.join(':')} should have parent group`)
      }

      return {
        itemType,
        itemId: trueNode.id,
        groupId,
      }
    })
    .filter(isNotEmpty)
    .filter(
      item =>
        !requested[
          buildChecklistStatusKey({
            id: item.itemId,
            type: item.itemType,
            parentId: item.groupId,
          })
        ],
    )
}

const checkedWithTree$ = combine({
  tree: filteredFlatTree$,
  checked: editorCheckedModel.checked$,
})

// Для работы с чекнутыми элементами во вьювере
// TODO После рефакторинга логики fromEditorToViewer выпилить
sample({
  clock: editorCheckedModel.checked$.updates,
  source: checkedWithTree$,
  fn: ({ checked, tree }, currentCheckedMap) => ({
    checked,
    tree,
    currentCheckedMap,
  }),
}).watch(({ checked, currentCheckedMap, tree }) => {
  if (Object.keys(currentCheckedMap).length > 0) {
    const keys = Object.keys(checked)
    const flatNodes = tree.reduce((acc, node) => {
      if (
        isApiFlatNode(node) &&
        keys.some(k => node.path.join(':') === k && checked[k])
      ) {
        acc[node.ref.type + node.ref.id] = node.ref
      }

      return acc
    }, {} as ViewerCheckedMap)
    updateChecked({
      path: 'Own',
      checkedMap: flatNodes,
    })
  } else {
    resetChecked('Own')
    resetShowMode()
  }
})

const requestedStatusItems$ = createStore<{
  [key: string]: true | undefined
}>({})
  .on(fetchTreeItemsStatus, (state, { items }) => {
    const next = { ...state }
    items.forEach(item => {
      next[
        buildChecklistStatusKey({
          id: item.itemId,
          type: item.itemType,
          parentId: item.groupId,
        })
      ] = true
    })
    return next
  })
  .reset(resetRequestedStatuses)

export const EditorTreeWrap = ({
  selectViewerRefs,
  searchModel,
  dependenciesWithModels$,
}: {
  selectViewerRefs: (value: Record<string, string[]>) => void
  searchModel: SearchModel
  dependenciesWithModels$: ModelStoreService['dependenciesWithModels$']
}) => {
  const { searchMatched$, searchNodes$ } = searchModel
  const currentUserClassifier = useStore(currentUserClassifier$)
  const selectedPath = useStore(currentGroup$)
  const depsCheckedList = useStore(dependencyCheckedItems$)
  const nodes = useStore(classifierService.nodes$)
  const flatTree = useStore(filteredFlatTree$)
  const searchNodes = useStore(searchNodes$)
  const pendingMap = useStore(pendingMapClassifier$)
  const currentInspection = useStore(
    inspectionService.inspection.currentInspection$,
  )!
  const checklistStatusMap = useStore(checklistStatus.statusMap$)
  const issueStatusMap = useStore(issueStatus.statusMap$)
  const expandedTreeItems = useStore(expandedTreeItems$)
  const treeRootItems = useStore(treeRootItems$)
  const operationsPending = useStore(operationsPending$)
  const requestedStatusItems = useStore(requestedStatusItems$)
  const [selectedCtxMenuGroup, setSelectedCtxMenuGroup] = useState<
    number | null
  >(null)
  const { readonlyMode } = useReadonlyMode()
  const selectedForgeElements = useStore(selectedForgeElements$)

  const { enqueueSnackbar } = useSnackbar()

  const disabledInsertFilteredElements = (node: FlatNode) => {
    const overLimit =
      (selectedForgeElements?.reduce(
        (acc, next) => acc + next.elementForgeExternalIds.length,
        0,
      ) || 0) >= FILTER_LIMIT

    if (node.ref.type === 'UserClassifierGroupWithGroupsNode') {
      return 'Нельзя вставить элементы в папку с папками'
    } else if (overLimit) {
      return `Невозможно добавить более ${FILTER_LIMIT} элементов`
    }

    return ''
  }

  const existIssue = useMemo(
    () =>
      Boolean(
        currentInspection?.issues?.some(
          children => children.groupId === selectedCtxMenuGroup,
        ),
      ),
    [currentInspection?.issues, selectedCtxMenuGroup],
  )

  // //TODO выпилить когда будет bulk запрос
  // const { uncheckItem } = useUnCheckItem(dependenciesCheckedModel)

  const findAnywhere = React.useCallback(
    async (
      ref: BimRef | Pick<ReferenceNode, 'id' | 'type'>,
      path: string[],
    ) => {
      let node = getNode(nodes, ref)
      if (node && isReferenceNode(node)) {
        node = getNode(nodes, node.element)
      }

      if (!node) {
        return
      }

      searchModel.setSearchNodes({ type: 'search', nodes: [{ node, path }] })

      const viewerRefs = await getViewerRefs(node, nodes)

      if (viewerRefs) {
        selectViewerRefs(viewerRefs)
      }
    },
    [nodes, searchModel, selectViewerRefs],
  )

  const notify = React.useCallback(
    (reason: string) => {
      enqueueSnackbar(reason, {
        variant: 'error',
      })
    },
    [enqueueSnackbar],
  )

  const checkExistCompletedChecklist = React.useCallback(
    (ref: UserClassifierGroupNodeRef | Pick<ReferenceNode, 'type' | 'id'>) => {
      const flatNode = flatTree.find(
        flatNode => (flatNode.ref as { id: number }).id === ref.id,
      )

      if (!flatNode) {
        return
      }

      const parents = getParentsByPath(nodes, flatNode.path)

      return ([...parents, flatNode.ref] as { id: number }[]).some(
        ({ id }) =>
          Object.values(
            checklistStatusMap as Record<
              string,
              { itemId: number; status: 'COMPLETED' }
            >,
          ).find(({ itemId }) => itemId === id)?.status === 'COMPLETED',
      )
    },
    [flatTree, nodes, checklistStatusMap],
  )

  const removeRef = React.useCallback(
    (ref: UserClassifierGroupNodeRef | Pick<ReferenceNode, 'type' | 'id'>) => {
      if (checkExistCompletedChecklist(ref)) {
        notify('Удаление элементов невозможно, чек-лист “Завершен”')
        return
      }

      removeNode(ref, currentUserClassifier!)
    },
    [checkExistCompletedChecklist, currentUserClassifier, notify],
  )

  const deleteSelected = React.useCallback(
    (items: NodeRef[]) => {
      const { id, version } = currentUserClassifier!
      if (
        items.some(ref =>
          checkExistCompletedChecklist(
            ref as Pick<ReferenceNode, 'type' | 'id'>,
          ),
        )
      ) {
        notify('Удаление элементов невозможно, чек-лист “Завершен”')
        return
      }
      removeAllNodes({
        id,
        version,
        items: items.map(item => ({
          ...item,
          type: nodeToApiTypeMap[item.type] as
            | smApi.BimReference['type']
            | smApi.UserClassifierGroup['type'],
        })),
      })
    },
    [checkExistCompletedChecklist, currentUserClassifier, notify],
  )

  const onPending = React.useCallback((key: string) => !!pendingMap[key], [
    pendingMap,
  ])

  const allowToCreate = React.useCallback(
    (node: FlatNode) => node.ref.type !== 'UserClassifierGroupWithElementsNode',
    [],
  )

  useEffect(() => {
    const rootItems = getRequestStatusItems({
      flatTree: treeRootItems,
      nodes,
      requested: requestedStatusItems,
    })

    const expandedItems = getRequestStatusItems({
      flatTree: expandedTreeItems,
      nodes,
      requested: requestedStatusItems,
    }).filter(item => rootItems.every(it => !equals(item, it)))

    const items = [...rootItems, ...expandedItems]

    if (items.length) {
      fetchTreeItemsStatus({
        fieldInspectionId: currentInspection.id,
        fieldInspectionVersion: currentInspection.version,
        items,
      })
    }
  }, [
    currentInspection.id,
    currentInspection.version,
    expandedTreeItems,
    nodes,
    requestedStatusItems,
    treeRootItems,
  ])

  const onInsertFilteredElements = React.useCallback(
    (ref: UserClassifierGroupNodeRef) => {
      if (
        checkExistCompletedChecklist({
          id: ref.id,
          type: ref.type as 'UserClassifierGroupWithGroupsNode',
        })
      ) {
        notify('Добавление элементов невозможно, чек-лист “Завершен”')
        return
      }
      if (
        selectedForgeElements?.length &&
        (ref.type === 'UserClassifierGroupWithElementsNode' ||
          ref.type === 'UserClassifierEmptyGroupNode')
      ) {
        smApi.UserClassifier.createRefsFromExternalIds.defaultContext({
          id: currentUserClassifier!.id,
          version: currentUserClassifier!.version,
          parentGroupId: ref.id,
          items: selectedForgeElements,
        })
      }
    },
    [
      checkExistCompletedChecklist,
      currentUserClassifier,
      notify,
      selectedForgeElements,
    ],
  )

  const dynamicGroupsConditions = useStore(dynamicGroupConditions$)

  if (!currentUserClassifier) {
    return null
  }

  return (
    <>
      <TreeLoader />
      <EditorTree
        dynamicGroupsConditions={dynamicGroupsConditions}
        isSpecialNode={() => false}
        notify={notify}
        nodes$={classifierService.nodes$}
        dynamicMode$={dynamicGroupMode$}
        currentUserClassifier={currentUserClassifier}
        treeModel={{ ...treeModel, flatTree$: filteredFlatTree$ }}
        checkedModel={editorCheckedModel}
        expandModel={expandModel}
        selectedFromOtherTreeCount={depsCheckedList.length}
        onSubmitCreating={(name, { parentNodeRef }) => {
          const parentGroupId = parentNodeRef ? parentNodeRef.id : undefined

          smApi.UserClassifierGroup.create.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId,
            name,
          })
        }}
        onCancelCreating={() => {
          treeModel.setInCreateNode(null)
        }}
        setInCreateNode={n => treeModel.setInCreateNode(n)}
        onSubmitEditing={(newName, node) => {
          smApi.UserClassifierGroup.rename.defaultContext.submit({
            classifierId: currentUserClassifier.id,
            parentClassifierVersion: currentUserClassifier.version,
            parentGroupId: node.parentGroupId,
            id: node.id,
            name: newName,
          })
        }}
        onMoveItems={({ target, items }) => {
          if (
            checkExistCompletedChecklist({
              id: target.id,
              type: target.type as 'UserClassifierGroupWithGroupsNode',
            }) ||
            items.some(
              ref =>
                !(
                  ref.type === 'UserClassifierGroupWithElementsNode' &&
                  Object.values(
                    checklistStatusMap as Record<
                      string,
                      { itemId: number; status: 'COMPLETED' }
                    >,
                  ).find(({ itemId }) => itemId === ref.id)?.status ===
                    'COMPLETED'
                ) &&
                checkExistCompletedChecklist(
                  ref as Pick<ReferenceNode, 'type' | 'id'>,
                ),
            )
          ) {
            notify('Добавление элементов невозможно, чек-лист “Завершен”')
            return
          }

          moveMultiplyFromOwn({
            currentClassifier: currentUserClassifier,
            items,
            nodes$: classifierService.nodes$,
            target,
          })
        }}
        onInsertFromOtherTree={({ target }) => {
          insertMultiplyFromDeps({
            currentClassifier: currentUserClassifier,
            dependenciesCheckedItems: depsCheckedList,
            nextParentNode: target,
            nodes$: classifierService.nodes$,
            // onSuccessItem: uncheckItem,
          })
        }}
        validateInsertFromOtherTree={({ target, nestingLevel }) => {
          if (
            checkExistCompletedChecklist({
              id: target.id,
              type: target.type as 'UserClassifierGroupWithGroupsNode',
            })
          ) {
            return 'Добавление элементов невозможно, чек-лист “Завершен”'
          }

          return validateMoveFromDeps({
            items: depsCheckedList,
            nodes,
            targetNode: target,
            nestingLevel,
            dynamicMode: false,
          })
        }}
        isEditingAllowed={flatNode => {
          const existCompletedChecklist = checkExistCompletedChecklist(
            flatNode?.ref as Pick<ReferenceNode, 'type' | 'id'>,
          )

          if (existCompletedChecklist) {
            notify('Редактирование элементов невозможно, чек-лист “Завершен”')
          }

          return !existCompletedChecklist
        }}
        onFindAnywhere={findAnywhere}
        onDelete={removeRef}
        onDeleteSelected={deleteSelected}
        disabledIssue={existIssue}
        searchNodes={searchNodes}
        searchMatched$={searchMatched$}
        onPending={onPending}
        allowCreate={allowToCreate}
        selectedPath={selectedPath}
        dependenciesWithModels$={dependenciesWithModels$}
        onSearchSource={searchSourceModel.setSearchSourceData}
        hideCtxMenu={operationsPending}
        allowInsertFilteredElements={() => !!selectedForgeElements?.length}
        disabledInsertFilteredElements={disabledInsertFilteredElements}
        onInsertFilteredElements={onInsertFilteredElements}
        renderNodeLayout={({ node, nodeLayoutProps, path }) => {
          const nextProps = {
            ...nodeLayoutProps,
          }

          const selfKey = path[path.length - 1]
          const parents = getParentsByPath(nodes, path).slice().reverse()
          const parentGroup = parents.find(isUserClassifierGroupNode)

          // Выбираем родительскую группу
          nextProps.onClick = () => {
            nodeLayoutProps.onClick?.()
            if (isGeneralGroupNode(node)) {
              return
            }
            const parentGroupPath = parentGroup
              ? path.slice(0, path.indexOf(buildTreeItemKey(parentGroup)) + 1)
              : null

            if (
              parentGroupPath &&
              !equals(selectedPath?.path, parentGroupPath)
            ) {
              setCurrentGroup({ path: parentGroupPath })
            }
          }

          const groupId = isGeneralGroupNode(node) ? node.id : parentGroup?.id

          const statusNodeKey =
            node.type === 'ReferenceNode'
              ? `${node.element.type}_${node.element.id}`
              : selfKey

          const issueStatusInfo = issueStatusMap[`${statusNodeKey}_${groupId}`]

          if (issueStatusInfo) {
            const errIcon = issueStatusInfo.hasIssues ? (
              <WarningFilled />
            ) : (
              <Dot />
            )

            nextProps.endContent = (
              <>
                {<StatusIconWrap>{errIcon}</StatusIconWrap>}
                {nextProps.endContent}
                {nextProps.moreActionButton}
              </>
            )
          }
          const checklistStatusInfo =
            checklistStatusMap[`${statusNodeKey}_${groupId}`]

          if (checklistStatusInfo) {
            let color: string | undefined
            switch (checklistStatusInfo.status) {
              case 'COMPLETED':
                color = '#0D9966'
                break
              case 'IN_PROGRESS':
                color = '#9A7A0C'
                break
              case 'NOT_STARTED':
                color = '#A2A3B7'
                break
            }

            nextProps.textStyle = {
              ...nextProps.textStyle,
              color,
            }
          }

          if (isGeneralGroupNode(node)) {
            const checklist = currentInspection.checklists?.find(
              ({ groupId }) => node.id === groupId,
            )
            if (checklist) {
              const color: string = getChecklistStatusColor(
                checklistStatusInfo?.status,
              )

              nextProps.icon = <FolderChecklist color={color} />
            }

            nextProps.onClick = () => {
              setCurrentGroup({ path })
            }

            nextProps.onContextMenu = event => {
              if (!readonlyMode.enabled) {
                setSelectedCtxMenuGroup(node.id)
              }

              nodeLayoutProps?.onContextMenu?.(event)
            }
          }

          return <NodeLayout {...nextProps} />
        }}
      />
    </>
  )
}
