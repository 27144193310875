import { Button } from '@gmini/ui-kit'
import styled from 'styled-components'

type LinkButtonProps = {
  disabled?: boolean
  adaptiveMode?: boolean
}

export const LinkButton = styled(Button)`
  && {
    background: rgba(53, 59, 96, 1);
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #ebebef;
    gap: 4px;
    user-select: none;
    cursor: ${(props: LinkButtonProps) =>
      props.disabled ? 'default' : 'pointer'};
    ${(props: LinkButtonProps) => props.disabled && 'pointer-events: none;'}
    ${(props: LinkButtonProps) => props.adaptiveMode && 'width: 100%;'}
  ${(props: LinkButtonProps) =>
      props.disabled &&
      'background: rgba(53, 59, 96, 0.05); color: rgba(53, 59, 96, 0.3);'}
  height: 36px;
    margin: auto 0;

    &:hover {
      background: rgba(0, 0, 61, 1);
    }

    &:active {
      ${(props: LinkButtonProps) =>
        !props.disabled && 'background: rgba(53, 59, 96, 1);'};
    }
  }
`

type ContainerProps = {
  adaptiveMode?: boolean
}

export const Container = styled.div`
  display: flex;
  margin-top: 24px;
  width: 100%;
  gap: 8px 12px;
  ${(props: ContainerProps) => props.adaptiveMode && 'flex-direction: column;'}
`
