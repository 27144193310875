import { MouseEvent, useMemo } from 'react'

import { Button, Tooltip } from '@gmini/ui-kit'

import { useReadonlyMode } from '@gmini/common'
import { EditIssueIFrame } from '@gmini/ui-common'

import { CrossedChain } from '../Checklists/RenderChecklist.styled'

import { InspectionIssue } from '../../../../api'
import { getIssuesManagerLink } from '../../../../helpers'
import { envLinks } from '../../../../config'

export type RenderIssueProps = {
  issueId: number
  widthFormulaResizableCol?: number
  setAnchorElPopover?: (
    currentTarget: MouseEvent<HTMLButtonElement>['currentTarget'],
  ) => void
  type: InspectionIssue['type']
  projectUrn: string
}

export const RenderIssue = ({
  issueId,
  widthFormulaResizableCol,
  setAnchorElPopover,
}: RenderIssueProps) => {
  const { readonlyMode } = useReadonlyMode()

  const issuesManagerLink = useMemo(
    () => getIssuesManagerLink(envLinks) || '',
    [],
  )

  const adaptiveMode = Number(widthFormulaResizableCol) > 385

  const removeButtonJsx = (
    <Tooltip title={adaptiveMode ? '' : 'Отвязать замечание'}>
      <Button
        onClick={(event: MouseEvent<HTMLButtonElement>) => {
          setAnchorElPopover?.(event.currentTarget)
        }}
        color='secondary'
        disabled={readonlyMode.enabled}
        leftIcon={<CrossedChain />}
        size='regular'
      >
        {adaptiveMode && 'Отвязать'}
      </Button>
    </Tooltip>
  )
  return (
    <>
      <EditIssueIFrame
        baseUrl={issuesManagerLink}
        issueId={issueId}
        additionalContentWrapperStyle={{ paddingTop: '40px' }}
      />
      {removeButtonJsx}
    </>
  )
}
