import { prop } from 'ramda'

import { ExternalEvent } from '../../inspection-service'

import * as api from '../../api'

import { notificationService } from '../../services/notificationService'

export const created = notificationService.message
  .filter({ fn: ExternalEvent.Create.is })
  .map(prop('payload'))

export const folderCreated = created.filter({ fn: api.InspectionRepoFolder.is })
export const inspectionCreated = created.filter({ fn: api.Inspection.is })
