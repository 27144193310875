import * as t from 'io-ts'

import * as smApi from '@gmini/sm-api-sdk'

export interface RequestMessage {
  id: number
}

export type RequestMethod = 'Subscribe' | 'Unsubscribe'

export interface AbstractSubscribeRequest extends RequestMessage {
  method: RequestMethod
}

export type ExplorerSubscriptionType =
  | 'ClassifierRepository'
  | 'CheckupRepository'
  | 'EstimationRepository'
  | 'FieldInspectionRepository'

export interface SubscribeExplorerReq extends AbstractSubscribeRequest {
  subscriptionType:
    | 'ClassifierRepository'
    | 'CheckupRepository'
    | 'EstimationRepository'
    | 'FieldInspectionRepository'
}

export type SubscriptionEvent =
  | SubscriptionEvent.SubscribeBimFile
  | SubscriptionEvent.SubscribeCheckup
  | SubscriptionEvent.SubscribeClassifier
  | SubscriptionEvent.SubscribeAssembly
  | SubscriptionEvent.SubscribeEstimation
  | SubscriptionEvent.SubscribeExplorer

export namespace SubscriptionEvent {
  interface SubscriptionType {
    readonly type: 'SubscribeEvent' | 'UnsubscribeEvent'
  }

  const subscribeEvent = 'SubscribeEvent'
  const unsubscribeEvent = 'UnsubscribeEvent'

  const SubscriptionType = t.union([
    t.literal(subscribeEvent),
    t.literal(unsubscribeEvent),
  ])

  const Status = t.union([
    t.literal('Success'),
    t.literal('Fail'),
    t.literal('Error'),
  ])

  type Status = 'Success' | 'Fail' | 'Error'

  interface Data {
    id: number
    status: Status
    message: null | string
  }
  export interface SubscribeExplorer extends SubscriptionType {
    readonly payload: SubscribeExplorer.Payload
  }
  export namespace SubscribeExplorer {
    export interface Payload extends Data {
      subscriptionType: ExplorerSubscriptionType
      params: null
    }
    export namespace Payload {
      export const io = <Type extends t.Mixed>(type: Type) =>
        t.type({
          subscriptionType: type,
          status: Status,
          message: t.union([t.null, t.string]),
          params: t.null,
          id: t.number,
        })
    }

    export namespace CheckupRepository {
      export const payload = Payload.io(t.literal('CheckupRepository'))
    }
    export namespace ClassifierRepository {
      export const payload = Payload.io(t.literal('ClassifierRepository'))
    }

    export namespace EstimationRepository {
      export const payload = Payload.io(t.literal('EstimationRepository'))
    }

    export namespace FieldInspectionRepository {
      export const payload = Payload.io(t.literal('FieldInspectionRepository'))
    }

    export const is = (e: SubscribeExplorer): e is SubscribeExplorer =>
      e.payload.subscriptionType === 'CheckupRepository' ||
      e.payload.subscriptionType === 'ClassifierRepository' ||
      e.payload.subscriptionType === 'EstimationRepository' ||
      e.payload.subscriptionType === 'FieldInspectionRepository'
  }

  export interface SubscribeAssembly extends SubscriptionType {
    readonly payload: SubscribeAssembly.Payload
  }
  export namespace SubscribeAssembly {
    export interface Payload extends Data {
      type: 'AssemblyClassifier'
      params: {
        classifierId: number
      }
    }
    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        type: t.literal('AssemblyClassifier'),
        status: Status,
        message: t.union([t.null, t.string]),
        params: t.type({
          classifierId: t.number,
        }),
        id: t.number,
      })
    }

    export const is = (e: SubscribeAssembly): e is SubscribeAssembly =>
      e.payload.type === 'AssemblyClassifier'
  }

  export interface SubscribeClassifier extends SubscriptionType {
    readonly payload: SubscribeClassifier.Payload
  }
  export namespace SubscribeClassifier {
    export interface Payload extends Data {
      type: 'UserClassifier'
      params: {
        classifierId: number
      }
    }
    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        type: t.literal('UserClassifier'),
        status: Status,
        message: t.union([t.null, t.string]),
        params: t.type({
          classifierId: t.number,
        }),
        id: t.number,
      })
    }

    export const is = (e: SubscribeClassifier): e is SubscribeClassifier =>
      e.payload.type === 'UserClassifier'
  }

  export interface SubscribeCheckup extends SubscriptionType {
    readonly payload: SubscribeCheckup.Payload
  }
  export namespace SubscribeCheckup {
    export interface Payload extends Data {
      type: 'Checkup'
      params: {
        checkupId: number
        classifierId: number
      }
    }
    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        type: t.literal('Checkup'),
        status: Status,
        message: t.union([t.null, t.string]),
        params: t.type({
          classifierId: t.number,
          checkupId: t.number,
        }),
        id: t.number,
      })
    }

    export const is = (e: SubscribeCheckup): e is SubscribeCheckup =>
      e.payload.type === 'Checkup'
  }

  export interface SubscribeEstimation extends SubscriptionType {
    readonly payload: SubscribeEstimation.Payload
  }
  export namespace SubscribeEstimation {
    export interface Payload extends Data {
      type: 'Estimation'
      params: {
        estimationId: number
        classifierId: number
      }
    }
    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        type: t.literal('Estimation'),
        status: Status,
        message: t.union([t.null, t.string]),
        params: t.type({
          classifierId: t.number,
          estimationId: t.number,
        }),
        id: t.number,
      })
    }

    export const is = (e: SubscribeEstimation): e is SubscribeEstimation =>
      e.payload.type === 'Estimation'
  }
  export interface SubscribeFieldInspection extends SubscriptionType {
    readonly payload: SubscribeFieldInspection.Payload
  }

  export namespace SubscribeFieldInspection {
    export interface Payload extends Data {
      type: 'FieldInspection'
      params: {
        classifierId: number
        fieldInspectionId: number
      }
    }
    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        type: t.literal('FieldInspection'),
        status: Status,
        message: t.union([t.null, t.string]),
        params: t.type({
          classifierId: t.number,
          fieldInspectionId: t.number,
        }),
        id: t.number,
      })
    }

    export const is = (
      e: SubscribeFieldInspection,
    ): e is SubscribeFieldInspection => e.payload.type === 'FieldInspection'
  }

  export interface SubscribeBimFile extends SubscriptionType {
    readonly payload: SubscribeBimFile.Payload
  }
  export namespace SubscribeBimFile {
    export interface Payload extends Data {
      type: 'BimFile'
      params: {
        bimFileUrn: smApi.Urn
      }
    }
    export namespace Payload {
      export const io: t.Type<Payload> = t.type({
        type: t.literal('BimFile'),
        status: Status,
        message: t.union([t.null, t.string]),
        params: t.type({
          bimFileUrn: smApi.Urn,
        }),
        id: t.number,
      })
    }

    export const is = (e: SubscribeBimFile): e is SubscribeBimFile =>
      e.payload.type === 'BimFile'
  }

  export const is = (e: SubscriptionType): e is SubscriptionType =>
    e.type === 'SubscribeEvent'

  export interface Subscribe {
    type: typeof subscribeEvent
    payload:
      | SubscribeCheckup.Payload
      | SubscribeClassifier.Payload
      | SubscribeEstimation.Payload
      | SubscribeBimFile.Payload
      | SubscribeAssembly.Payload
      | SubscribeExplorer.Payload
      | SubscribeFieldInspection.Payload
  }
  export namespace Subscribe {
    export const io = t.type(
      {
        type: t.literal(subscribeEvent),
        payload: t.union([
          SubscribeCheckup.Payload.io,
          SubscribeClassifier.Payload.io,
          SubscribeEstimation.Payload.io,
          SubscribeBimFile.Payload.io,
          SubscribeAssembly.Payload.io,
          SubscribeFieldInspection.Payload.io,
          SubscribeExplorer.CheckupRepository.payload,
          SubscribeExplorer.ClassifierRepository.payload,
          SubscribeExplorer.EstimationRepository.payload,
          SubscribeExplorer.FieldInspectionRepository.payload,
        ]),
      },
      'SubscribeEvent',
    )
  }

  export interface Unsubscribe {
    type: typeof unsubscribeEvent
    payload:
      | SubscribeCheckup.Payload
      | SubscribeClassifier.Payload
      | SubscribeEstimation.Payload
      | SubscribeBimFile.Payload
      | SubscribeAssembly.Payload
      | SubscribeExplorer.Payload
      | SubscribeFieldInspection.Payload
  }
  export namespace Unsubscribe {
    export const io = t.type(
      {
        type: t.literal(unsubscribeEvent),
        payload: t.union([
          SubscribeCheckup.Payload.io,
          SubscribeClassifier.Payload.io,
          SubscribeEstimation.Payload.io,
          SubscribeBimFile.Payload.io,
          SubscribeAssembly.Payload.io,
          SubscribeFieldInspection.Payload.io,
          SubscribeExplorer.CheckupRepository.payload,
          SubscribeExplorer.ClassifierRepository.payload,
          SubscribeExplorer.EstimationRepository.payload,
          SubscribeExplorer.FieldInspectionRepository.payload,
        ]),
      },
      'UnsubscribeEvent',
    )
  }

  export const io = t.union(
    [SubscriptionEvent.Subscribe.io, SubscriptionEvent.Unsubscribe.io],
    'NotificationEvent',
  )
}

export interface SubscribeUserClassifierReq extends AbstractSubscribeRequest {
  subscriptionType: 'UserClassifier'
  params: {
    classifierId: number
  }
}
export interface SubscribeAssemblyClassifierReq
  extends AbstractSubscribeRequest {
  subscriptionType: 'AssemblyClassifier'
  params: {
    classifierId: number
  }
}

export interface SubscribeCheckupReq extends AbstractSubscribeRequest {
  subscriptionType: 'Checkup'
  params: {
    classifierId: number // TODO - уточнить надо ли
    checkupId: number
  }
}

export interface SubscribeEstimationReq extends AbstractSubscribeRequest {
  subscriptionType: 'Estimation'
  params: {
    classifierId: number // TODO - уточнить надо ли
    estimationId: number
  }
}

export interface SubscribeInspectionReq extends AbstractSubscribeRequest {
  subscriptionType: 'FieldInspection'
  params: {
    classifierId: number
    fieldInspectionId: number
  }
}

export interface SubscribeBimFileReq extends AbstractSubscribeRequest {
  subscriptionType: 'BimFile'
  params: {
    bimFileUrn: smApi.Urn
  }
}

export type SubscribeRequest =
  | SubscribeBimFileReq
  | SubscribeCheckupReq
  | SubscribeEstimationReq
  | SubscribeExplorerReq
  | SubscribeUserClassifierReq
  | SubscribeInspectionReq
  | SubscribeAssemblyClassifierReq

export type SubscriptionsApi = {
  subscribeExplorer: (params: {
    type: ExplorerSubscriptionType
  }) => Promise<void>
  unsubscribeExplorer: (params: {
    type: ExplorerSubscriptionType
  }) => Promise<void>
  subscribeUserClassifier: (params: { id: number }) => Promise<void>
  unsubscribeUserClassifier: (params: { id: number }) => Promise<void>
  subscribeAssemblyClassifier: (params: { id: number }) => Promise<void>
  unsubscribeAssemblyClassifier: (params: { id: number }) => Promise<void>
  subscribeCheckup: (params: {
    checkupId: number
    classifierId: number
  }) => Promise<void>
  unsubscribeCheckup: (params: {
    checkupId: number
    classifierId: number
  }) => Promise<void>
  subscribeEstimation: (params: {
    estimationId: number
    classifierId: number
  }) => Promise<void>
  unsubscribeEstimation: (params: {
    estimationId: number
    classifierId: number
  }) => Promise<void>
  subscribeInspection: (params: {
    fieldInspectionId: number
    classifierId: number
  }) => Promise<void>
  unsubscribeInspection: (params: {
    fieldInspectionId: number
    classifierId: number
  }) => Promise<void>
  subscribeBimFile: (params: { urn: smApi.Urn }) => Promise<void>
  unsubscribeBimFile: (params: { urn: smApi.Urn }) => Promise<void>
}

// notification.subscruptions.subscribeExplorer()
// notification.subscruptions.unsubscribeExplorer()
