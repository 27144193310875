import React, { UIEventHandler, useRef } from 'react'
import { Select, Tooltip } from '@gmini/ui-kit'

import { useReadonlyMode } from '@gmini/common'

import * as chmApi from '@gmini/chm-api-sdk'

import { Container, LinkButton } from '../Issues/IssueSelect.styled'

import {
  SelectInner,
  SelectLabel,
  SelectWrapper,
} from '../Issues/CreateIssue.styled'

export type ChecklistSelectProps = {
  adaptiveMode?: boolean
  onLinkChecklist: (value: chmApi.Instance.Instance | null) => void
  label?: string
  options: chmApi.Instance.Instance[] | null
  setInputValue: (newValue: string) => void
  onScrollList?: UIEventHandler<HTMLDivElement>
}

export const ChecklistSelect = ({
  adaptiveMode,
  onLinkChecklist,
  label,
  options,
  setInputValue,
  onScrollList,
}: ChecklistSelectProps) => {
  const [value, setValue] = React.useState<chmApi.Instance.Instance | null>(
    null,
  )

  const containerRef = useRef<HTMLDivElement>(null)

  const { readonlyMode } = useReadonlyMode()

  return (
    <Container adaptiveMode={adaptiveMode} ref={containerRef}>
      <SelectWrapper>
        {label && <SelectLabel>{label}</SelectLabel>}
        <SelectInner>
          <Select
            getOptionLabel={option => (option as chmApi.Instance.Instance).name}
            options={options}
            value={value}
            onChange={(
              newValue:
                | chmApi.Instance.Instance
                | React.FormEvent<HTMLInputElement>
                | null,
            ) => {
              setValue(newValue as chmApi.Instance.Instance | null)
            }}
            emptyOptionListMessage='Нет доступных совпадений'
            onInputChange={(newValue: string) => setInputValue(newValue)}
            onScrollList={onScrollList}
            placeholder='Выберите чек-лист'
            optionListStyles={{
              width: containerRef.current?.getBoundingClientRect().width,
            }}
            disabled={readonlyMode.enabled}
            dataTestIdFor={{ input: 'selectChecklistInput' }}
          />
          <Tooltip title={value ? '' : 'Выберите чек-лист'}>
            <LinkButton
              disabled={!value || readonlyMode.enabled}
              onClick={() => onLinkChecklist(value)}
              adaptiveMode={adaptiveMode}
              data-test-id='tieTheChecklist'
            >
              Привязать
            </LinkButton>
          </Tooltip>
        </SelectInner>
      </SelectWrapper>
    </Container>
  )
}
